import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { callApiAction } from "../../store/actions/commonAction"
import WFHDetailsUi from "./WFHDetailsUi"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"

import { deleteWFHApi, fetchWFHByIdDataApi } from "../../apis/wfh.api"
import { closeModal } from "../../store/actions/modalAction"


const WFHDetailsController = ({ id, callBack = () => { } }) => {
    const dispatch = useDispatch();

    const [list, setList] = useState({});
    const [loading, setLoading] = useState(false);
    const [fieldLoading, setFieldLoading] = useState({
        fieldName: "",
        loading: false
    });

    const onDelete = () => {
        setFieldLoading({
            fieldName: "delete",
            loading: true
        });

        dispatch(callApiAction(
            async () => await deleteWFHApi({ id }),
            (response) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                });
                dispatch(closeModal());
            },
            (err) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                });
                dispatch(callSnackBar(err, 'error'));
            }
        ));
    };

    const fetchList = async () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await fetchWFHByIdDataApi({ id }),
                async (response) => {
                    setLoading(false);
                    setList(response);
                },
                (err) => {
                    setLoading(false);
                    setList({});
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                },
            )
        );
    };

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <WFHDetailsUi
            onDelete={onDelete}
            fieldLoading={fieldLoading}
            setList={setList}
            callBack={callBack}
            list={list}
            loading={loading}
        />
    );
};

export default WFHDetailsController;