import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { CenteredBox } from "../../../components/layouts/common/boxes";
import { Link } from "react-router-dom";
import CustomDayPicker from "../../../components/layouts/common/CustomDayPicker";
import { Add, ListAlt } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CustomInput from "../../../components/inputs/CustomInput";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import { fetchUserWiseDepartment } from "../../../apis/dailyupdate.api";
import DailyUpdateCustomDayPicker from "../../../components/layouts/common/DailyUpdateCustomDayPicker";
import { DAILYUPDATE_STATUS } from "../../../utils/leave.constants";

const DailyUpdateUi = ({
  loading,
  date,
  setDate,
  departments,
  submittedTasks,
  openMonthlyReport,
  openCKEditorModal,
}) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  // Calculate whether the date is within the last 7 days
  const isDateWithin7Days = moment().diff(date, "days") <= 7;

  // // Check if the selected date is a future date
  const isFutureDate = moment(date).isAfter(moment(), "day");

  return (
    <Box p={2} mt={1}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          flexDirection: desktop ? "row" : "column",
        }}
      >
        <Typography align="left" variant="h4" gutterBottom>
          Daily Update
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={openMonthlyReport}
          sx={{
            height: "35px",
            minWidth: "150px",
          }}
        >
          Tasks Summary
        </Button>
      </Box>

      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DailyUpdateCustomDayPicker date={date} setDate={setDate} />
      </Box>

      <Box
        mt={4}
        sx={{
          display: "grid",
          gridTemplateColumns:
            departments.length < 3
              ? "repeat(auto-fit, minmax(300px, 350px))"
              : "repeat(auto-fit, minmax(300px, 1fr))",
          justifyContent:
            departments.length < 3 ? "flex-start" : "space-between",
          gap: 3,
        }}
      >
        {loading ? (
          <CenteredBox sx={{ minHeight: "300px" }}>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {departments.length > 0 ? (
              departments.map((department) => (
                <Card
                  key={department._id}
                  sx={{
                    height: "300px",
                    width: "100%",
                    maxWidth: "350px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #ddd",
                    transition: "box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      overflowY: "auto",
                      position: "relative",
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "#e0e0e0",
                        color: "#333",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        marginBottom: "16px",
                      }}
                    >
                      {department.name}
                    </Typography>

                    {submittedTasks[department.name] &&
                    submittedTasks[department.name].length > 0 ? (
                      <List dense>
                        {submittedTasks[department.name].map((task, index) => (
                          <>
                            {" "}
                            <ListItem
                              sx={{
                                borderRadius: "10px",
                                backgroundColor:
                                  task?.status === DAILYUPDATE_STATUS.REJECTED
                                    ? "rgba(244, 67, 54, 0.1)"
                                    : "#f9f9f9",
                                marginBottom: "5px",
                              }}
                              key={index}
                            >
                              {console.log("thid is task", task)}
                              <ListItemText
                                primary={
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: task?.description,
                                    }}
                                  />
                                }
                              />
                            </ListItem>{" "}
                          </>
                        ))}
                      </List>
                    ) : (
                      <Typography
                        sx={{ marginTop: "90px" }}
                        textAlign={"center"}
                        variant="body1"
                        color="textSecondary"
                      >
                        No Updates Submitted
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => openCKEditorModal(department._id)}
                      disabled={!isDateWithin7Days || isFutureDate} // Disable button if date is not within 7 days or is a future date
                      fullWidth
                    >
                      Add Update
                    </Button>
                  </CardActions>
                </Card>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography variant="h4" color="textSecondary">
                  No departments available.
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default DailyUpdateUi;
