import axios from "axios";
import { getFileHeaders } from "../utils/helper";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";


export const disabilityComplaintCreateApi =async(data)=>{
    const callResponse = await axios({
      url: endpoints.disabilityComplaintCreate,
      method: 'post',
      headers: getHeaders(),
      data,
    })
      .then((response) => response.data)
      .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))
  
    return callResponse        
  }

  export const disabilityComplaintFetchApi =async(params)=>{
    return await axios({
        url: endpoints.disabilityComplaintFetch,
        method: "GET",
        headers: getHeaders(),
        params,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const disabilityComplaintFetchByIdApi =async(params)=>{
  return await axios({
      url: endpoints.disabilityFetchById,
      method: "GET",
      headers: getHeaders(),
      params,
    })
      .then(response => response.data)
      .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}