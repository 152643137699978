import { Close, Download } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Fade, IconButton, Modal, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { getFileOrImage } from "../apis/file.api";
import { useDispatch } from "react-redux";
import { callSnackBar } from "../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../utils/constants";

import fileDownload from 'js-file-download'

const FileDownloadComponent = ({ src,Component,downloadName }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const filePath = src ? src.split('/') : []
    
    const fileNameString = filePath[filePath.length - 1]

    const fileName = fileNameString.indexOf("?") == -1 ? fileNameString : fileNameString.slice(0, fileNameString.indexOf("?"))


    const fetchImage = async () => {
        try {
            setLoading(true)
            const file = await getFileOrImage({ fileUrl: src })

            fileDownload(file, downloadName??fileName)
            dispatch(callSnackBar("Download Successful", SNACK_BAR_VARIETNS.suceess))


            setLoading(false)
        } catch (e) {
            
            setLoading(false)
            dispatch(callSnackBar("File cant downloded", SNACK_BAR_VARIETNS.error))
        }

    }


    if(Component){
        return <Component onClick={fetchImage} loading={loading} />
    }
    if (loading)
        return <CircularProgress size={20} />

       
    return (

        <>
            <IconButton onClick={fetchImage} ><Download /></IconButton>

        </>)
}
export default FileDownloadComponent