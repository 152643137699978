
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {  Download,  Info } from '@mui/icons-material';
import {  getAllPms, getPmsPdf, getPmsReportingPerson } from '../../apis/pms.api';
import { callSnackBar } from '../../store/actions/snackbarAction';
import { SNACK_BAR_VARIETNS } from '../../utils/constants';


import {  IconButton,  } from '@mui/material';
import PmsPdfUi from './PmsPdfUi';
import moment from 'moment';


const PmsPdfController = () => {
  const dispatch = useDispatch();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    total: 0,
    searchable: ["name"],
    date: moment()
  });

  const fetchForms = async ({ pageNo, pageSize, search, searchable, date }) => {
    setLoading(true);
    const formattedDate = date.toISOString();
    try {
      const response = await getAllPms({
        pageNo,
        pageSize,
        name: search,
        searchable,
        date: formattedDate, 
      });

      if (response.status === 1 && response.data) {
        const filteredData = response.data.filter(
          (item) => item.reporting_status === 2
        );

        const groupedData = filteredData.reduce((acc, item) => {
          const employeeId = item.user_id._id;
          const taskDate = new Date(item.completion_date);
          const taskMonthYear = moment(taskDate).format("MM-yyyy");

          if (!acc[employeeId]) {
            acc[employeeId] = {};
          }
          if (!acc[employeeId][taskMonthYear]) {
            acc[employeeId][taskMonthYear] = {
              _id: employeeId,
              employee_name: `${item.user_id.first_name} ${item.user_id.last_name}`,
              review_month: taskMonthYear,
              tasks: [],
            };
          }
          acc[employeeId][taskMonthYear].tasks.push({
            _id: item._id,
            monthly_task: item.monthly_task,
            completion_date: item.completion_date,
            status: item.status,
            remarks: item.remarks,
          });
          return acc;
        }, {});

        let formList = Object.values(groupedData).flatMap((employeeForms) =>
          Object.values(employeeForms)
        );

        // Reverse the formList array to show the newest forms at the top
        formList = formList.reverse();

        const totalForms = response.totalCount || formList.length;

        setForms(formList);
        setFilters((prev) => ({
          ...prev,
          total: totalForms,
          pageNo,
          pageSize,
          date
        }));
      } else {
        dispatch(callSnackBar("Failed to fetch forms", SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error("Error fetching forms:", error);
      dispatch(callSnackBar("Failed to fetch forms", SNACK_BAR_VARIETNS.error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForms(filters);
  }, [filters.pageNo, filters.pageSize, filters.search, filters.date]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setFilters((prev) => ({ ...prev, search: searchValue, pageNo: 1 })); 
  };

  const handleChangePage = (e, newPage) => {
    setFilters((prev) => ({ ...prev, pageNo: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (e) => {
    setFilters((prev) => ({ ...prev, pageNo: 1, pageSize: parseInt(e.target.value, 10) }));
  };

  const handleDownloadClick = async (employeeId, reviewMonth) => {
    const [month, year] = reviewMonth.split('-');
    const date = new Date(year, month - 1, 2);
    const formattedDate = date.toISOString().split('T')[0];

    try {
      const response = await getPmsPdf({ id: employeeId, date: formattedDate });
      if (response.status === 1 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `employee_${employeeId}_${reviewMonth}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        dispatch(callSnackBar('Failed to download PDF', SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
      dispatch(callSnackBar('Failed to download PDF', SNACK_BAR_VARIETNS.error));
    }
  };

  const handleInfoClick = async (employeeId, reviewMonth) => {
    try {
      const [month, year] = reviewMonth.split("-");
      const response = await getPmsPdf({ id: employeeId, date: `${year}-${month}-02` });
      if (response.status === 1 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        window.open(url, '_blank');
      } else {
        dispatch(callSnackBar("Failed to open PDF", SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error("Error opening PDF:", error);
      dispatch(callSnackBar("Failed to open PDF", SNACK_BAR_VARIETNS.error));
    }
  };

  const columns = useMemo(() => [
    { id: 1, label: 'Employee Name', fieldName: 'employee_name', sort: true },
    { id: 2, label: 'Review Month', fieldName: 'review_month', sort: true },
    {
      id: 3,
      label: 'Actions',
      sort: false,
      renderValue: (rowData) => (
        <>
          <IconButton onClick={() => handleInfoClick(rowData._id, rowData.review_month)}>
            <Info color='info' />
          </IconButton>
          <IconButton onClick={() => handleDownloadClick(rowData._id, rowData.review_month)}>
            <Download/>
          </IconButton>
        </>
      )
    }
  ], [handleInfoClick, handleDownloadClick]);

  // Calculate the sliced forms for the current page
  const startIndex = (filters.pageNo - 1) * filters.pageSize;
  const endIndex = startIndex + filters.pageSize;
  const currentForms = forms.slice(startIndex, endIndex);

  return (
    <PmsPdfUi
      loading={loading}
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      currentForms={currentForms}
      handleSearchChange={handleSearchChange}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default PmsPdfController;