import { memo, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import { getAllDailyUpdates } from "../../../apis/dailyupdate.api";
import { EmployeeTaskUserContext } from "../EmployeeMain";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import DailyUpdateReportUi from "./DailyUpdateReportUi";

const DailyUpdateReportController = ({ date, userId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedUser = useContext(EmployeeTaskUserContext);
  const [filters, setFilters] = useState({
    err: "",
    pageNo: 1,
    pageSize: 25,
    date: moment(date), // Initialize with the passed date
    data: true,
  });
  const [list, setList] = useState([]);

  const fetchList = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await getAllDailyUpdates({
            ...filters,
            date: filters.date.toISOString(),
            userId,
          }),
        async (response) => {
          setLoading(false);

          const totalDaysInMonth = filters.date.daysInMonth();
          let finalList = [];

          if (response && Array.isArray(response)) {
            if (date) {
              // If a specific date is passed, filter to show only that day's tasks
              finalList.push({});
              for (let item of response) {
                const itemDate = moment(item.date).get("date");
                if (itemDate === filters.date.date()) {
                  const dateIndex = itemDate - 1;
                  if (!finalList[0][item.department]) {
                    finalList[0][item.department] = [];
                  }
                  finalList[0][item.department] = finalList[0][
                    item.department
                  ].concat(item.items);
                }
              }
            } else {
              // Original behavior: display all tasks for the month
              for (let i = 0; i < totalDaysInMonth; i++) {
                finalList.push({});
              }
              for (let item of response) {
                const itemDate = moment(item.date).get("date");
                const dateIndex = itemDate - 1;
                if (!finalList[dateIndex][item.department]) {
                  finalList[dateIndex][item.department] = [];
                }
                finalList[dateIndex][item.department] = finalList[dateIndex][
                  item.department
                ].concat(item.items);
              }
            }
          }

          setList(finalList);
        },
        (err) => {
          setLoading(false);
          setList({});
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters, selectedUser]);

  return (
    <DailyUpdateReportUi
      list={list}
      loading={loading}
      filters={filters}
      setFilters={setFilters}
      showSingleDay={!!date} // Pass a prop to control the UI
    />
  );
};
export default memo(DailyUpdateReportController);
