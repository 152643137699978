import { Box, CircularProgress, Divider, Grid, Typography, styled } from "@mui/material"
import { CenteredBox } from "../../../components/layouts/common/boxes"
import moment from "moment"
import PriorityButton from "./actionbuttons/PriorityButton"
import { TASK_STATUS } from "../../../utils/task.constants"
import StatusButton from "./actionbuttons/StatusButton"

const HtmlViewer = styled(Box)(({ theme }) => ({
    width: "100%",
    overflowX: "scroll",
    background: theme.palette.grey[200],
    width: "100%",
    borderRadius: 1,
    "*": {
        "ul": {
            paddingLeft: theme.spacing(4)
        },
        "ol": {
            paddingLeft: theme.spacing(4)
        },
        "b": {
            fontWeight: "bold"
        },
        "a": {
            color: "blue",
            cursor: "pointer"
        },

        "td": {
            border: "1px solid black",
            minWidth: "100px"
        }
    }

}))
const TaskDetailsUi = ({ loading, list, id, selectedUser }) => {
    return <Box>
        {loading && <CenteredBox mt={4} mb={4}><CircularProgress /></CenteredBox>}
        {
            !loading && list && Object.keys(list).length > 0 && <Box>
               
               
                <Box mt={4}>
                    <Typography mb={2} fontWeight={"bold"} variant="h5">Description</Typography>
                    <HtmlViewer p={2} dangerouslySetInnerHTML={{ __html: list.description }}  >

                    </HtmlViewer>
                </Box>


             
            </Box> 
        }

        {!loading && (!list || Object.keys(list).length == 0) && <CenteredBox mt={4} mb={4}><Typography variant="h3" >No task Found</Typography></CenteredBox>}
    </Box>
}
export default TaskDetailsUi