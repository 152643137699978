import React, { useState } from 'react'
import { UserSearchBarNormalUser } from '../../components/inputs/SearchBar'
import { Box, FormControlLabel, Radio, Typography } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CustomInput from '../../components/inputs/CustomInput';
import moment from 'moment'
import { DatePicker, DesktopDatePicker, MobileTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useSelector } from 'react-redux';
import FileInput from '../../components/inputs/FileInput';
import SubmitButton from '../../components/button/SubmitButton';
import { ADOPTION_RESPONSE, NATURE_OF_COMPLAINT, SUPPORTING_DOCUMENTS, VICTIM_RESPONSE } from '../../utils/constants';


const PoshPolicyComplaintFormUi = ({ fields, setFields, loading, onSubmit, id }) => {
  const [radioselect, setRadioselect] = useState('')
  const [radioselected, setRadioselected] = useState('')
  const [selected, setSelected] = useState('')
  const { holiday } = useSelector((state) => state)

  return (
    <Box mt={2} display={"flex"} flexDirection={"column"} alignItems={"space-between"} justifyContent={"center"}>

      {
        fields.err && <Typography color="error" variant="h6">
          {fields.err}
        </Typography>
      }

      <Box mt={3} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
        {!id &&
          <Box>
            <Typography>Name Of The Respondent : </Typography>
          </Box>}

        <Box sx={{ display: 'flex', width: '100%' }}>
          {!id && <UserSearchBarNormalUser
            placeholder="search"
            inputProps={{ placeholder: "Search & Select Employee" }}
            value={fields.respondentId}
            defaultParams={fields.respondentId}
            onChange={(newVal) => {
              setFields({ ...fields, err: '', respondentId: newVal?._id })
            }}
          />}
        </Box>
      </Box>

      <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>

        <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
          <FormLabel id="demo-radio-buttons-group-label">Nature Of The Complaint :</FormLabel>
          <RadioGroup
            row
            sx={{ marginLeft: "10px" }}
            value={fields.natureOfComplaint}
            onChange={(e) => setFields({
              ...fields,
              natureOfComplaint: e.target.value
                      })}
          >
            {Object.keys(NATURE_OF_COMPLAINT).map((item) => (
              <FormControlLabel
                disabled={loading}
                key={item}
                value={NATURE_OF_COMPLAINT[item]}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
        </FormControl>

      </Box>

      <Box>
        <Box mt={3} display={"flex"} justifyContent={"flex-start"}>
          <Typography variant='h4'>Details Of Complaint : </Typography>
        </Box>

        <Box mt={3} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Box sx={{ display: 'flex', width: '24%' }}>
            <DesktopDatePicker
              autoFocus={true}
              label={"Date Of The Incident*"}
              renderInput={(props) => {
                return <CustomInput {...props} sx={{ height: '100%' }} />
              }}
              // shouldDisableDate={disableDates}
              inputFormat="DD-MM-yyyy"
              disabled={
                loading ||
                !(holiday.data && Array.isArray(holiday.data)) ||
                holiday.loading
              }
              value={moment(fields.detailsOfComplaint.dateOfIncident)}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: '', detailsOfComplaint: { ...fields.detailsOfComplaint, dateOfIncident: e.toISOString() },
                })
              }
              type="date"
            />

          </Box>

          <Box width={"24%"}>
            <TimePicker
              label={"Time Of The Incident*"}
              renderInput={(props) => {
                return <CustomInput {...props} sx={{ height: '100%' }} />
              }}

              value={moment(fields.detailsOfComplaint.timeOfIncident)}
              onChange={(e) =>
                setFields({ ...fields, err: '', detailsOfComplaint: { ...fields.detailsOfComplaint, timeOfIncident: e.toISOString() } })
              }
            />
          </Box>

          <Box width={"24%"}>
            <CustomInput
              value={fields.detailsOfComplaint.location}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: '',
                  detailsOfComplaint: {
                    ...fields.detailsOfComplaint,
                    err: '',
                    location: e.target.value
                  }
                })
              }
              type="text"
              label={"Location Where The Incident Has Taken Place*"}
            />
          </Box>
          <Box width={"24%"}>
            <CustomInput
              value={fields.detailsOfComplaint.frequency}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: '',
                  detailsOfComplaint: {
                    ...fields.detailsOfComplaint,
                    frequency: e.target.value
                  }
                })}
              type="number"
              label={"Frequency Of The Incident*"}
            />
          </Box>
        </Box>

        <Box mt={2}>
          <CustomInput
            rows={2}
            multiline
            value={fields.detailsOfComplaint.detailsOfMisconduct}
            onChange={(e) =>
              setFields({
                ...fields,
                err: '',
                detailsOfComplaint: {
                  ...fields.detailsOfComplaint,
                  detailsOfMisconduct: e.target.value
                }
              })}
            type="text"
            label={"Details Of Misconduct Done Including The Sequence Of Events Leading To The Same*"}
          />
        </Box>

        <Box mt={2}>
          <CustomInput
            rows={2}
            multiline
            value={fields.detailsOfComplaint.stepsTaken}
            onChange={(e) =>
              setFields({
                ...fields,
                err: '',
                detailsOfComplaint: {
                  ...fields.detailsOfComplaint,
                  stepsTaken: e.target.value
                }
              })}
            type="text"
            label={"Steps Taken To Stop The Unwanted Behaviour Including The Confront Made Against The Offensive Behavior And The Reaction To The Complaint Made*"}
          />
        </Box>

      </Box>

      <Box>
        <Box mt={3} display={"flex"} justifyContent={"flex-start"}>
          <Typography variant='h4'>Documents Supporting The Evidence : </Typography>
        </Box>

        <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>

          <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              row
              sx={{ marginLeft: "10px" }}
              value={fields.supportingDocuments.supportingDocuments_type}
              onChange={(e) => setFields({
                ...fields,
                supportingDocuments: {
                  ...fields.supportingDocuments, err: '',
                  supportingDocuments_type: e.target.value
                },

              })}
            >
              {Object.keys(SUPPORTING_DOCUMENTS).map((item) => (
                <FormControlLabel
                  disabled={loading}
                  key={item}
                  value={SUPPORTING_DOCUMENTS[item]}
                  control={<Radio />}
                  label={item}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>

        {fields.supportingDocuments.supportingDocuments_type == SUPPORTING_DOCUMENTS['Others, Specify'] &&
          <Box >
            <CustomInput
              multiline
              value={fields.supportingDocuments.others}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: '',
                  supportingDocuments: {
                    ...fields.supportingDocuments,
                    others: e.target.value
                  }
                })}
              type="text"
              label={"Specify Here*"}
            />
          </Box>
        }

        <Box ml={1} width={"100%"} >
          {!id && <FileInput
            multi
            // value={fields.supportingDocuments.attachments}
            onChange={(file, fileArr) => {
              setFields({
                ...fields,
                supportingDocuments: {
                  ...fields.supportingDocuments,
                  attachments: [fileArr]
                }
              })
            }}
            defaults={fields.supportingDocuments.attachments ? [fields.supportingDocuments.attachments] : []}
            accept="image/*,.pdf"
            title="Upload Attachments"
            subTitle="Only Attachments are allowed! less than 1 MB"
          />}
        </Box>


      </Box>

      <Box mt={3} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Box width={"20%"}>
          <CustomInput
            value={fields.witness.name}
            onChange={(e) =>
              setFields({
                ...fields,
                err: '',
                witness: {
                  ...fields.witness,
                  name: e.target.value
                }
              })}
            type="text"
            label={"Name Of Witness (If Any)"}
          />
        </Box>
        <Box width={"79%"}>
          <CustomInput
            value={fields.witness.remarks}
            onChange={(e) =>
              setFields({
                ...fields,
                err: '',
                witness: {
                  ...fields.witness,
                  remarks: e.target.value
                }
              })}
            type="text"
            label={"Remarks Of The Witness (If Any)"}
          />
        </Box>
      </Box>


      <Box mt={2}>
        <CustomInput
          rows={2}
          multiline
          value={fields.otherDetails}
          onChange={(e) =>
            setFields({
              ...fields,
              err: '',
              otherDetails:  e.target.value
              
            })}
          type="text"
          label={"Other Details (If Any)"}
        />
      </Box>

      <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>

        <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
          <FormLabel id="demo-radio-buttons-group-label">Whether Complaint Lodged By The Victim Or Any Other Person On His/Her Behalf :</FormLabel>
          <RadioGroup
            row
            sx={{ marginLeft: "10px" }}
            value={fields.complaintLodgedBy.isVictim}
            onChange={(e) => setFields({
              ...fields,
              complaintLodgedBy: {
                ...fields.complaintLodgedBy, err: '',
                isVictim: e.target.value
              },

            })}
          >
            {Object.keys(VICTIM_RESPONSE).map((item) => (
              <FormControlLabel
                disabled={loading}
                key={item}
                value={VICTIM_RESPONSE[item]}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>


      {fields.complaintLodgedBy.isVictim == "true" &&
        <Box mt={2} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>

          <Box>
            <Typography>Name Of The Person : </Typography>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <UserSearchBarNormalUser
              placeholder="search"
              inputProps={{ placeholder: "Search & Select Employee" }}
              value={fields.victim_name}
              onChange={(newVal) => {
                setFields({ ...fields, err: '', victim_name: newVal?._id })
              }}
            />
          </Box>
        </Box>}

      <Box mt={2}>
        {!id && <SubmitButton
          title={"Submit"}
          onClick={onSubmit}
        />}
      </Box>

    </Box>
  )
}

export default PoshPolicyComplaintFormUi