import { memo, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../../store/actions/commonAction'

import { openModal } from '../../../../store/actions/modalAction'


import Calendar from '../../../../components/layouts/Calendar'
import CalendarBox from './CalendarInnerBox'

import {
  CALENDAR_ITEM_TYPES,
  LEAVE_COLORS,
  LEAVE_RESPONSE,
  SNACK_BAR_VARIETNS,

} from '../../../../utils/constants'
import { fetchTasksCalendarApi } from '../../../../apis/task.api'
import moment from 'moment'
import { EmployeeTaskUserContext } from '../../EmployeeMain'
import CustomMonthPicker from '../../../../components/layouts/common/CustomMonthPicker'
import { Box } from '@mui/material'
import TaskDetailsController from '../TaskDetails.controller'
import { callSnackBar } from '../../../../store/actions/snackbarAction'
import { getAllDailyUpdates } from '../../../../apis/dailyupdate.api'
import { getAllPms } from '../../../../apis/pms.api'
import PmsDetailsController from '../PmsDetailsController'

const TaskCalendarController = ({ Component, isFromPerfomance }) => {
  const { holiday, user } = useSelector((state) => state);
  const selectedUser = useContext(EmployeeTaskUserContext);
  const [calendarTimeObj, setCalendarTimeObj] = useState(moment());

  const weeklyOf = selectedUser ? selectedUser.weekly_of : user.data.weekly_of;
  const userId = selectedUser ? selectedUser._id : user.data._id;

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const holidayList = holiday.data;
  const [taskList, setTaskList] = useState(null);

  // For PMS and daily updates
  const [pmsTaskList, setPmsTaskList] = useState(null);
  const [updatesList, setUpdateList] = useState(null);

  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    userId: userId,
    isFromPerfomance,
  });

  const fetchList = () => {
    setListLoading(true);

    const paramsToPass = { ...filters, userId: userId };

    // Fetch PMS Tasks
    dispatch(
      callApiAction(
        async () => await getAllPms({ userId, date: calendarTimeObj.toISOString() }),
        (response) => {
          setPmsTaskList(Array.isArray(response) ? response : []);
        },
        (err) => {
          setPmsTaskList([]);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );

    // Fetch other tasks
    dispatch(
      callApiAction(
        async () =>
          await fetchTasksCalendarApi({
            ...paramsToPass,
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {
          setTaskList(response);
        },
        (err) => {
          setTaskList(null);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );

    // Fetch daily updates
    dispatch(
      callApiAction(
        async () =>
          await getAllDailyUpdates({
            ...paramsToPass,
            date: calendarTimeObj.toISOString(),
          }),
        (response) => {
          setUpdateList(response);
        },
        (err) => {
          setUpdateList(null);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [calendarTimeObj.valueOf(), userId]);

  useEffect(() => {
    if (taskList && updatesList && pmsTaskList) {
      let temp = [];

      // Process PMS tasks
      pmsTaskList.forEach((pmsItem) => {
        if (!userId || pmsItem.user_id._id === userId) {
          const taskDescription = pmsItem.monthly_task || '';
          const taskWords = taskDescription.split(' ').slice(0, 2).join(' ');
          const title = `${taskWords}`;

          temp.push({
            title,
            start: new Date(pmsItem.completion_date),
            end: new Date(pmsItem.completion_date),
            relatedId: pmsItem._id,
            type: CALENDAR_ITEM_TYPES.pms_task,
            onClick: () => {
              dispatch(
                openModal({
                  title: 'PMS Task Detail',
                  component: (
                    <PmsDetailsController
                      callBack={() => {}}
                      selectedUser={selectedUser}
                      id={pmsItem.user_id}
                      task={pmsItem}
                    />
                  ),
                  size: 'md',
                })
              );
            },
          });
        }
      });

      // Process other tasks
      taskList.forEach((item) => {
        temp.push({
          title: item.name,
          completed_date: item.completed_date ? new Date(item.completed_date) : null,
          start: new Date(item.date),
          end: new Date(item.date),
          relatedId: item._id,
          type: CALENDAR_ITEM_TYPES.task,
          status: item.status,
          priority: item.priority,
          onClick: () => {
            dispatch(
              openModal({
                title: 'Task Detail',
                component: <TaskDetailsController callBack={() => {}} selectedUser={selectedUser} id={item._id} />,
                size: 'md',
              })
            );
          },
        });
      });

      // Process daily updates
      updatesList.forEach((item) => {
        temp.push({
          title: item.count.toString(),
          dailyUpdate: true,
          start: new Date(item.date),
          end: new Date(item.date),
          type: CALENDAR_ITEM_TYPES.daily_updates,
        });
      });

      // Process holidays
      if (holidayList && Array.isArray(holidayList)) {
        holidayList.forEach((leave) => {
          temp.push({
            title: leave.name,
            start: new Date(leave.date),
            end: new Date(leave.date),
            relatedId: leave._id,
            type: CALENDAR_ITEM_TYPES.holiday,
            color: LEAVE_COLORS.HOLIDAY,
            onClick: () => {},
          });
        });
      }

      setList(temp);
      setListLoading(false);
    }
  }, [taskList, updatesList, pmsTaskList]);

  return (
    <>
      <Box mb={4}>
        <CustomMonthPicker date={calendarTimeObj} setDate={setCalendarTimeObj} />
      </Box>
      <Calendar
        loading={listLoading}
        data={list}
        dayComponent={(props) =>
          Component ? (
            <Component weeklyOf={weeklyOf ?? [0]} {...props} calendarTimeObj={calendarTimeObj} />
          ) : (
            <CalendarBox weeklyOf={weeklyOf ?? [0]} {...props} calendarTimeObj={calendarTimeObj} />
          )
        }
        calendarTimeObj={calendarTimeObj.toDate()}
      />
    </>
  );
};

export default memo(TaskCalendarController);