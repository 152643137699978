import { Box, CircularProgress, Grid, Typography, styled } from '@mui/material'
import moment from 'moment'
import React from 'react'
import FileDownloadComponent from '../../components/FileDownloadComponent'
import { ADOPTION_RESPONSE, COMPLAINT_RELATION_TO, COMPLAINT_RELATION_TO_TRANSGENDER, DISIBILITY_TYPE, DISIBILITY_TYPE_DATA, EXPECTED_CHILD, NATURE_OF_COMPLAINT, SNACK_BAR_VARIETNS, SUPPORTING_DOCUMENTS, TYPE_OF_COMPLAINT } from '../../utils/constants'
import { findObjectKeyByValue, titleCase, toTitleCase } from '../../utils/helper'
import SubmitButton from '../../components/button/SubmitButton'
import { useDispatch } from 'react-redux'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { Download } from '@mui/icons-material'

const MaternityComplaintModalUi = ({ id, data, setData, loading }) => {
    console.log("object daata", data.respondentId?.first_name);
    const dispatch = useDispatch()

    const errorfor = () => {
        dispatch(
            callSnackBar("No Document Available", SNACK_BAR_VARIETNS.error)
        );
    }
    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Typography sx={{ display: "flex", justifyContent: "center", textDecoration: "underline" }} fontWeight="bold" variant="h2">
                    Application Form For Maternity Leave
                </Typography>

                <Grid item xs={12} md={8}>
                    <Grid mt={1} container spacing={2}>

                        <Grid mt={4} item md={12} xs={12}>
                            <Typography sx={{ display: "flex", justifyContent: "center", textDecoration: "underline" }} fontWeight="bold" variant="h3">
                                Details Regarding Delivery Date
                            </Typography>
                        </Grid>

                        <Grid item mt={3} md={2} xs={6}>
                            <Typography fontWeight="bold" variant="h6">Expected Date Of delivery :</Typography>
                        </Grid>

                        <Grid item mt={3} md={4} xs={6}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {moment(data?.expectedDateOfDelivery?.date).format("DD/MM/YYYY")}
                            </Typography>

                        </Grid>

                        <Grid item mt={3} md={2} xs={6}>
                            <Typography fontWeight="bold" variant="h6">Details Regarding Date Of Delivery :</Typography>
                        </Grid>
                        <Grid item mt={3} md={4} xs={6}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {data?.expectedDateOfDelivery?.remarks}
                            </Typography>

                        </Grid>

                        <Grid item mt={3} md={3} xs={3.5} >
                            <Typography fontWeight="bold" variant="h6">Document Regarding Date Of Delivery : </Typography>

                        </Grid>
                        <Grid item mt={3} md={9} xs={8.5}>
                            {(data?.expectedDateOfDelivery?.attechment ? (

                                <FileDownloadComponent
                                    key={data.userId.first_name}
                                    downloadName={`${data.userId.first_name}.pdf`}
                                    src={data?.expectedDateOfDelivery?.attechment}
                                    Component={({ loading, ...props }) => (
                                        <Box>
                                            {loading ? <CircularProgress size={20} /> : <Download  {...props} disabled={loading} />}
                                        </Box>
                                    )}
                                />
                            ) :
                                <Box mt={8} sx={{ float: "right", marginTop: "15px" }}><Download onClick={errorfor} /></Box>)}
                        </Grid>


                        <Grid mt={4} item md={12} xs={12}>

                            <Typography sx={{ display: "flex", justifyContent: "center", textDecoration: "underline" }} fontWeight="bold" variant="h3">
                                Details Regarding Maternity Leave Period
                            </Typography>
                        </Grid>

                        <Grid item mt={3} md={2.5} xs={6}>
                            <Typography fontWeight="bold" variant="h6">Date From Which Leave Is To Be Availed :</Typography>
                        </Grid>

                        <Grid item mt={3} md={3.5} xs={6}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {moment(data?.dateFromLeave).format("DD/MM/YYYY")}
                            </Typography>

                        </Grid>

                        <Grid item mt={3} md={2.5} xs={6}>
                            <Typography fontWeight="bold" variant="h6">Total No. Of Weeks (Leave) :</Typography>
                        </Grid>
                        <Grid item mt={3} md={3.5} xs={2}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {data?.numberOfWeeks?.total}
                            </Typography>

                        </Grid>

                        <Grid item mt={3} md={2.5} xs={6}>
                            <Typography fontWeight="bold" variant="h6">No. Of Weeks Pre-Delivery :</Typography>
                        </Grid>
                        <Grid item mt={3} md={3.5} xs={2}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {data?.numberOfWeeks?.preDelivery}
                            </Typography>

                        </Grid>


                        <Grid item mt={3} md={2.5} xs={6}>
                            <Typography fontWeight="bold" variant="h6">No. Of Weeks Post-Delivery :</Typography>
                        </Grid>
                        <Grid item mt={3} md={3.5} xs={2}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {data?.numberOfWeeks?.postDelivery}
                            </Typography>

                        </Grid>


                        <Grid mt={4} item md={12} xs={12}>

                            <Typography sx={{ display: "flex", justifyContent: "center", textDecoration: "underline" }} fontWeight="bold" variant="h3">
                                Other Details
                            </Typography>
                        </Grid>


                        <Grid item mt={3} md={2} xs={2.6} >
                            <Typography fontWeight="bold" variant="h6">The Expected Child : </Typography>

                        </Grid>
                        <Grid item mt={3} md={10} xs={9.4} >

                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {findObjectKeyByValue(data?.expectedChild?.first
                                    , EXPECTED_CHILD)}
                            </Typography>

                        </Grid>


                        <Grid item mt={3} md={2} xs={2.6} >
                            <Typography fontWeight="bold" variant="h6">Whether Adoption ? : </Typography>

                        </Grid>
                        <Grid item mt={3} md={10} xs={9.4} >

                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {findObjectKeyByValue(data?.adoption?.isAdopted
                                    , ADOPTION_RESPONSE)}
                            </Typography>

                        </Grid>


                        {data?.adoption?.isAdopted === ADOPTION_RESPONSE.YES &&
                            <>

                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Details Regarding Adoption :</Typography>
                                </Grid>
                                <Grid item mt={3} md={7} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data?.expectedDateOfDelivery?.remarks}
                                    </Typography>

                                </Grid>


                                <Grid item mt={3} md={2} xs={3.5} >
                                    <Typography fontWeight="bold" variant="h6">Adoption Document : </Typography>

                                </Grid>
                                <Grid item mt={3} md={1} xs={8.5}>
                                    {(data?.adoption?.attechment ? (

                                        <FileDownloadComponent
                                            key={data.userId.first_name}
                                            downloadName={`${data.userId.first_name}.pdf`}
                                            src={data?.adoption?.attechment}
                                            Component={({ loading, ...props }) => (
                                                <Box>
                                                    {loading ? <CircularProgress size={20} /> : <Download  {...props} disabled={loading} />}
                                                </Box>
                                            )}
                                        />
                                    ) :
                                        <Box mt={8} sx={{ float: "right", marginTop: "15px" }}><Download onClick={errorfor} /></Box>)}
                                </Grid>

                            </>
                        }

                        <Grid item mt={3} md={2} xs={2.6} >
                            <Typography fontWeight="bold" variant="h6">Any Facility Needed While Pregnancy ? : </Typography>

                        </Grid>
                        <Grid item mt={3} md={10} xs={9.4} >

                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {findObjectKeyByValue(data?.modificationsNeeded?.isNeeded
                                    , ADOPTION_RESPONSE)}
                            </Typography>

                        </Grid>


                        {data?.modificationsNeeded?.isNeeded === ADOPTION_RESPONSE.YES &&
                            <>

                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Details Regarding Facility Needed :</Typography>
                                </Grid>
                                <Grid item mt={3} md={7} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data?.modificationsNeeded?.remarks}
                                    </Typography>

                                </Grid>


                                <Grid item mt={3} md={2} xs={3.5} >
                                    <Typography fontWeight="bold" variant="h6">Related Document : </Typography>

                                </Grid>
                                <Grid item mt={3} md={1} xs={8.5}>
                                    {(data?.modificationsNeeded?.attechment ? (

                                        <FileDownloadComponent
                                            key={data.userId.first_name}
                                            downloadName={`${data.userId.first_name}.pdf`}
                                            src={data?.modificationsNeeded?.attechment}
                                            Component={({ loading, ...props }) => (
                                                <Box>
                                                    {loading ? <CircularProgress size={20} /> : <Download  {...props} disabled={loading} />}
                                                </Box>
                                            )}
                                        />
                                    ) :
                                        <Box mt={8} sx={{ float: "right", marginTop: "15px" }}><Download onClick={errorfor} /></Box>)}
                                </Grid>

                            </>
                        }


                        <Grid item mt={3} md={2} xs={2.6} >
                            <Typography fontWeight="bold" variant="h6">Any Counseling Needed ? : </Typography>

                        </Grid>
                        <Grid item mt={3} md={10} xs={9.4} >

                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {findObjectKeyByValue(data?.counselingNeeded?.isNeeded
                                    , ADOPTION_RESPONSE)}
                            </Typography>

                        </Grid>


                        {data?.counselingNeeded?.isNeeded === ADOPTION_RESPONSE.YES &&
                            <>

                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Details Regarding Counseling Needed :</Typography>
                                </Grid>
                                <Grid item mt={3} md={7} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data?.counselingNeeded?.remarks}
                                    </Typography>

                                </Grid>


                                <Grid item mt={3} md={2} xs={3.5} >
                                    <Typography fontWeight="bold" variant="h6">Counseling Related Document : </Typography>

                                </Grid>
                                <Grid item mt={3} md={1} xs={8.5}>
                                    {(data?.counselingNeeded?.attechment ? (

                                        <FileDownloadComponent
                                            key={data.userId.first_name}
                                            downloadName={`${data.userId.first_name}.pdf`}
                                            src={data?.counselingNeeded?.attechment}
                                            Component={({ loading, ...props }) => (
                                                <Box>
                                                    {loading ? <CircularProgress size={20} /> : <Download  {...props} disabled={loading} />}
                                                </Box>
                                            )}
                                        />
                                    ) :
                                        <Box mt={8} sx={{ float: "right", marginTop: "15px" }}><Download onClick={errorfor} /></Box>)}
                                </Grid>

                            </>
                        }


                        <Grid item mt={3} md={2} xs={2.6} >
                            <Typography fontWeight="bold" variant="h6">Medical Documents Submitted ? : </Typography>

                        </Grid>
                        <Grid item mt={3} md={10} xs={9.4} >

                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {findObjectKeyByValue(data?.medicalDocumentsSubmitted?.isSubmitted
                                    , ADOPTION_RESPONSE)}
                            </Typography>

                        </Grid>


                        {data?.medicalDocumentsSubmitted?.isSubmitted === ADOPTION_RESPONSE.YES &&
                            <>

                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Details Regarding Medical Documents :</Typography>
                                </Grid>
                                <Grid item mt={3} md={7} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data?.medicalDocumentsSubmitted?.remarks}
                                    </Typography>

                                </Grid>


                                <Grid item mt={3} md={2} xs={3.5} >
                                    <Typography fontWeight="bold" variant="h6">Medical Document : </Typography>

                                </Grid>
                                <Grid item mt={3} md={1} xs={8.5}>
                                    {(data?.medicalDocumentsSubmitted?.attechment ? (

                                        <FileDownloadComponent
                                            key={data.userId.first_name}
                                            downloadName={`${data.userId.first_name}.pdf`}
                                            src={data?.medicalDocumentsSubmitted?.attechment}
                                            Component={({ loading, ...props }) => (
                                                <Box>
                                                    {loading ? <CircularProgress size={20} /> : <Download  {...props} disabled={loading} />}
                                                </Box>
                                            )}
                                        />
                                    ) :
                                        <Box mt={8} sx={{ float: "right", marginTop: "15px" }}><Download onClick={errorfor} /></Box>)}
                                </Grid>

                            </>
                        }

                        {data?.medicalDocumentsSubmitted?.isSubmitted === ADOPTION_RESPONSE.NO &&
                            <>
                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Details For Not Submitting Medical Documents :</Typography>
                                </Grid>
                                <Grid item mt={3} md={5} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data?.medicalDocumentsSubmitted?.remarks}
                                    </Typography>

                                </Grid>
                            </>
                        }

                    </Grid>
                </Grid>

            </Box >

        </>

    )
}

export default MaternityComplaintModalUi