import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CenteredBox } from "../../components/layouts/common/boxes";
import { useCallback, useState } from "react";
import FileInput from "../../components/inputs/FileInput";
import moment from "moment";
import UserSearchBar from "../../components/inputs/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { selectEmployee } from "../../store/actions/selectedEmployeeAction";

const UploadSheetUI = ({ loading, onSubmit, fields, setFields }) => {
  const dispatch = useDispatch();
  const { selectedEmployee, user } = useSelector((state) => state);
  const onUserChange = useCallback((user) => {
    dispatch(selectEmployee(user));
  }, []);
  const currentDate = new Date();
  const updatedDate = moment(currentDate).set("date", 1).set("month", 3);
  const [date, setDate] = useState(updatedDate);
  const defaultVal = selectEmployee.user;
  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}

        {fields.err && (
          <Typography color="error" variant="h5">
            {fields.err}
          </Typography>
        )}

        {!loading && (
          <>
            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h3">
                  {" "}
                  USER :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <UserSearchBar
                      defaultVal={defaultVal}
                      onUserChange={onUserChange}
                      onChange={async (changedVal) => {
                        setFields({
                          ...fields,
                          userId: changedVal ? changedVal._id : null,
                        });
                      }}
                      titleKey={"name"}
                      valueKey={"_id"}
                      InputComponent={(params) => (
                        <TextField
                          placeholder={"Select User"}
                          {...params}
                          margin="none"
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h3">
                  {" "}
                  PDF :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <FileInput
                  multi
                  sx={{ width: "100%" }}
                  onChange={(file, fileArr) => {
                    setFields({ ...fields, url: fileArr });
                  }}
                  defaults={fields.url ?? []}
                  accept=".pdf"
                  title="Form pdf*"
                  subTitle="Only pdf files are allowed! less than 1 MB"
                />
              </Box>
            </Box>
            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h3">
                  {" "}
                  SalaryStructure Year :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <DatePicker
                      minDate={moment(updatedDate)}
                      // maxDate={moment().add(-1,'month').endOf("month")}
                      views={["year"]}
                      inputFormat="YYYY"
                      onChange={(e) =>
                        setFields({ ...fields, err: "", year: e })
                      }
                      value={fields.year}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          error={false}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ float: "right", marginTop: "7px" }}>
              <Button
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default UploadSheetUI;
