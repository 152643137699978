import React from 'react'
import AsyncDropDown from '../../components/inputs/AsyncDropDown';
import { fetchDepartments } from '../../apis/department';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import CustomInput from '../../components/inputs/CustomInput';
import { UserSearchBarNormal } from '../../components/inputs/SearchBar';
import SubmitButton from '../../components/button/SubmitButton';

const MultipleDepartmentAddUi = ({ MultipleDepartmentAdd, Setfield, field, loading }) => {
    
    return (
        <>
            <Grid xs={12} md={6} item>
                <Box>
                    <UserSearchBarNormal
                        defaultVal={
                            field.id
                                ? {
                                    _id: field.id,
                                    name: field.parent_id_name,
                                }
                                : null
                        }
                        inputProps={{
                            label: "Select User",
                        }}
                        onUserChange={(newVal) => {
                            Setfield({
                                ...field,
                                err: "",
                                id: newVal._id,
                            });
                        }}
                    />
                    <FormHelperText>
                        {/* {getErrorMessage("Reporing Person")} */}
                    </FormHelperText>
                </Box>
            </Grid>
            <Grid xs={12} md={6} item>
                <Box>
                    <AsyncDropDown
                        defaultVal={
                            field.department
                                ? {
                                    _id: field.department,
                                    name: field.department_name,
                                }
                                : null
                        }
                        InputComponent={(props) => (
                            <CustomInput
                                label="Department"
                                placeholder="Select Department"
                                {...props}
                            />
                        )}
                        label="Department*"
                        lazyFun={async (props) => {
                            return await fetchDepartments({ ...props });
                        }}
                        OptionComponent={({ option, ...rest }) => {
                            return (
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    {...rest}
                                >
                                    <Typography ml={3} variant="h5">
                                        {option.name}
                                    </Typography>
                                </Box>
                            );
                        }}
                        onChange={async (changedVal) => {
                            Setfield({
                                ...field,
                                err: "",
                                department: changedVal._id,
                            });
                        }}
                        titleKey={"name"}
                        valueKey={"_id"}
                    />
                </Box>
            </Grid>
            <Grid xs={12} md={6} item>
                <Box>
                    <UserSearchBarNormal
                        defaultVal={
                            field.parent_id
                                ? {
                                    _id: field.parent_id,
                                    name: field.parent_id_name,
                                }
                                : null
                        }
                        inputProps={{
                            label: "Reporting Person",
                        }}
                        onUserChange={(newVal) => {
                            Setfield({
                                ...field,
                                err: "",
                                parent_id: newVal._id,
                            });
                        }}
                    />
                    <FormHelperText>
                        {/* {getErrorMessage("Reporing Person")} */}
                    </FormHelperText>
                </Box>
            </Grid>
            <Grid xs={12} md={6} item>
                <SubmitButton
                    loading={loading}
                    variant="contained"
                    onClick={MultipleDepartmentAdd}
                    title={"Multiple Department Add"}
                />
            </Grid>
        </>
    )
}

export default MultipleDepartmentAddUi