// import UploadPdfUI from "../appresialLetter/UploadPdfUI";
import React, { useState, useRef } from "react";
// import { UploadSheetApi } from "../../apis/payroll.api";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { closeModal, openModal } from "../../store/actions/modalAction";
// import PreviewTableController from "./PreviewTableController";
import Papa from "papaparse";
import moment from "moment";
import UploadPdfUI from "./UploadPdfUI";
import useValidate from "../../store/hooks/useValidator";
import { fetchDocumentDataAction } from "../../store/actions/settingsAction";
import { CreateForm16DetailsApi, createForm16, fetchForm16 } from "../../apis/form16.api";

const UploadPdfController = (callBack) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().inputFormat='YYYY');
  const [file, setFile] = useState(null);
  const [err, setErr] = useState("");
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState([]);
  const validate = useValidate()
  // const createBranchApi = createBranch
  const { settings } = useSelector((state) => state)


  const [fields, setFields] = useState({
    userId: "",
    url: '',
    year: '',
    err: "",
  })

  const validationSchema = useMemo(() => ([
    {
      required: true,
      value: fields.userId,
      field: ' userId '
    },
    {
      required: true,
      value: fields.url,
      field: 'url',
    },
    {
      required: true,
      value: fields.year,
      field: 'year',
    },
  ]), [fields])

  const onSubmit = (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchema)
    // console.log("kk", validationResponse)  
    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await createForm16({ ...fields }),
        (response) => {
          // console.log("hello", response)
          // callBack(response)
          setLoading(false)
      fetchForm16(response)
          dispatch(closeModal())
        },
        (err) => {
          setLoading(false)
          setFields({ ...fields, err })
        }
      ))
    } else {
      setFields({ ...fields, err: validationResponse })
    }
  };

  // const onSubmit = (e) => {
  //   e.preventDefault()
  //       const validationResponse = validate(validationSchema)

  //       if (validationResponse === true) {
  //           setLoading(true)
  //           dispatch(callApiAction(
  //               async () => await CreateForm16DetailsApi({ ...fields }),
  //               (response) => {
  //                   callBack(response)
  //                   setLoading(false)
  //                   dispatch(fetchDocumentDataAction(settings.document_filters))
  //                   dispatch(closeModal())
  //               },
  //               (err) => {
  //                   setLoading(false)
  //                   setFields({ ...fields, err })
  //               }
  //           ))
  //       } else {
  //           setFields({ ...fields, 'err': validationResponse })
  //       }
  // };

  return (
    <UploadPdfUI
      // setFields={setFields}
      //   onSubmit={onSubmit}
      //   fields={fields}
      //   loading={loading}
      //   data={data}
      //   date={date}
      //   setDate={setDate}
      //   file={file}
      //   setFile={setFile}
      //   err={err}
      //   setErr={setErr}
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
      setFields={setFields}
      err={err}
    />
  );
};

export default UploadPdfController;