import { Box, InputAdornment, OutlinedInput, Typography } from "@mui/material"
import { getUserByIdApi } from "../../apis/user.api"
import { memo, useEffect, useState } from "react"
import { callApiAction } from "../../store/actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/inputs/CustomInput";
import { LEAVE_TYPE } from "../../utils/leave.constants";

const UserSearchId = ({ userId,workable_days,salary ,count,fields,setFields}) => {
    
    const dispatch = useDispatch()
    const { leaveBalance } = useSelector((state) => state)

  const [state,setState] =  useState({});
  const [loading,setLoading] = useState(false)

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getUserByIdApi({userId}),
            (response) => {
                setState(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const handleInputChange = (e) => {
      const newLeaveCount = e.target.value;
      setFields((prevFields) => ({
        ...prevFields,
        leave_count: newLeaveCount,
      }));
    };
  
    useEffect(() => {
      if (state?.salary?.basic_salary && workable_days) {
        const basic_salary = parseInt(state.salary.basic_salary);
        const per_day_salary = basic_salary / workable_days;
        const encash_salary = fields.leave_count * per_day_salary;
  
        setFields((prevFields) => ({
          ...prevFields,
          salary: encash_salary,
        }));
      }
    }, [fields.leave_count, state.salary, workable_days, setFields]);
  
    useEffect(() => {
     fetchList()
    }, [userId]);
    

    return (
        <>
          <Typography variant="h3" mt={3}>
           Leave Count :
          </Typography>
                <OutlinedInput
                    min={0}
                    fullWidth
                    disabled={loading}
                    value={fields.leave_count}
                    endAdornment={<InputAdornment position="end" >
                        <Box sx={{ borderLeft: "2px solid lightgrey" }} pl={2} >
                            <Typography fontSize="inherit">
                                ₹ {fields.salary && !isNaN(fields.salary) ? fields.salary.toFixed(2) : 0}
                            </Typography>

                        </Box>
                    </InputAdornment>}
                    onChange={handleInputChange}
                    type="number"
             />
        </>
    )
  
  }
  export default memo(UserSearchId)