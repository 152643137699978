import UploadSheetUI from "../payrollSummary/UploadSheetUI";
import React, { useState, useRef } from "react";
import { UploadSheetApi } from "../../apis/payroll.api";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { openModal } from "../../store/actions/modalAction";
import PreviewTableController from "./PreviewTableController";
import Papa from "papaparse";
import moment from "moment";

const UploadSheetController = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().add(-1, "month"));
  const [file, setFile] = useState(null);
  const [err, setErr] = useState("");
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState([]);

  const fields = [
    "employee_id",
    "company_name",
    "workable_days",
    "worked_days",
    "ph",
    "cl",
    "sl",
    "pl",
    "coff",
    "lwp",
    "basic_salary",
    "house_rent_allowance",
    "conveyence_allowance",
    "food_allowance",
    "other_allowance",
    "medical_allowance",
    "incencitive",
    "proffesional_tax",
    "tds_deduction",
    "pf",
    "esic",
    "leave_deduction",
    "attual_basic_salary",
    "attual_house_rent_allowance",
    "attual_conveyence_allowance",
    "attual_food_allowance",
    "attual_other_allowance",
    "attual_medical_allowance",
    "attual_incencitive",
  ];

  const onFileSelect = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    setFile(e.target.files[0]);

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;

      const columns = Object.keys(parsedData[0]);

      let hasColumnError = false;

      for (let field of fields) {
        if (!columns.includes(field)) {
          setErr(`Field with field name ${field} doesnot exist.`);
          setLoading(false);
          hasColumnError = true;
          break;
        }
      }

      if (parsedData && Array.isArray(parsedData)) {
        const tempData = parsedData.map((parsedrow) => {
          if (Object.keys(parsedrow).length == columns.length) {
            const rowData = {
              hasError: false,
              data: {},
            };
            const row = {};
            for (let cell in parsedrow) {
              if (
                (cell == "employee_id" || cell == "company_name") &&
                parsedrow[cell] == ""
              ) {
                rowData["hasError"] = true;
                setHasErr(true);
                row[cell] = {
                  value: parsedrow[cell],
                  err: "This cell should be non empty.",
                };
              } else if (
                cell != "employee_id" &&
                cell != "company_name" &&
                (!cell || !parsedrow[cell] || isNaN(parsedrow[cell]))
              ) {
                row[cell] = {
                  value: 0,
                  err: "",
                };
              } else
                row[cell] = {
                  value: parsedrow[cell],
                  err: "",
                };
            }
            rowData["data"] = row;

            return rowData;
          }
        });
        setData(tempData);
      } else {
        setErr("cant read file.");
      }

      setLoading(false);
    };
    reader.readAsText(file);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (file != null && date != "") {
      dispatch(
        openModal({
          title: "Sheet Preview",
          component: (
            <PreviewTableController
              hasError={hasErr}
              columns={fields}
              file={file}
              data={data}
              date={date.valueOf()}
            ></PreviewTableController>
          ),
        })
      );
    } else {
      setErr("Please select file & date");
    }
  };

  return (
    <UploadSheetUI
      onSubmit={onSubmit}
      fields={fields}
      loading={loading}
      uploadSheet={onFileSelect}
      data={data}
      date={date}
      setDate={setDate}
      file={file}
      setFile={setFile}
      err={err}
      setErr={setErr}
    />
  );
};

export default UploadSheetController;
