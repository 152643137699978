import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"

import { fetchOneYearWorkFromHomeDataApi } from "../../apis/wfh.api"
import moment from "moment"
import DataTable from "../../components/tables/DataTable"
import WFHStatusComponent from "./WFHStatusComponent"
import WFHDetailShowButton from "./WFHDetailShowButton"
import { Box } from "@mui/material"
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"
import CustomYearPicker from "../../components/layouts/common/CustomYearPicker"



const WFHSummarryController = ({ userId }) => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [state, setState] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = useMemo(() => [
        { id: 1, fieldName: 'date', label: 'Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.date).format('DD/MM/YYYY') },
        { id: 4, fieldName: 'start_time', label: 'Duration', align: "left", sort: true, minWidth: '150px', renderValue: (params) => (moment(params.start_time).format('hh:mm A') + " - " + moment(params.end_time).format('hh:mm A')) },
        { id: 2, fieldName: 'reason', label: 'Reason', align: "left", minWidth: '250px' },
        { id: 3, sort: true, fieldName: 'status', label: 'Status', align: "left", minWidth: '250px', renderValue: (params) => <WFHStatusComponent params={params} /> },
        {
            id: 5, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
                <WFHDetailShowButton params={params} setParams={setParams} />
            </Box>
        },
    ], [dispatch]);

    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1,
        userId,
        date: moment(),
         // Added monthFilter flag
    });

    const fetchList = () => {
        setLoading(true);
        const passFilters = { ...filters };
        passFilters.date = passFilters.date.year();

        dispatch(callApiAction(
            async () => await fetchOneYearWorkFromHomeDataApi(passFilters),
            (response) => {
                setState(response);
                setLoading(false);
            },
            (err) => {
                setLoading(false);
            }
        ));
    };

    useEffect(() => {
        fetchList();
    }, [filters]);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} mt={2}>
                <CustomYearPicker date={filters.date} setDate={(date) => { setFilters({ ...filters, date }) }} />
            </Box>
            <Box sx={{ minHeight: "300px" }} mt={3}>
            <DataTable columns={columns} rows={state} count={state?.length} filters={filters} setFilters={setFilters} loading={loading} />
            </Box>
        </>
    );
};

export default WFHSummarryController;
