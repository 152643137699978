import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import DataTable from "../../components/tables/DataTable";
import CustomInput from "../../components/inputs/CustomInput";
import { POLICY_COMPLAINT_TYPE } from "../../utils/constants";
import PaddingBoxInDesktop from "../../components/layouts/common/PaddingBoxInDesktop";

const ComplainListUi = ({
  date,
  filters,
  setFilters,
  complainButtonRef,
  callBack,
  loading,
  state,
  columns,
  fields,
  setFields,
  setComplainButton,
  complainButton,
}) => {
  return (
    <>
      <Box m={3}>
        <PaddingBoxInDesktop
          sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
        >
          <ButtonGroup
            disableElevation
            variant="outlined"
            sx={{ height: "6vh" }}
          >
            <Button
              onClick={() => {
                // setFields({ ...fields, err: "" });
                setComplainButton(POLICY_COMPLAINT_TYPE.Disability);
                complainButtonRef.current = POLICY_COMPLAINT_TYPE.Disability;
                setFilters({
                  pageNo: 1,
                  pageSize: 10,
                  search: "",
                  searchable: ["userId.employee_id"],
                  sort: "createdAt",
                  sortDirection: -1,
                });
              }}
              variant={
                complainButton == POLICY_COMPLAINT_TYPE.Disability
                  ? "contained"
                  : "outlined"
              }
            >
              <Typography variant="h5">Disability</Typography>
            </Button>
            <Button
              onClick={() => {
                setComplainButton(POLICY_COMPLAINT_TYPE.Transgender);
                complainButtonRef.current = POLICY_COMPLAINT_TYPE.Transgender;
                setFilters({
                  pageNo: 1,
                  pageSize: 10,
                  search: "",
                  searchable: ["userId.employee_id"],
                  sort: "createdAt",
                  sortDirection: -1,
                });
              }}
              variant={
                complainButton == POLICY_COMPLAINT_TYPE.Transgender
                  ? "contained"
                  : "outlined"
              }
            >
              <Typography variant="h5">Transgender</Typography>
            </Button>
            <Button
              onClick={() => {
                setComplainButton(POLICY_COMPLAINT_TYPE.POSH);
                complainButtonRef.current = POLICY_COMPLAINT_TYPE.POSH;
                setFilters({
                  pageNo: 1,
                  pageSize: 10,
                  search: "",
                  searchable: ["userId.employee_id"],
                  sort: "createdAt",
                  sortDirection: -1,
                });
              }}
              variant={
                complainButton == POLICY_COMPLAINT_TYPE.POSH
                  ? "contained"
                  : "outlined"
              }
            >
              <Typography variant="h5">POSH</Typography>
            </Button>
            <Button
              onClick={() => {
                // setFields({ ...fields, err: "" });
                setComplainButton(POLICY_COMPLAINT_TYPE.Maternity_Application);
                complainButtonRef.current =
                  POLICY_COMPLAINT_TYPE.Maternity_Application;
                setFilters({
                  pageNo: 1,
                  pageSize: 10,
                  search: "",
                  searchable: ["userId.employee_id"],
                  sort: "createdAt",
                  sortDirection: -1,
                });
              }}
              variant={
                complainButton == POLICY_COMPLAINT_TYPE.Maternity_Application
                  ? "contained"
                  : "outlined"
              }
            >
              <Typography variant="h5">Maternity Applications</Typography>
            </Button>
          </ButtonGroup>
        </PaddingBoxInDesktop>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              color: "#0D0D0D",
            }}
          >
            Complain List
          </Typography>
        </Box>
        <Box sx={{ minHeight: "300px" }} mt={3}>
          <DataTable
            key={date}
            columns={columns}
            rows={state.result}
            count={state.total}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};
export default ComplainListUi;
