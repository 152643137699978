import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import { CenteredBox } from "../../../components/layouts/common/boxes";
import CustomMonthPicker from "../../../components/layouts/common/CustomMonthPicker";

const ListContainer = styled(Box)(({ theme }) => ({
  width: "100%",

  background: theme.palette.grey[200],
}));

const ListItemContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflowX: "auto",

  width: "100%",
  borderRadius: 1,
  "*": {
    ul: {
      paddingLeft: theme.spacing(4),
    },
    ol: {
      paddingLeft: theme.spacing(4),
    },
    b: {
      fontWeight: "bold",
    },
    a: {
      color: "blue",
      cursor: "pointer",
    },

    td: {
      border: "1px solid black",
      minWidth: "100px",
    },
  },
}));

const DailyUpdateDateViseRow = ({ data, date }) => {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex" }}>
        <Box
          p={1}
          elevation={0}
          sx={{ background: "#000" }}
          mb={1}
          mt={1}
          px={3}
          component={Paper}
        >
          <Typography variant="body2" color="light.main">
            {moment(date).format("DD MMM YYYY")}
          </Typography>
        </Box>
      </Box>
      <ListContainer p={1} px={3}>
        {Object.entries(data).map(([department, tasks]) => (
          <Box key={department} mb={2}>
            <Typography variant="h6" color="primary.main" fontWeight="bold">
              {department}
            </Typography>
            {tasks.map((item, index) => (
              <ListItemContainer
                mb={1}
                key={index}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            ))}
          </Box>
        ))}
        {Object.keys(data).length === 0 && (
          <ListItemContainer>
            <CenteredBox p={1}>NA</CenteredBox>
          </ListItemContainer>
        )}
      </ListContainer>
    </Box>
  );
};

const DailyUpdateReportUi = ({
  list,
  loading,
  filters,
  setFilters,
  showSingleDay, // This controls if we are showing only one day
}) => {
  const date = filters.date;
  const setDate = (newDate) => {
    setFilters({ ...filters, date: newDate });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* Conditionally render the date picker only if not showing a single day */}
        {!showSingleDay && <CustomMonthPicker date={date} setDate={setDate} />}

        {!loading && list && list.length > 0 ? (
          showSingleDay ? (
            // If showing only one day, render a single day's tasks
            <DailyUpdateDateViseRow data={list[0]} date={date} />
          ) : (
            // Otherwise, render the entire month as before
            list.map((item, index) => (
              <DailyUpdateDateViseRow
                data={item}
                date={moment(filters.date).set("date", index + 1)}
                key={index}
              />
            ))
          )
        ) : (
          <CenteredBox>
            {!loading && <Typography>No Data</Typography>}
          </CenteredBox>
        )}

        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}
      </Box>
    </>
  );
};

export default DailyUpdateReportUi;
