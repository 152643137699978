import { Box, CircularProgress, Grid, Typography, styled } from '@mui/material'
import moment from 'moment'
import React from 'react'
import FileDownloadComponent from '../../components/FileDownloadComponent'
import { COMPLAINT_RELATION_TO, COMPLAINT_RELATION_TO_TRANSGENDER, DISIBILITY_TYPE, DISIBILITY_TYPE_DATA, SNACK_BAR_VARIETNS, TYPE_OF_COMPLAINT } from '../../utils/constants'
import { findObjectKeyByValue, titleCase, toTitleCase } from '../../utils/helper'
import SubmitButton from '../../components/button/SubmitButton'
import { useDispatch } from 'react-redux'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { Download } from '@mui/icons-material'

const TransgenderComplaintModalUi = ({ id, data, setData, loading }) => {
    console.log("object daata", data);
    const dispatch = useDispatch()

    const errorfor = () => {
        dispatch(
            callSnackBar("No Document Available", SNACK_BAR_VARIETNS.error)
        );
    }
    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Typography sx={{ display: "flex", justifyContent: "center", textDecoration: "underline" }} fontWeight="bold" variant="h2">
                    Grievence Form For Transgender
                </Typography>

                <Grid item xs={12} md={8}>
                    <Grid mt={1} container spacing={2}>
                        <Grid item mt={3} md={3.5} xs={3.5} >
                            <Typography fontWeight="bold" variant="h6">Transgender Certificate And ID Card : </Typography>

                        </Grid>
                        <Grid item mt={3} md={8.5} xs={8.5}>
                            {(data.transgenders_certificate ? (

                                <FileDownloadComponent
                                    key={data.userId.first_name}
                                    downloadName={`${data.userId.first_name}.pdf`}
                                    src={data.transgenders_certificate}
                                    Component={({ loading, ...props }) => (
                                        <Box>
                                            {loading ? <CircularProgress size={20} /> : <Download  {...props} disabled={loading} />}
                                        </Box>
                                    )}
                                />
                            ) :
                                <Box mt={8} sx={{ float: "right", marginTop: "15px" }}><Download onClick={errorfor} /></Box>)}
                        </Grid>

                        <Grid item mt={3} md={2.5} xs={6} >
                            <Typography fontWeight="bold" variant="h6">Complaint In Relation To : </Typography>

                        </Grid>
                        <Grid item mt={3} md={9.5} xs={8.5} >

                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {findObjectKeyByValue(data.complaint_to_transgender, COMPLAINT_RELATION_TO_TRANSGENDER)}
                            </Typography>

                        </Grid>

                        {data.complaint_to_transgender == COMPLAINT_RELATION_TO_TRANSGENDER['Co-Worker'] &&
                            <>
                                <Grid mt={4} item md={12} xs={12}>
                                    <Typography sx={{ display: "flex", justifyContent: "center", textDecoration: "underline" }} fontWeight="bold" variant="h3">
                                        Details Related To Co-Worker
                                    </Typography>
                                </Grid>

                                <Grid item mt={3} md={2} xs={6} >
                                    <Typography fontWeight="bold" variant="h6">Name Of Co-Worker: </Typography>

                                </Grid>

                                <Grid item mt={3} md={4} xs={6} >

                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.co_worker.first_name}&nbsp;{data.co_worker.last_name}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={2} xs={6} >
                                    <Typography fontWeight="bold" variant="h6">Type Of Complaint : </Typography>

                                </Grid>

                                <Grid item mt={3} md={4} xs={6} >

                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {findObjectKeyByValue(data.complaint_type, TYPE_OF_COMPLAINT)}
                                    </Typography>

                                </Grid>


                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Date Of The Incident :</Typography>
                                </Grid>

                                <Grid item mt={3} md={4} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {moment(data.incident_date).format("DD/MM/YYYY")}
                                    </Typography>

                                </Grid>


                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Time Of The Incident :</Typography>
                                </Grid>

                                <Grid item mt={3} md={4} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {moment(data.incident_date).format("h:mm:ss a")}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Location Where The Incident Has Taken Place :</Typography>
                                </Grid>
                                <Grid item mt={3} md={4} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.location}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Frequency Of The Incident :</Typography>
                                </Grid>
                                <Grid item mt={3} md={4} xs={2}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.frequency}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Name Of Witness :</Typography>
                                </Grid>
                                <Grid item mt={3} md={10} xs={6}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.witness_name}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Remarks Of The Witness :</Typography>
                                </Grid>
                                <Grid item mt={3} md={10} xs={8.5}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.witness_remarks}
                                    </Typography>

                                </Grid>


                                <Grid item mt={3} md={2} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Details Of Misconduct :</Typography>
                                </Grid>
                                <Grid item mt={3} md={10} xs={9}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.remarks}
                                    </Typography>

                                </Grid>


                                <Grid item mt={3} md={4} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Documents Supporting The Evidence :</Typography>
                                </Grid>
                                <Grid item mt={3} md={8} xs={6}>
                                    {(data.evidence ? (

                                        <FileDownloadComponent
                                            key={data.co_worker.first_name}
                                            downloadName={`${data.co_worker.first_name}.pdf`}
                                            src={data.evidence}
                                            Component={({ loading, ...props }) => (
                                                <Box>
                                                    {loading ? <CircularProgress size={20} /> : <Download  {...props} disabled={loading} />}
                                                </Box>
                                            )}
                                        />
                                    ) :
                                        <Box mt={8} sx={{ float: "right", marginTop: "15px" }}><Download onClick={errorfor} /></Box>)}
                                </Grid>

                            </>
                        }

                        {data.complaint_to_transgender == COMPLAINT_RELATION_TO_TRANSGENDER['Working Environment'] &&
                            <>
                                <Grid mt={4} item md={12} xs={12}>
                                    <Typography sx={{ display: "flex", justifyContent: "center", textDecoration: "underline" }} fontWeight="bold" variant="h3">
                                        Details Related To Working Environment
                                    </Typography>
                                </Grid>

                                <Grid item mt={3} md={3.5} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Infrastructure Related Remarks :</Typography>
                                </Grid>
                                <Grid item mt={3} md={8.5} xs={8.5}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.infrastructure}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={3.5} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Accessibility For Example - Replacing Steps With Ramps Etc. :</Typography>
                                </Grid>
                                <Grid item mt={3} md={8.5} xs={8.5}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.accessibility}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={3.5} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Placing Audio-Visual FireAlarms :</Typography>
                                </Grid>
                                <Grid item mt={3} md={8.5} xs={8.5}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.placing_audio_visual_fire_alarms}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={3.5} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Communication Technologies :</Typography>
                                </Grid>
                                <Grid item mt={3} md={8.5} xs={8.5}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.communication_technologies}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={3.5} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Special Leave :</Typography>
                                </Grid>
                                <Grid item mt={3} md={8.5} xs={8.5}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.special_leave}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={3.5} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Accessible Rest Rooms :</Typography>
                                </Grid>
                                <Grid item mt={3} md={8.5} xs={8.5}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.accessible_rest_rooms}
                                    </Typography>

                                </Grid>

                                <Grid item mt={3} md={3.5} xs={6}>
                                    <Typography fontWeight="bold" variant="h6">Other Details :</Typography>
                                </Grid>
                                <Grid item mt={3} md={8.5} xs={8.5}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {data.other_details_working_environment}
                                    </Typography>

                                </Grid>

                            </>
                        }

                        <Grid mt={4} item md={12} xs={12}>
                            <Typography sx={{ display: "flex", justifyContent: "center", textDecoration: "underline" }} fontWeight="bold" variant="h3">
                                Other Details
                            </Typography>
                        </Grid>

                        <Grid mt={3} item md={5} xs={6}>
                            <Typography fontWeight="bold" variant="h6">Steps Taken By The Complainant To Resolve The Issue :</Typography>
                        </Grid>
                        <Grid mt={3} item md={7} xs={6}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {data.step_taken}
                            </Typography>

                        </Grid>


                        <Grid mt={3} item md={5} xs={6}>
                            <Typography fontWeight="bold" variant="h6">Remedy Or Facility Demanded By The Complainant :</Typography>
                        </Grid>
                        <Grid mt={3} item md={7} xs={6}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {data.facility_demanded}
                            </Typography>

                        </Grid>

                    </Grid>
                </Grid>

            </Box >

        </>

    )
}

export default TransgenderComplaintModalUi