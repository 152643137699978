import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { callApiAction } from '../../../store/actions/commonAction';
import { getPmsById } from '../../../apis/pms.api';
import { callSnackBar } from '../../../store/actions/snackbarAction';
import { SNACK_BAR_VARIETNS } from '../../../utils/constants';
import PmsDetailUi from './PmsDetailsUi';


const PmsDetailController = ({ id, task, selectedUser, callBack = () => {} }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pmsDetail, setPmsDetail] = useState(task || {}); // Initialize with task details

  useEffect(() => {
    if (!task) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await getPmsById({ id }),
          (response) => {
            setLoading(false);
            setPmsDetail(response.data);
          },
          (err) => {
            setLoading(false);
            setPmsDetail({});
            dispatch(callSnackBar("Failed to fetch PMS details", SNACK_BAR_VARIETNS.error));
          }
        )
      );
    }
  }, [id, task, dispatch]);

  return (
    <PmsDetailUi
      pmsDetail={pmsDetail}
      loading={loading}
      selectedUser={selectedUser}
    />
  );
};

export default memo(PmsDetailController);
