import * as React from "react";
import { Box, CircularProgress, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SubmitButton from "../../components/button/SubmitButton";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { useState } from "react";
import { useEffect } from "react";
import { getSalaryStructurePdfByIdApi } from "../../apis/SalaryStructurePdf.api";

const SalaryStructurePdfUI = () => {
  const { user } = useSelector((state) => state);
  const currentDate = new Date();

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([]);
  const updatedDate = moment(currentDate).set("date", 1).set("month", 3);
  const [date, setDate] = useState(updatedDate);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await getSalaryStructurePdfByIdApi({ date: date.toISOString() }),
        (response) => {
          if (Array.isArray(response)) {
            setState(response);
          } else {
            console.error("API response is not an array:", response);
            setState([]);
          }
          setLoading(false);
        },
        (err) => {
          console.error("API call failed:", err);
          setLoading(false);
          setState([]);
        }
      )
    );
  };

  useEffect(() => {
    if (state.length === 0) {
      fetchList();
    }
  }, []);

  const errorfor = () => {
    dispatch(
      callSnackBar("No Salary StructurePdf Available", SNACK_BAR_VARIETNS.error)
    );
  };

  return (
    <>
      <Box mt={3}>
        <DatePicker
          minDate={moment(updatedDate)}
          views={["year"]}
          inputFormat="YYYY"
          onChange={(newValue) => setDate(newValue)}
          value={date}
          renderInput={(params) => (
            <TextField {...params} sx={{ width: "100%" }} error={false} />
          )}
        />

        {state != 0 && state.length > 0 ? (
          state.map((item) => (
            <FileDownloadComponent
              key={user.data.first_name}
              downloadName={`${user.data.first_name}.pdf`}
              src={item.url}
              Component={({ loading, ...props }) => (
                <Box mt={8} sx={{ float: "right", marginTop: "15px" }}>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <SubmitButton
                      {...props}
                      disabled={loading}
                      title={"Download SalaryStructure"}
                    />
                  )}
                </Box>
              )}
            />
          ))
        ) : (
          <Box mt={8} sx={{ float: "right", marginTop: "15px" }}>
            <SubmitButton title={"Download SalaryStructure"} onClick={errorfor} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default SalaryStructurePdfUI;
