import React, { useEffect, useMemo, useState } from 'react'
import MaternityApplicationFormUi from './MaternityApplicationFormUi';
import { closeModal } from '../../store/actions/modalAction';
import { useDispatch, useSelector } from 'react-redux';
import { callApiAction } from '../../store/actions/commonAction';
import { fetchDocumentDataAction } from '../../store/actions/settingsAction';
import useValidate from '../../store/hooks/useValidator';
import { maternityApplicationCreateApi, maternityFetchByIdApi } from '../../apis/maternity.api';
import { SNACK_BAR_VARIETNS } from '../../utils/constants';
import { callSnackBar } from '../../store/actions/snackbarAction';

const MaternityFormController = ({ callBack, id }) => {
  const [loading, setLoading] = useState(false)
  const validate = useValidate()
  const dispatch = useDispatch()
  const { settings } = useSelector((state) => state)
  const [fields, setFields] = useState({
    id,
    medicalDocumentsSubmitted: { isSubmitted: null, remarks: "", attechment: [] },
    expectedDateOfDelivery: { date: "", remarks: "", attechment: "" },
    dateFromLeave: "",
    numberOfWeeks: { total: "", preDelivery: "", postDelivery: "" },
    expectedChild: { first: "", second: "", third_or_thereafter: "" },
    adoption: { isAdopted: "", remarks: "", attechment: "" },
    modificationsNeeded: { isNeeded: "", remarks: "", attechment: "" },
    counselingNeeded: { isNeeded: "", remarks: "", attechment: "" },

  })
  console.log("object  fields.expectedDateOfDelivery.date", fields);

  const validationSchema = useMemo(() => ([
    {
      required: true,
      value: fields.expectedDateOfDelivery.date,
      field: ' expectedDateOfDelivery.date'
    },
    {
      required: true,
      value: fields.expectedDateOfDelivery.remarks,
      field: ' Detailed Date Of Delivery Remarks '
    },
    {
      required: true,
      value: fields.expectedDateOfDelivery.attechment,
      field: ' Date of Delivery Attachment'
    },
    {
      required: true,
      value: fields.dateFromLeave,
      field: ' Date From Leave'
    },
    {
      required: true,
      value: fields.numberOfWeeks.total,
      field: ' Number Of Weeks Total'
    },
    {
      required: true,
      value: fields.numberOfWeeks.preDelivery,
      field: ' Number Of Weeks PreDelivery'
    },
    {
      required: true,
      value: fields.numberOfWeeks.postDelivery,
      field: ' numberOfWeeks.postDelivery'
    },

    {
      required: true,
      value: fields.expectedChild.first,
      field: ' expectedChild.first'
    },
    // {
    //   required: true,
    //   value: fields.expectedChild.second,
    //   field: ' expectedChild.second'
    // },
    // {
    //   required: true,
    //   value: fields.expectedChild.third_or_thereafter,
    //   field: ' expectedChild.third_or_thereafter'
    // }, 
    {
      required: true,
      value: fields.adoption.isAdopted,
      field: 'Whether Adopted'
    },
    {
      required: fields.adoption.isAdopted == 1 ? true : false,
      value: fields.adoption.remarks,
      field: ' adoption.isAdopted'
    },
    {
      required: fields.adoption.isAdopted == 1 ? true : false,
      value: fields.adoption.attechment,
      field: ' adoption.isAdopted'
    },
    {
      required: true,
      value: fields.modificationsNeeded.isNeeded,
      field: ' modificationsNeeded.isNeeded'
    },
    {
      required: fields.modificationsNeeded.isNeeded == 1 ? true : false,
      value: fields.modificationsNeeded.remarks,
      field: ' modificationsNeeded.remarks'
    },
    {
      required: fields.modificationsNeeded.isNeeded == 1 ? true : false,
      value: fields.modificationsNeeded.attechment,
      field: ' modificationsNeeded.attechment'
    },
    {
      required: true,
      value: fields.counselingNeeded.isNeeded,
      field: ' counselingNeeded.isNeeded'
    },
    {
      required: fields.counselingNeeded.isNeeded == 1 ? true : false,
      value: fields.counselingNeeded.remarks,
      field: ' counselingNeeded.remarks'
    },
    {
      required: fields.counselingNeeded.isNeeded == 1 ? true : false,
      value: fields.counselingNeeded.attechment,
      field: ' counselingNeeded.attechment'
    },
    {
      required: true,
      value: fields.medicalDocumentsSubmitted.isSubmitted,
      field: ' medicalDocumentsSubmitted.isSubmitted'
    },
    {
      required: fields.medicalDocumentsSubmitted.isSubmitted == 1 ? true : false,
      value: fields.medicalDocumentsSubmitted.remarks,
      field: ' medicalDocumentsSubmitted.remarks'
    },
    {
      required: fields.medicalDocumentsSubmitted.isSubmitted == 1 ? true : false,
      value: fields.medicalDocumentsSubmitted.attechment,
      field: ' medicalDocumentsSubmitted.attechment'
    },

  ]), [fields])


  const submitData = (e) => {
    console.log("obhjbgjhb jhbgji ject");
    e.preventDefault()
    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await maternityApplicationCreateApi({ ...fields }),
        (response) => {
          // callBack()
          setLoading(false)
          dispatch(closeModal())
          dispatch(
            callSnackBar("Maternity Application Applied Successfully", SNACK_BAR_VARIETNS.suceess)
          );
        },
        (err) => {
          setLoading(false)
          setFields({ ...fields, err })
        }
      ))
    } else {
      setFields({ ...fields, 'err': validationResponse })
      // console.log("err");

    }
  }

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await maternityFetchByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <MaternityApplicationFormUi
      id={id}
      fields={fields}
      setFields={setFields}
      onSubmit={submitData}
    // disableDates={disableDates}

    />
  )
}
export default MaternityFormController