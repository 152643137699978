import { Box, CircularProgress, Paper, TablePagination, Typography } from "@mui/material";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import DataTable from "../../components/tables/DataTable";
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";

const PmsPdfUi = ({
  loading,
  columns,
  filters,
  setFilters,
  currentForms,
  handleSearchChange,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <Box mt={3} mb={3}>
      <Paper elevation={2} sx={{ width: "100%" }} p={4} component={Box}>
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              color: "#0D0D0D",
            }}
          >
            Monthly Evaluation Pdf
          </Typography>
          <StyledSearchBar
            placeholder="Search by User Name"
            value={filters.search}
            onChange={handleSearchChange}
            sx={{ width: "300px", marginRight: "16px" }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <CustomMonthPicker
            date={filters.date}
            setDate={(date) => setFilters((prev) => ({ ...prev, date }))}
          />
        </Box>
        <Box sx={{ minHeight: "300px" }} mt={4}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <DataTable
              columns={columns}
              rows={currentForms}
              count={filters.total}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default PmsPdfUi;