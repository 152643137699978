import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  getDailyUpdateByUser,
  getDailyUpdateDashboard,
  updateDailyUpdateStatus,
} from "../../apis/dailyupdate.api";
import { EmployeeTaskUserContext } from "./EmployeeMain";
import EmployeeDailyUpdateUi from "./EmployeeDailyUpdateUi";
import { useDispatch, useSelector } from "react-redux";
import { DAILYUPDATE_STATUS } from "../../utils/leave.constants";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { getUserByParentId } from "../../apis/user.api";

const EmployeeDailyUpdateController = () => {
  const [date, setDate] = useState(moment());
  const [departments, setDepartments] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [groupedEmployeeData, setGroupedEmployeeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const selectedUser = useContext(EmployeeTaskUserContext);
  const dispatch = useDispatch();
  const [userClick, setUserClick] = useState([]);

  const [childUsers, setChildUsers] = useState([]);
  const [userCard, setUserCard] = useState({});
  const [selectedParentUser, setSelectedParentUser] = useState(null);
  const { user } = useSelector((state) => state);

  const onApprove = (data) => {
    dispatch(
      callApiAction(
        async () =>
          await updateDailyUpdateStatus({
            ...data,
            status: DAILYUPDATE_STATUS.APPROVED,
            date,
          }),
        (response) => {
          fetchTasks();
          dispatch(callSnackBar("Daily Update Approved", "success"));
        },
        (err) => {
          dispatch(callSnackBar(err, "error"));
        }
      )
    );
  };

  const onReject = (data, userId, mainindex, childindex) => {
    console.log("this is onReject", data);
    dispatch(
      callApiAction(
        async () =>
          await updateDailyUpdateStatus({
            ...data,
          }),
        (response) => {
          fetchTasks();
          fetchDailyUpdateByUserId(userId, mainindex, childindex);
          dispatch(callSnackBar("Daily Update Rejected", "success"));
        },
        (err) => {
          dispatch(callSnackBar(err, "error"));
        }
      )
    );
  };

  const fetchDataForSelectedUser = async (userId) => {
    setLoading(true);
    try {
      // Fetch tasks for the selected user using getDailyUpdateByUser API
      const response = await getDailyUpdateByUser({
        user_id: userId,
        date: date.format("YYYY-MM-DD"),
      });

      // Group tasks by department
      const tasksByDepartment = response.data.reduce((acc, task) => {
        const departmentName = task.department
          ? task.department.name
          : "Unnamed Department";
        const departmentId = task.department?._id || null;

        if (!acc[departmentId]) {
          acc[departmentId] = {
            departmentName,
            departmentId,
            tasks: [],
          };
        }

        acc[departmentId].tasks.push(task);
        return acc;
      }, {});

      // Convert to an array of department-based entries
      const userEntries = Object.values(tasksByDepartment).map((dept) => ({
        _id: userId,
        tasks: dept.tasks,
        departmentName: dept.departmentName,
        departmentId: dept.departmentId,
      }));

      // Update state with the selected user's task data grouped by department
      setAllUsers((prevUsers) => {
        // Find the existing user if already present
        const existingUserIndex = prevUsers.findIndex(
          (user) => user.parentUserId === userId
        );

        // Make a copy of previous users
        const updatedUsers = [...prevUsers];

        if (existingUserIndex !== -1) {
          // Update existing user's data
          updatedUsers[existingUserIndex] = {
            ...updatedUsers[existingUserIndex],
            childUsers: userEntries,
          };
        } else {
          // Append new user data
          updatedUsers.push({
            parentUserId: userId,
            parentUserName: selectedUser?.name || "User",
            childUsers: userEntries,
          });
        }

        return updatedUsers;
      });
    } catch (error) {
      console.error("Error fetching tasks for selected user:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchInitialUsersWithDashboard = async (userId, userName) => {
    setLoading(true);
    try {
      // Fetch the child users of the selected user (from ParentId API)
      const childUsers = await fetchUserByParentId(userId);

      // Fetch daily updates for the initial users using getDailyUpdateDashboard API
      const usersWithTasks = await Promise.all(
        childUsers.map(async (user) => {
          const response = await getDailyUpdateDashboard({
            user_id: user._id,
            date: date.format("YYYY-MM-DD"),
          });

          // Group tasks by department
          const tasksByDepartment = response.data.reduce((acc, task) => {
            const departmentName = task.department
              ? task.department.name
              : "Unnamed Department";
            const departmentId = task.department?._id || null;

            // Key based on departmentId to ensure unique department-based grouping
            const key = departmentId || departmentName;

            if (!acc[key]) {
              acc[key] = {
                departmentName,
                departmentId,
                tasks: [],
              };
            }
            acc[key].tasks.push(task);

            return acc;
          }, {});

          // Prepare the user entries, one for each department
          const userEntries = Object.values(tasksByDepartment).map((dept) => ({
            ...user, // Base user card data from Parent ID API
            tasks: dept.tasks, // Assign the tasks grouped by department
            departmentName: dept.departmentName, // Use the department name from tasks
            departmentId: dept.departmentId,
          }));

          // Handle case where the user has no tasks submitted
          if (userEntries.length === 0) {
            userEntries.push({
              ...user,
              tasks: [], // No tasks for this user
              departmentName: user.department?.name || "No Department", // Fallback department name
              departmentId: user.department?._id || null,
            });
          }

          return userEntries;
        })
      );
      // Flatten the array of users with tasks grouped by department
      const flattenedUsersWithTasks = usersWithTasks.flat();

      // Update state with the flattened list of users and their tasks grouped by department
      setAllUsers((prevUsers) => [
        ...prevUsers.filter((user) => user.parentUserId !== userId),
        {
          parentUserId: userId,
          parentUserName: userName,
          childUsers: flattenedUsersWithTasks,
        },
      ]);
    } catch (error) {
      console.error("Error fetching initial users and tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchChildUsersOnClick = async (userId, userName, index) => {
  //   setLoading(true);
  //   try {
  //     // Fetch child users using getUserByParentId
  //     const childUsers = await fetchUserByParentId(userId);

  //     if (childUsers.length == 0) {
  //       dispatch(callSnackBar("No User Found", "success"));
  //     }

  //     // Fetch the daily updates using getDailyUpdateByUser for the clicked user's child users
  //     const usersWithTasks = await Promise.all(
  //       childUsers.map(async (user) => {
  //         const response = await getDailyUpdateByUser({
  //           user_id: user._id,
  //           date: date.format("YYYY-MM-DD"),
  //         });

  //         const tasksWithDepartments = response.data.map((task) => ({
  //           ...task,
  //           departmentName: task.department
  //             ? task.department.name
  //             : "Unnamed Department",
  //         }));

  //         let departmentName = user.department
  //           ? user.department.name
  //           : "Unnamed Department";
  //         if (
  //           tasksWithDepartments.length > 0 &&
  //           tasksWithDepartments[0].departmentName
  //         ) {
  //           departmentName = tasksWithDepartments[0].departmentName;
  //         }

  //         return {
  //           ...user,
  //           tasks: tasksWithDepartments,
  //           departmentName,
  //           departmentId: user.department ? user.department._id : null,
  //         };
  //       })
  //     );

  //     // Update the state to reflect the new child users with their tasks
  //     if (index >= 0) {
  //       setAllUsers((prev) => {
  //         const arr = [...prev];
  //         arr[index] = {
  //           parentUserId: userId,
  //           parentUserName: userName,
  //           childUsers: usersWithTasks,
  //         };

  //         return arr;
  //       });
  //     } else {
  //       setAllUsers((prev) => [
  //         ...prev,
  //         {
  //           parentUserId: userId,
  //           parentUserName: userName,
  //           childUsers: usersWithTasks,
  //         },
  //       ]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching child users and tasks on click:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchDailyUpdateByUserId = async (userId, mainindex, childindex) => {
    setLoading(true);
    try {
      const response = await getDailyUpdateByUser({
        user_id: userId,
        date: date.format("YYYY-MM-DD"),
      });

      // Group tasks by department
      const tasksByDepartment = response.data.reduce((acc, task) => {
        const departmentName = task.department
          ? task.department.name
          : "Unnamed Department";
        const departmentId = task.department?._id || null;

        if (!acc[departmentId]) {
          acc[departmentId] = {
            departmentName,
            departmentId,
            tasks: [],
          };
        }

        acc[departmentId].tasks.push(task);
        return acc;
      }, {});

      // Create user entries grouped by department
      const userEntries = Object.values(tasksByDepartment).map((dept) => ({
        ...user,
        tasks: dept.tasks,
        departmentName: dept.departmentName,
        departmentId: dept.departmentId,
      }));

      // If the user has no tasks, create an entry with empty tasks
      if (userEntries.length === 0) {
        userEntries.push({
          ...user,
          tasks: [], // No tasks for this user
          departmentName: user.department?.name || "No Department",
          departmentId: user.department?._id || null,
        });
      }

      // Flatten the result to have a separate card for each department (or empty task cards)
      // const flattenedUsersWithTasks = userEntries.flat();

      // Update state with the new child users and tasks grouped by department
      setAllUsers((prev) => {
        const updatedUsers = [...prev];
        console.log("this is updatedUsers",updatedUsers,userEntries);
        updatedUsers[mainindex].childUsers[childindex] =
        userEntries;
        return updatedUsers;
      });
    } catch (error) {
      console.error("Error fetching child users and tasks on click:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchChildUsersOnClick = async (userId, userName, index) => {
    setLoading(true);
    try {
      // Fetch child users using getUserByParentId
      const childUsers = await fetchUserByParentId(userId);

      if (childUsers.length === 0) {
        dispatch(callSnackBar("No User Found", "error"));
        return;
      }

      // Fetch the daily updates using getDailyUpdateByUser for the clicked user's child users
      const usersWithTasks = await Promise.all(
        childUsers?.map(async (user) => {
          const response = await getDailyUpdateByUser({
            user_id: user._id,
            date: date.format("YYYY-MM-DD"),
          });

          // Group tasks by department
          const tasksByDepartment = response.data.reduce((acc, task) => {
            const departmentName = task.department
              ? task.department.name
              : "Unnamed Department";
            const departmentId = task.department?._id || null;

            if (!acc[departmentId]) {
              acc[departmentId] = {
                departmentName,
                departmentId,
                tasks: [],
              };
            }

            acc[departmentId].tasks.push(task);
            return acc;
          }, {});

          // Create user entries grouped by department
          const userEntries = Object.values(tasksByDepartment).map((dept) => ({
            ...user,
            tasks: dept.tasks,
            departmentName: dept.departmentName,
            departmentId: dept.departmentId,
          }));

          // If the user has no tasks, create an entry with empty tasks
          if (userEntries.length === 0) {
            userEntries.push({
              ...user,
              tasks: [], // No tasks for this user
              departmentName: user.department?.name || "No Department",
              departmentId: user.department?._id || null,
            });
          }

          return userEntries;
        })
      );

      // Flatten the result to have a separate card for each department (or empty task cards)
      const flattenedUsersWithTasks = usersWithTasks.flat();

      // Update state with the new child users and tasks grouped by department
      setAllUsers((prev) => {
        const updatedUsers = [...prev];
        if (index !== undefined) {
          updatedUsers[index] = {
            parentUserId: userId,
            parentUserName: userName,
            childUsers: flattenedUsersWithTasks,
          };
        } else {
          const exists_index = updatedUsers.findIndex(
            (obj) => obj.parentUserId === userId
          );

          console.log("this is index", exists_index);

          if (exists_index == -1) {
            updatedUsers.push({
              parentUserId: userId,
              parentUserName: userName,
              childUsers: flattenedUsersWithTasks,
            });
          } else {
            updatedUsers[exists_index] = {
              parentUserId: userId,
              parentUserName: userName,
              childUsers: flattenedUsersWithTasks,
            };
          }
        }
        return updatedUsers;
      });
    } catch (error) {
      console.error("Error fetching child users and tasks on click:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAllUsers([]);
    if (selectedUser) {
      // Fetch data for the selected user from context
      fetchDataForSelectedUser(selectedUser._id);
    } else if (user?.data?._id) {
      // Fetch initial data when the page loads or date changes
      fetchChildUsersOnClick(user.data._id, user.data.name);
    }
  }, [date, user?.data?._id, selectedUser]);

  const fetchTasks = async () => {
    if (!selectedUser) return;
    setLoading(true);
    try {
      const response = await getDailyUpdateByUser({
        user_id: selectedUser._id,
        date: date.format("YYYY-MM-DD"),
      });

      if (response && response.status !== 0) {
        setEmployeeData(Array.isArray(response.data) ? response.data : []);
      } else {
        setEmployeeData([]);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setEmployeeData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [selectedUser, date]);

  const fetchUserByParentId = async (parentId) => {
    try {
      const response = await getUserByParentId({ parent_id: parentId });
      return response.data;
    } catch (error) {
      console.error("Error fetching users by parent ID:", error);
      return [];
    }
  };

  return (
    <EmployeeDailyUpdateUi
      date={date}
      setDate={setDate}
      departments={departments}
      selectedDepartment={null}
      setSelectedDepartment={() => {}}
      groupedEmployeeData={groupedEmployeeData}
      loading={loading}
      onApprove={onApprove}
      onReject={onReject}
      //handleUserCardClick={handleUserCardClick}
      fetchChildUsersOnClick={fetchChildUsersOnClick}
      allUsers={allUsers}
      setAllUsers={setAllUsers}
    />
  );
};

export default EmployeeDailyUpdateController;
