import { Box, FormControlLabel, Radio, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import React, { useState } from 'react'
import CustomInput from '../../components/inputs/CustomInput'
import moment from 'moment'
import { useSelector } from 'react-redux'
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FileInput from '../../components/inputs/FileInput'
import SubmitButton from '../../components/button/SubmitButton'
import { ADOPTION_RESPONSE, EXPECTED_CHILD } from '../../utils/constants'


const MaternityApplicationFormUi = ({ fields, setFields, loading, onSubmit, id }) => {
    const { holiday } = useSelector((state) => state)

    return (
        <>
            {
                fields.err && <Typography color="error" variant="h6">
                    {fields.err}
                </Typography>
            }
            <Box>

                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} >
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Box width={"55%"} height={"3vh"}>
                            <Typography variant='h4'>
                                Expected Date Of Delivery :
                            </Typography>
                        </Box>

                        <Box mt={1} sx={{ display: 'flex', width: '88%', }}>
                            <DesktopDatePicker
                                autoFocus={true}
                                label={"Expected Date Of Delivery*"}
                                renderInput={(props) => {
                                    return <CustomInput {...props} sx={{ height: '100%' }} />
                                }}
                                // shouldDisableDate={disableDates}
                                inputFormat="DD-MM-yyyy"
                                disabled={
                                    loading ||
                                    !(holiday.data && Array.isArray(holiday.data)) ||
                                    holiday.loading
                                }
                                value={moment(fields.expectedDateOfDelivery.date)}
                                onChange={(e) =>
                                    setFields({
                                        ...fields,
                                        err: '',
                                        expectedDateOfDelivery: { ...fields.expectedDateOfDelivery, date: e.toISOString() },
                                    })
                                }

                                type="date"
                            />
                        </Box>

                        <Box mt={1} ml={1} sx={{ display: 'flex', width: '90%', flexDirection: "column" }}>
                            <CustomInput
                                // disabled={loading}
                                // value={fields.name}
                                // onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                                value={fields.expectedDateOfDelivery.remarks}
                                onChange={(e) =>
                                    setFields({
                                        ...fields,
                                        err: '',
                                        expectedDateOfDelivery: {
                                            ...fields.expectedDateOfDelivery,
                                            remarks: e.target.value
                                        }
                                    })

                                }
                                type="text"
                                label={"Details Regarding Date Of Delivery*"}
                            />
                        </Box>
                    </Box>

                </Box>
                <Box display={"flex"} flexDirection={"column"} >
                    <Box width={"100%"}  >
                        <FileInput
                            multi
                            value={fields.expectedDateOfDelivery.attechment}
                            onChange={(file, fileArr) => {
                                setFields({
                                    ...fields,err:'',
                                    expectedDateOfDelivery: {
                                        ...fields.expectedDateOfDelivery,
                                        attechment: [fileArr]
                                    }
                                })
                            }}
                            defaults={fields.url ?? []}
                            accept=""
                            title="Upload Documents Regarding Date Of Delivery"
                            subTitle="Only documents less than 1 MB are allowed"
                        />
                    </Box>
                </Box>
                <Box mt={3} sx={{ display: 'flex', width: '50%', flexDirection: "column" }}>
                    <Box>
                        <Typography variant='h4'>
                            Date From Which Leave Is To Be Availed :
                        </Typography>
                    </Box>

                    <Box mt={1} sx={{ display: 'flex', width: '98%', }}>
                        <DesktopDatePicker
                            autoFocus={true}
                            label={"Date From Which Leave Is To Be Availed*"}
                            renderInput={(props) => {
                                return <CustomInput {...props} sx={{ height: '100%' }} />
                            }}
                            // shouldDisableDate={disableDates}
                            inputFormat="DD-MM-yyyy"
                            disabled={
                                loading ||
                                !(holiday.data && Array.isArray(holiday.data)) ||
                                holiday.loading
                            }
                            value={fields.dateFromLeave}
                            onChange={(e) =>
                                setFields({
                                    ...fields,
                                    err: '',
                                    dateFromLeave: e.toISOString(),  // Update the correct field
                                })
                            }

                            type="date"
                        />
                        {console.log("object expected date ", fields.dateFromLeave)}
                    </Box>

                </Box>

                <Box>
                    <Box mt={3}>
                        <Typography variant='h4'>
                            No. Of  Weeks For Which Leave Is To Be Availed :
                        </Typography>
                    </Box>
                    <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Box width={"33%"}>
                            <CustomInput
                                // disabled={loading}
                                value={fields.numberOfWeeks.total}
                                onChange={(e) =>
                                    setFields({
                                        ...fields,
                                        err: '',
                                        numberOfWeeks: {
                                            ...fields.numberOfWeeks,
                                            total: e.target.value
                                        }
                                    })
                                } type="number"
                                label={"Total No. of Weeks*"}
                            />
                        </Box>

                        <Box width={"33%"}>
                            <CustomInput
                                // disabled={loading}
                                value={fields.numberOfWeeks.preDelivery}
                                onChange={(e) =>
                                    setFields({
                                        ...fields,
                                        err: '',
                                        numberOfWeeks: {
                                            ...fields.numberOfWeeks,
                                            preDelivery: e.target.value
                                        }
                                    })
                                }
                                type="number"
                                label={"No. of Weeks Pre-Delivery*"}
                            />
                        </Box>

                        <Box width={"33%"}>
                            <CustomInput
                                // disabled={loading}
                                value={fields.numberOfWeeks.postDelivery}
                                onChange={(e) =>
                                    setFields({
                                        ...fields,
                                        err: '',
                                        numberOfWeeks: {
                                            ...fields.numberOfWeeks,
                                            postDelivery: e.target.value
                                        }
                                    })
                                }
                                type="number"
                                label={"No. of Weeks Post-Delivery*"}
                            />
                        </Box>
                    </Box>


                    <Box mt={3}>
                        <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                            <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "20px", fontWeight: "500" }}>The Expected Child : </FormLabel>
                            <RadioGroup
                                row
                                sx={{ marginLeft: "10px" }}
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                value={fields.expectedChild.first}
                                onChange={(e) =>
                                    setFields({
                                        ...fields,
                                        err: "",
                                        expectedChild: {
                                            ...fields.expectedChild,
                                            first: e.target.value
                                        }
                                    })
                                }
                                name="radio-buttons-group"
                            >
                                {Object.keys(EXPECTED_CHILD).map((item) => (
                                    <FormControlLabel
                                        disabled={loading}
                                        key={item}
                                        value={EXPECTED_CHILD[item]}
                                        control={<Radio />}
                                        label={item}
                                    />
                                ))}

                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>

                <Box mt={3}>
                    <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "20px", fontWeight: "500" }}>Whether Adoption ? : </FormLabel>
                        <RadioGroup
                            row
                            sx={{ marginLeft: "10px" }}
                            value={fields.adoption.isAdopted}
                            onChange={(e) => setFields({
                                ...fields,
                                adoption: {
                                    ...fields.adoption, err: '',
                                    isAdopted: e.target.value
                                },

                            })}
                        >
                            {Object.keys(ADOPTION_RESPONSE).map((item) => (
                                <FormControlLabel
                                    disabled={loading}
                                    key={item}
                                    value={ADOPTION_RESPONSE[item]}
                                    control={<Radio />}
                                    label={item}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>

                {fields.adoption.isAdopted == "true" &&
                    <Box>
                        <Box>
                            <Box width={"100%"}>
                                <CustomInput
                                    // disabled={loading}
                                    value={fields.adoption.remarks}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            err: '',
                                            adoption: {
                                                ...fields.adoption,
                                                remarks: e.target.value
                                            }
                                        })
                                    }
                                    type="text"
                                    label={"Adoption Related Details*"}
                                />
                            </Box>
                            <Box ml={1} width={"100%"} >
                                <FileInput
                                    multi
                                    value={fields.adoption.attechment}
                                    onChange={(file, fileArr) => {
                                        setFields({
                                            ...fields,
                                            adoption: {
                                                ...fields.adoption,
                                                attechment: [fileArr]
                                            }
                                        })
                                    }}
                                    defaults={fields.adoption.attechment ?? []}
                                    accept=""
                                    title="Upload Adoption Related Documents"
                                    subTitle="Only documents are allowed! less than 1 MB"
                                />
                            </Box>
                        </Box>
                    </Box>}

                <Box mt={3}>
                    <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "20px", fontWeight: "500" }}>Any Modifications Or Facility Needed While Pregnancy ? : </FormLabel>
                        <RadioGroup
                            row
                            sx={{ marginLeft: "10px" }}
                            value={fields.modificationsNeeded.isNeeded}
                            onChange={(e) => setFields({
                                ...fields,
                                modificationsNeeded: {
                                    ...fields.modificationsNeeded, err: '',
                                    isNeeded: e.target.value
                                },

                            })}
                        >
                            {Object.keys(ADOPTION_RESPONSE).map((item) => (
                                <FormControlLabel
                                    disabled={loading}
                                    key={item}
                                    value={ADOPTION_RESPONSE[item]}
                                    control={<Radio />}
                                    label={item}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>

                {fields.modificationsNeeded.isNeeded == "true" &&
                    <Box>
                        <Box>
                            <Box width={"100%"}>
                                <CustomInput
                                    // disabled={loading}
                                    value={fields.modificationsNeeded.remarks}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            err: '',
                                            modificationsNeeded: {
                                                ...fields.modificationsNeeded,
                                                remarks: e.target.value
                                            }
                                        })
                                    }
                                    type="text"
                                    label={"Details*"}
                                />
                            </Box>
                            <Box ml={1} width={"100%"} >
                                <FileInput
                                    multi
                                    value={fields.modificationsNeeded.attechment}
                                    onChange={(file, fileArr) => {
                                        setFields({
                                            ...fields,
                                            modificationsNeeded: {
                                                ...fields.modificationsNeeded,
                                                attechment: [fileArr]
                                            }
                                        })
                                    }}
                                    defaults={fields.modificationsNeeded.attechment ?? []}
                                    accept=""
                                    title="Upload Documents"
                                    subTitle="Only documents are allowed! less than 1 MB"
                                />
                            </Box>
                        </Box>
                    </Box>}

                <Box mt={3}>
                    <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "20px", fontWeight: "500" }}>Any Counselling Needed While Pregnancy ? : </FormLabel>
                        <RadioGroup
                            row
                            sx={{ marginLeft: "10px" }}
                            value={fields.counselingNeeded.isNeeded}
                            onChange={(e) => setFields({
                                ...fields,
                                counselingNeeded: {
                                    ...fields.counselingNeeded, err: '',
                                    isNeeded: e.target.value
                                },

                            })}
                        >
                            {Object.keys(ADOPTION_RESPONSE).map((item) => (
                                <FormControlLabel
                                    disabled={loading}
                                    key={item}
                                    value={ADOPTION_RESPONSE[item]}
                                    control={<Radio />}
                                    label={item}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>

                {fields.counselingNeeded.isNeeded == "true" &&
                    <Box>
                        <Box>
                            <Box width={"100%"}>
                                <CustomInput
                                    // disabled={loading}
                                    value={fields.counselingNeeded.remarks}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            err: '',
                                            counselingNeeded: {
                                                ...fields.counselingNeeded,
                                                remarks: e.target.value
                                            }
                                        })
                                    }
                                    type="text"
                                    label={"Counseling Related Details*"}
                                />
                            </Box>
                            <Box ml={1} width={"100%"} >
                                <FileInput
                                    multi
                                    value={fields.counselingNeeded.attechment}
                                    onChange={(file, fileArr) => {
                                        setFields({
                                            ...fields,
                                            counselingNeeded: {
                                                ...fields.counselingNeeded,
                                                attechment: [fileArr]
                                            }
                                        })
                                    }}
                                    defaults={fields.counselingNeeded.attechment ?? []}
                                    accept=""
                                    title="Upload Counseling Related Documents"
                                    subTitle="Only documents are allowed! less than 1 MB"
                                />
                            </Box>
                        </Box>
                    </Box>}

                <Box mt={3}>
                    <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "20px", fontWeight: "500" }}>Medical Documents Submitted ? : </FormLabel>
                        <RadioGroup
                            row
                            sx={{ marginLeft: "10px" }}
                            value={fields.medicalDocumentsSubmitted.isSubmitted}
                            onChange={(e) => setFields({
                                ...fields,
                                medicalDocumentsSubmitted: {
                                    ...fields.medicalDocumentsSubmitted, err: '',
                                    isSubmitted: e.target.value
                                },

                            })}
                        >
                            {Object.keys(ADOPTION_RESPONSE).map((item) => (
                                <FormControlLabel
                                    disabled={loading}
                                    key={item}
                                    value={ADOPTION_RESPONSE[item]}
                                    control={<Radio />}
                                    label={item}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>

                {fields.medicalDocumentsSubmitted.isSubmitted == "true" &&
                    <Box>
                        <Box>
                            <Box width={"100%"}>
                                <CustomInput
                                    // disabled={loading}
                                    value={fields.medicalDocumentsSubmitted.remarks}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            err: '',
                                            medicalDocumentsSubmitted: {
                                                ...fields.medicalDocumentsSubmitted,
                                                remarks: e.target.value
                                            }
                                        })
                                    }
                                    type="text"
                                    label={"Medical Documents Related Details*"}
                                />
                            </Box>
                            <Box ml={1} width={"100%"} >
                                <FileInput
                                    multi
                                    value={fields.medicalDocumentsSubmitted.attechment}
                                    onChange={(file, fileArr) => {
                                        setFields({
                                            ...fields,
                                            medicalDocumentsSubmitted: {
                                                ...fields.medicalDocumentsSubmitted,
                                                attechment: [fileArr]
                                            }
                                        })
                                    }}
                                    defaults={fields.medicalDocumentsSubmitted.attechment ?? []}
                                    accept=""
                                    title="Upload Documents"
                                    subTitle="Only documents are allowed! less than 1 MB"
                                />
                            </Box>
                        </Box>
                    </Box>}

                {fields.medicalDocumentsSubmitted.isSubmitted == "false" &&
                    <Box width={"100%"}>
                        <CustomInput
                            // disabled={loading}
                            value={fields.medicalDocumentsSubmitted.remarks}
                            onChange={(e) =>
                                setFields({
                                    ...fields,
                                    err: '',
                                    medicalDocumentsSubmitted: {
                                        ...fields.medicalDocumentsSubmitted,
                                        remarks: e.target.value
                                    }
                                })
                            }
                            type="text"
                            label={"Reason*"}
                        />
                    </Box>
                }

                {!id && <Box mt={2}>
                    <SubmitButton
                        title={"Submit"}
                        onClick={onSubmit}
                    />
                </Box>}

            </Box >
        </>
    )
}

export default MaternityApplicationFormUi