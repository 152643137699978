
import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { NotificationsNone } from "@mui/icons-material";
import { center } from "../../assets/css/theme/common";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationAction,
  readIndividualNotificationAction,
  readNotificationAction,
} from "../../store/actions/notificationAction";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";


// function NotificationModel() {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const dispatch = useDispatch();
//   const notifications = useSelector((state) => state.notifications || { new: 0, data: [] });

//   const handleClick = (event) => {
//     dispatch(readNotificationAction());
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "simple-popover" : undefined;

//   React.useEffect(() => {
//     dispatch(fetchNotificationAction());

//     let interval = setInterval(() => {
//       dispatch(fetchNotificationAction());
//     }, 1000 * 60 * 5);

//     return () => {
//       clearInterval(interval);
//     };
//   }, [dispatch]);

//   return (
//     <>
//       <IconButton
//         aria-describedby={id}
//         sx={{ mr: 1, ml: 1 }}
//         onClick={handleClick}
//       >
//         {notifications.new && notifications.new !== 0 ? (
//           <Badge
//             color="error"
//             overlap="circular"
//             badgeContent={notifications.new}
//             variant="dot"
//           >
//             <NotificationsNone />
//           </Badge>
//         ) : (
//           <NotificationsNone />
//         )}
//       </IconButton>
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "right",
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "right",
//         }}
//       >
//         <List
//           sx={{ width: "380px", maxWidth: "100%", bgcolor: "background.paper" }}
//           component="nav"
//           aria-labelledby="nested-list-subheader"
//           subheader={
//             <ListSubheader
//               component="div"
//               variant="subtitle1"
//               sx={(theme) => ({ color: theme.palette.text.primary })}
//               id="nested-list-subheader"
//             >
//               Notifications
//             </ListSubheader>
//           }
//         >
//           {notifications.data &&
//             Array.isArray(notifications.data) &&
//             notifications.data.map((notification) => (
//               <Link
//                 key={notification._id}
//                 to={notification.redirect ? notification.redirect : ""}
//                 onClick={handleClose}
//                 style={{ textDecoration: 'none' }}
//               >
//                 <ListItemButton
//                   disableTouchRipple
//                   sx={{ borderTop: "1px solid lightgray" }}
//                 >
//                   <Avatar
//                     sx={{ mr: 3 }}
//                     src={notification.senderId?.profile_url}
//                   >
//                     {notification.senderId?.name?.[0]}
//                   </Avatar>
//                   <Box
//                     sx={{
//                       flexDirection: "column",
//                       display: "flex",
//                       alignItems: "flex-start",
//                     }}
//                   >
//                     <Typography
//                       variant="subtitle1"
//                       color={"text.primary"}
//                       sx={{ lineHeight: "100%" }}
//                     >
//                       {notification.senderId?.name}
//                     </Typography>
//                     <Typography
//                       variant="subtitle2"
//                       color={"text.secondary"}
//                       sx={{ mt: 0 }}
//                     >
//                       {moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}
//                     </Typography>
//                     <Typography
//                       variant="subtitle2"
//                       color={"text.secondary"}
//                       sx={{ mt: 0 }}
//                     >
//                       {notification.message}
//                     </Typography>
//                   </Box>
//                 </ListItemButton>
//               </Link>
//             ))}
//         </List>
//       </Popover>
//     </>
//   );
// }

// export default React.memo(NotificationModel);

function NotificationModel() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications || { new: 0, data: [] });
  const navigate = useNavigate();

  const handleClick = (event) => {
    dispatch(readNotificationAction());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    dispatch(readIndividualNotificationAction(notification._id)).then(() => {
      if (notification.redirect) {
        navigate(notification.redirect);
      }
    });
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    dispatch(fetchNotificationAction());

    let interval = setInterval(() => {
      dispatch(fetchNotificationAction());
    }, 1000 * 60 * 5);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <>
      <IconButton
        aria-describedby={id}
        sx={{ mr: 1, ml: 1 }}
        onClick={handleClick}
      >
        {notifications.new > 0 ? (
          <Badge
            color="error"
            badgeContent={notifications.new}
          >
            <NotificationsNone />
          </Badge>
        ) : (
          <NotificationsNone />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List
          sx={{ width: "380px", maxWidth: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              variant="subtitle1"
              sx={(theme) => ({ color: theme.palette.text.primary })}
              id="nested-list-subheader"
            >
              Notifications
            </ListSubheader>
          }
        >
          {notifications.data &&
            Array.isArray(notifications.data) &&
            notifications.data.map((notification) => (
              <ListItemButton
                key={notification._id}
                disableTouchRipple
                sx={{ borderTop: "1px solid lightgray" }}
                onClick={() => handleNotificationClick(notification)}
              >
                <Avatar
                  sx={{ mr: 3 }}
                  src={notification.senderId?.profile_url}
                >
                  {notification.senderId?.name?.[0]}
                </Avatar>
                <Box
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color={"text.primary"}
                    sx={{ lineHeight: "100%" }}
                  >
                    {notification.senderId?.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={"text.secondary"}
                    sx={{ mt: 0 }}
                  >
                    {moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={"text.secondary"}
                    sx={{ mt: 0 }}
                  >
                    {notification.message}
                  </Typography>
                </Box>
              </ListItemButton>
            ))}
        </List>
      </Popover>
    </>
  );
}

export default React.memo(NotificationModel);