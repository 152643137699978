import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { findObjectKeyByValue, unEscapeStr } from "../../utils/helper"
import moment from "moment"
import WFHStatusComponent from "./WFHStatusComponent"
import { HALF_TYPE_OBJ, REQUESTS_STATUS, USER_ROLES } from "../../utils/constants"
import SubmitButton from "../../components/button/SubmitButton"
import { useSelector } from "react-redux"


const WFHDetailsUi = ({ onDelete, fieldLoading,loading, list ,setList,callBack}) => {
    const { user } = useSelector(state => state)
    return <Box>
        {loading && <CenteredBox mt={4} mb={4}><CircularProgress /></CenteredBox>}
        {
            !loading && list && list['_id'] && <Box>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6} >
                        <Box mb={4} >

                            <Typography fontWeight="Bold" variant="body1">Applied By:</Typography>
                            <Typography variant="body2" mt={1}>{list.user_id?.first_name + " " + list.user_id?.last_name}</Typography>

                            <Typography fontWeight="Bold" variant="body1">Date:</Typography>
                            <Typography variant="body2" mt={1}>{moment(list.date).format('DD/MM/YYYY')} </Typography>

                            <Typography fontWeight="Bold" variant="body1">Duration:</Typography>
                            <Typography variant="body2" mt={1}>{moment(list.start_time).format("hh:mm A")} - {moment(list.end_time).format("hh:mm A")}</Typography>

                            <Typography fontWeight="Bold" variant="body1">Reason:</Typography>
                            <Typography variant="body2" mt={1}>{list.reason ?? "NA"}</Typography>




                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Typography fontWeight="Bold" variant="body1">Status:</Typography>
                        <Typography variant="body2" mt={1}>{<WFHStatusComponent params={list} />}</Typography>
                        {list.action_by && <>


                            <Typography fontWeight="Bold" variant="body1">Action By:</Typography>
                            <Typography variant="body2" mt={1}>{list.action_by?.first_name + " " + list.action_by?.last_name}</Typography>
                        </>
                        }

                        {list.action_by && <> <Typography fontWeight="Bold" variant="body1">Action Reason:</Typography>
                            <Typography variant="body2" mt={1}>{list.action_by?.action_reason}</Typography>
                        </>
                        }
                        {list.status == REQUESTS_STATUS.PENDING && <>
                            


                            <Typography fontWeight="Bold" variant="body1">Action:</Typography>
                            <Typography variant="body2" mt={1}><WFHStatusComponent editable params={list} setParams={setList} callBack={callBack} /> </Typography>
                        </>
                        }


                    </Grid>
                </Grid>
                {( moment().valueOf() < moment(list.end_time).valueOf() || user.data.role === USER_ROLES.admin || user.data.role === USER_ROLES.hr) && (
                        <SubmitButton
                            sx={{ mt: 3, mb: 0 }}
                            loading={fieldLoading.fieldName === 'delete'}
                            onClick={onDelete}
                            disabled={fieldLoading.loading}
                            title={'Delete WFH'}
                            color="error"
                        />
                    )}



            </Box>
        }

        {!loading && (!list || Object.keys(list).length == 0) && <CenteredBox mt={4} mb={4}><Typography variant="h3" >No Details Found</Typography></CenteredBox>}
    </Box>
}
export default WFHDetailsUi