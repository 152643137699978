import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getReimberesementByIdApi, updateReimberesementField } from "../../apis/reimbersement.api"
import { getUserByIdApi, updateUserField } from "../../apis/user.api"
import { callApiAction } from "../../store/actions/commonAction"
import ReimberesementDetailsPageUi from "./ReimberesementDetailsPageUi"
import ReimburesmentDetailsDialog from "./ReimburesmentDetailsDialog"



//id props will be passed when it will be opened inside modal in user View module

const ReimberesementDetailsController = ({ reimbursementId }) => {


    const [id, setId] = useState(reimbursementId)

    const dispatch = useDispatch()
    const params = useParams()
    const [state, setState] = useState(null)
    const [loading, setLoading] = useState(false)
    const onUpdateValue = () => {

    }

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getReimberesementByIdApi(id),
            (response) => {
                setState(response)
              
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

        


    const [fieldLoading, setFieldLoading] = useState({
        fieldName: "",
        loading: false
    })

    const onUpdateField = (fieldName, value) => {
        setState({ ...state, [fieldName]: value })
        setFieldLoading({
            fieldName,
            loading: true
        })
        let changedVal = value
        if (value && typeof value === "object") {
            changedVal = value['_id']
        }
        dispatch(callApiAction(
            async () => await updateReimberesementField({ id, [fieldName]: changedVal }),
            (response) => {
                console.log(response,"response")
                setFieldLoading({
                    fieldName: "",
                    loading: false
                })

            },
            (err) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                })
            }
        ))

    }

    useEffect(() => {
        if (reimbursementId) {
            fetchList()
        } else {
            setId(params.id)
        }
    }, [params.id])

    useEffect(() => {
        if (id && id == params.id) {
            fetchList()
        }
    }, [id])

    if(reimbursementId){
        return <ReimburesmentDetailsDialog setState={setState} loading={loading} state={state} onUpdateField={onUpdateField} fieldLoading={fieldLoading} isFromModal={id} onUpdateValue={onUpdateValue} />
    }


    return <ReimberesementDetailsPageUi setState={setState} loading={loading} state={state} onUpdateField={onUpdateField} fieldLoading={fieldLoading} isFromModal={false} onUpdateValue={onUpdateValue} />
}
export default ReimberesementDetailsController