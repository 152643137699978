import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { useParams } from "react-router-dom"

import PendingLeaveUi from "./PendingLeaveUi"
import {  getPendingLeavesApi } from "../../../apis/leave.api"
import moment from "moment"
import { Chip, Typography } from "@mui/material"
import {  LEAVE_TYPE } from "../../../utils/leave.constants"
import { findObjectKeyByValue } from "../../../utils/helper"
import LeaveViewButton from "./LeaveViewButton"
const PendingLeavesController = ({ userId, pendingAndGenral }) => {

    const dispatch = useDispatch()
    const params = useParams()

    const [list, setList] = useState([])
    const [listLoading, setListLoading] = useState(false)




    const columns = useMemo(() => [
        { id: 1, fieldName: 'createdAt', label: 'Application Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.createdAt).format("DD/MM/YYYY") },
        { id: 2, fieldName: 'type', label: 'Type', align: "left",minWidth: '50px', renderValue: (params) => <Typography fontSize="inherit" textTransform="capitalize">{findObjectKeyByValue(params.type, LEAVE_TYPE)}</Typography> },
        { id: 8, fieldName: 'applied_by', label: 'Applied By', align: "left", minWidth: '150px', renderValue: (params) => <Typography fontSize="inherit" textTransform="capitalize">{params.applied_by?.first_name+" "+params.applied_by?.last_name}</Typography> },
        {
            id: 3, fieldName: 'from', label: 'Leave Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => {
                let str = moment(params.from).format("DD/MM/YYYY")
                const to = moment(params.to).format("DD/MM/YYYY")
                if (str != to) {
                    str += (" - " + to)
                }
                return str
            }
        },
        {
            id: 4, fieldName: 'leave_count', label: 'Total Leave', align: "left", minWidth: '150px'
        },
        
        {
            id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <LeaveViewButton id={params._id} />
        },
    ], [dispatch]);
    const [filters, setFilters] = useState({

        pageNo:1,
        pageSize:10,
        date: moment(),
        userId: userId

    })

    const fetchList = () => {
        setListLoading(true)
        const parmasToPass = { ...filters }
        parmasToPass.date = parmasToPass.date.valueOf()
        dispatch(callApiAction(
            async () => await getPendingLeavesApi({ ...parmasToPass }),
            (response) => {
                setList(response)

                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (

        <>
            <PendingLeaveUi
                columns={columns}
                pendingAndGenral={pendingAndGenral}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                callBack={fetchList}
                list={list}

            />

        </>
    )
}
export default PendingLeavesController