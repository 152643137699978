import { memo, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'
import ForwardLeaveUi from './ForwardLeaveUi'
import { addManualLeaveApi, forwadLeaveApi } from '../../../apis/leave.api'
import moment from 'moment'
import { HALF_TYPE_OBJ, SNACK_BAR_VARIETNS } from '../../../utils/constants'

import { callSnackBar } from '../../../store/actions/snackbarAction'
import { LEAVE_TYPE } from '../../../utils/leave.constants'
import AddForwardLeaveUi from './AddForwardLeaveUi'
import { closeModal } from '../../../store/actions/modalAction'

const AddForwardLeaveController = ({ weeklyOf, callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()

  const { holiday, user } = useSelector((state) => state)

  const [loading, setLoading] = useState(false)
  const defaultFields = {
    err: '',

    userId: null,
    type: LEAVE_TYPE.PL,
    count: 0,
    date: moment(),
  }
  const [fields, setFields] = useState(defaultFields)

  const validationSchema = useMemo(
    () => [
      {
        required: true,
        value: fields.userId,
        field: 'Select User'
      },
      {
        required: true,
        value: fields.date,
        field: 'Leave Date ',
        
      },
      {
        required: true,
        value: fields.count,
        field: 'Leave Count'
      },
      {
        required: true,
        value: fields.type,
        field: 'Leave Type',
      }
    ],
    [fields],
  )

 

  const onSubmit = async (e) => {
   
    e.preventDefault()

    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      const leaveData = { ...fields }

      leaveData.date = fields.date.toISOString()

      setLoading(true)
      dispatch(
        callApiAction(
          async () => await forwadLeaveApi(leaveData),
          async (response) => {

            setLoading(false)
            setFields(defaultFields)
            dispatch(closeModal())
            dispatch(callSnackBar('Leave forwarded Successfully',SNACK_BAR_VARIETNS.suceess))
          },
          (err) => {
            setLoading(false)
            setFields({ ...fields, err })
          },
        ),
      )
    } else {
      setFields({ ...fields, err: validationResponse })
    }
  }

  return (
    <AddForwardLeaveUi
      
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  )
}
export default memo(AddForwardLeaveController)
