import { Box, Button, Checkbox, Collapse, Skeleton, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton, {
  ResetButton,
} from "../../../components/button/SubmitButton";
import CustomInput from "../../../components/inputs/CustomInput";
import CustomRadioButtons from "../../../components/layouts/common/CustomRadioButtons";
import { HALF_TYPE, HALF_TYPE_OBJ, typeS } from "../../../utils/constants";

import { useNavigate } from "react-router-dom";
import { fetchLeaveBalanceCountAction } from "../../../store/actions/leaveBalanceAction";
import { useCallback, useEffect } from "react";
import { LEAVE_NAMES, LEAVE_TYPE } from "../../../utils/leave.constants";
import UserSearchBar, {
  StyledSearchBar,
  UserSearchBarNormal,
} from "../../../components/inputs/SearchBar";
import { Add } from "@mui/icons-material";
import DataTable from "../../../components/tables/DataTable";
import { openModal } from "../../../store/actions/modalAction";
import AddManualLeaveRaiseController from "./AddManualLeaveRaiseController";
import CustomMonthPicker from "../../../components/layouts/common/CustomMonthPicker";

const ManualLeaveRaiseUi = ({
  onSubmit,
  loading,
  callBack,
  state,
  setState,
  columns,
  fields,
  setFields,
  // onSubmit,
  disableDates,
}) => {
  const dispatch = useDispatch()
  const onManualClick =useCallback(() =>{
    dispatch(
      openModal({
        title: "Add Manual Leave",
        component: <AddManualLeaveRaiseController callBack={callBack} />,
        size: "lg",
      })
      )
  },[])

  return(
  <>
    {" "}
    <Box m={3}>
      <Box mb={3}>
        <Typography
          mb={2}
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "40px",
            color: "#0D0D0D",
          }}
        >
         Manual Leave 
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2,mb:3 }}>
                    <CustomMonthPicker 
                        date={fields.date} 
                        setDate={(date) => setFields({ ...fields, date })} 
                    />
                </Box>
        <Box
          pl={2}
          mb={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <StyledSearchBar
            placeholder={"Search Name"}
            value={fields.search}
            onChange={(e) => {
              setFields({ ...fields, search: e.target.value });
            }}
          />

          {/* <Box pl={2} sx={{ display: "flex" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e, newVal) => {
                    setFilters({
                      ...filters,
                      role: parseInt(newVal._id),
                    });
                  }}
                  options={[
                    { label: "All", _id: null },
                    ...Object.keys(USER_ROLES).map((key) => ({
                      label: key,
                      _id: USER_ROLES[key],
                    })),
                  ]}
                  sx={{
                    width: 300,
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center" },
                  }}
                  renderInput={(params) => (
                    <StyledSearchBar
                      placeholder="Select Role"
                      {...params}
                    />
                  )}
                />
              </Box> */}
        </Box>
        <Button
          onClick={()=>onManualClick()}
          sx={{
            display: "flex",
            height: "100%",
            zIndex: 0,
            textTransform: "capitalize",
            boxShadow: "none",
            border: "1px solid #393939",
            width: "100%",
            height: "56px",
            ":hover": {
              boxShadow: "none",
            },
          }}
        >
          <Add color="primary" /> &nbsp;Manual Leave
        </Button>
      </Box>

      <Box sx={{ minHeight: "300px" }}>
        <DataTable
          columns={columns}
          rows={state?.result}
          count={state?.total}
          filters={fields}
          setFilters={setFields}
          loading={loading}
        />
      </Box>
    </Box>
  </>
  )
};
export default ManualLeaveRaiseUi;
