import React from "react";
import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";
import { openModal } from "../../store/actions/modalAction";
import { useCallback } from "react";
import UploadPdfController from "./UploadPdfController";
import SalaryStructurePdfListController from "./SalaryStructurePdfListController";

const GenerateSalaryStructurePdf = ({ callBack }) => {
  const [date, setDate] = useState(moment());
  const dispatch = useDispatch();
  const uploadSheet = useCallback(() => {
    dispatch(
      openModal({
        title: "Upload Salary Structure",
        component: <UploadPdfController callBack={callBack} />,
        size: "sm",
      })
    );
  }, []);

  return (
    <>
      <Box m={3}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "40px",
            color: "#0D0D0D",
          }}
        >
          Upload Salary Structure
        </Typography>
        <Box mt={3} mb={3}>
          <CustomMonthPicker date={date} setDate={setDate} />
        </Box>

        <Button
          onClick={uploadSheet}
          sx={{
            display: "flex",
            height: "100%",
            zIndex: 0,
            textTransform: "capitalize",
            boxShadow: "none",
            border: "1px solid #393939",
            width: "100%",
            height: "56px",
            ":hover": {
              boxShadow: "none",
            },
          }}
        >
          <Add color="primary" /> &nbsp;Upload PDF
        </Button>
      </Box>

      <SalaryStructurePdfListController date={date.toISOString()} />
    </>
  );
};

export default GenerateSalaryStructurePdf;
