import {  useMemo, useState } from 'react'
import { useDispatch} from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { createPms, getPmsDelete } from '../../apis/pms.api'
import PmsCreateUi from './PmsCreateUi'



const PmsCreateController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    tasks: Array(5).fill({ monthly_task: '', completion_date: '', status: '', remarks: '' }),
  });

  const validationSchema = useMemo(() => [
    {
      required: true,
      value: formData.tasks[0].monthly_task,
      field: 'Task 1 - monthly_task',
    },
    {
      required: true,
      value: formData.tasks[0].completion_date,
      field: 'Task 1 - completion_date',
    },
    ...formData.tasks.slice(1).flatMap((task, index) => task.monthly_task ? [
      {
        required: true,
        value: task.completion_date,
        field: `Task ${index + 2} - completion_date`,
      }
    ] : []),
  ], [formData]);

  const validate = (schema) => {
    for (let rule of schema) {
      if (rule.required && !rule.value) {
        return `${rule.field} is required`;
      }
    }
    return true;
  };

  const handleChange = (index, name, value) => {
    const tasks = [...formData.tasks];
    tasks[index] = { ...tasks[index], [name]: value };
    setFormData({ ...formData, tasks });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationResponse = validate(validationSchema);
    if (validationResponse === true) {
      const tasksWithFormattedDates = formData.tasks
        .filter(task => task.monthly_task || task.completion_date)  // Filter out empty tasks
        .map(task => ({
          ...task,
          completion_date: task.completion_date ? moment(task.completion_date).format('YYYY-MM-DD') : '',
        }));

      setLoading(true);
      try {
        console.log('Submitting formData:', { tasks: tasksWithFormattedDates });
        const response = await createPms({ tasks: tasksWithFormattedDates });
        if (response.status === 1) {
          dispatch(callSnackBar('PMS created successfully', 'success'));
          navigate('/employee-dashboard');
        } else {
          console.error('Backend Response Error:', response);
          dispatch(callSnackBar('Failed to create PMS', 'error'));
        }
      } catch (error) {
        console.error('Error creating PMS:', error);
        dispatch(callSnackBar('Failed to create PMS', 'error'));
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(callSnackBar(validationResponse, 'error'));
    }
  };

  const addNewField = () => {
    setFormData((prevState) => ({
      ...prevState,
      tasks: [...prevState.tasks, { monthly_task: '', completion_date: '', status: '', remarks: '' }],
    }));
  };

  const deleteField = async (index) => {
    const taskToDelete = formData.tasks[index];
    if (taskToDelete.id) {
      try {
        await getPmsDelete(taskToDelete.id);
        dispatch(callSnackBar('Task deleted successfully', 'success'));
      } catch (error) {
        console.error('Error deleting task:', error);
        dispatch(callSnackBar('Failed to delete task', 'error'));
        return;
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.filter((_, i) => i !== index),
    }));
  };

  return (
    <PmsCreateUi
      formData={formData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      addNewField={addNewField}
      deleteField={deleteField}
      loading={loading}
      readOnlyFields={{ status: true, remarks: true }}
    />
  );
};

export default PmsCreateController;