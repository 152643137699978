import { memo, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'

import { closeModal } from '../../../store/actions/modalAction'

import ManualLeaveRaiseUi from './ManualLeaveRaiseUi'
import { addManualLeaveApi, raiseLeaveApi } from '../../../apis/leave.api'
import moment from 'moment'
import { HALF_TYPE, HALF_TYPE_OBJ, SNACK_BAR_VARIETNS } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { LEAVE_NAMES, LEAVE_TYPE } from '../../../utils/leave.constants'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import AddManualLeaveRaiseUi from './AddManualLeaveRaiseUi'

const AddManualLeaveRaiseController = ({ weeklyOf, callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()

  const { holiday, user } = useSelector((state) => state)

  const [loading, setLoading] = useState(false)
  const defaultFields = {
    err: '',

    userId: null,
    type: LEAVE_TYPE.CL,

    remarks: '',
    date: moment(),
    duration: HALF_TYPE_OBJ.FULL_DAY,
  }
  const [fields, setFields] = useState(defaultFields)

  const validationSchema = useMemo(
    () => [
      {
        required: true,
        value: fields.userId,
        field: 'Select User'
      },
      // {
      //   required:true,
      //   value:fields.type,
      //   fiel
      // },
      {
        required: true,
        value: fields.date,
        field: 'Leave Date ',
        custom: () => {
          return !disableDates(fields.date)
        },
      },
      {
        required: true,
        value: fields.duration,
        field: 'Leave Duration'
      },
      {
        required: true,
        value: fields.type,
        field: 'Leave Type',
      },
      {
        required: true,
        value: fields.remarks,
        field: 'Remarks',
      },
    ],
    [fields],
  )

  const disableDates = (date) => {
    let isHoliday = false
    if (holiday.data && Array.isArray(holiday.data)) {
      for (let i = 0; i < holiday.data.length; i++) {
        const item = holiday.data[i]
        const incomingDate = moment(item.date)
        isHoliday =
          incomingDate.get('dates') == date.get('dates') &&
          incomingDate.get('months') == date.get('months') &&
          incomingDate.get('years') == date.get('years')
        if (isHoliday) break
      }
    }
    let isWeeklyOf = false
    if (!isHoliday) {

      if (weeklyOf && Array.isArray(weeklyOf) && weeklyOf.includes(date.day())) {
        isWeeklyOf = true
      } else if (user.data && user.data.weekly_of && Array.isArray(user.data.weekly_of) && user.data.weekly_of.includes(date.day())) {
        isWeeklyOf = true
      }
    }
    return isWeeklyOf || isHoliday
  }

  const onSubmit = async (e) => {
    if (holiday.loading) {
      return
    }
    e.preventDefault()

    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      const leaveData = { ...fields }

      leaveData.date = fields.date.format('YYYY-MM-DD')

      setLoading(true)
      dispatch(
        callApiAction(
          async () => await addManualLeaveApi(leaveData),
          async (response) => {

            setLoading(false)
            setFields(defaultFields)
            dispatch(closeModal())
            dispatch(callSnackBar('Leave Deducted Successfully', SNACK_BAR_VARIETNS.suceess))
          },
          (err) => {
            setLoading(false)
            setFields({ ...fields, err })
          },
        ),
      )
    } else {
      setFields({ ...fields, err: validationResponse })
    }
  }

  return (
    <AddManualLeaveRaiseUi
      disableDates={disableDates}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  )
}
export default memo(AddManualLeaveRaiseController)
