import React, { useEffect, useMemo, useState } from 'react'
import DisabilityAndTransgenderComplaintFormUi from './DisabilityAndTransgenderComplaintFormUi'
import { disabilityComplaintCreateApi, disabilityComplaintFetchByIdApi } from '../../apis/disabilityComplaint.api';
import { closeModal } from '../../store/actions/modalAction';
import { useDispatch, useSelector } from 'react-redux';
import { callApiAction } from '../../store/actions/commonAction';
import { fetchDocumentDataAction } from '../../store/actions/settingsAction';
import useValidate from '../../store/hooks/useValidator';
import moment from 'moment';
import { POLICY_COMPLAINT_TYPE, SNACK_BAR_VARIETNS } from '../../utils/constants';
import { callSnackBar } from '../../store/actions/snackbarAction';
import { TransgenderComplaintFetchByIdApi, transgenderComplaintCreateApi } from '../../apis/transgender.api';


const DisabilityAndTransgenderComplaintController = ({ callBack, id, value }) => {
  const [complainButton, setComplainButton] = useState(value ? value : POLICY_COMPLAINT_TYPE.Disability);
  const [loading, setLoading] = useState(false)
  const validate = useValidate()
  const dispatch = useDispatch()
  const { settings } = useSelector((state) => state)

  const [fields, setFields] = useState({
    id,
    disibility_type: [],
    complaint_to: {},
    co_worker: null,
    complaint_type: "",
    incident_date: moment().toISOString(),
    location: "",
    remarks: "",
    witness_name: "",
    witness_remarks: "",
    disability_certificate: "",
    other_details: "",
    // working_environment:"",
    other_details: "",
    infrastructure: "",
    frequency: "",
    gender_identity: "",
    special_training: "",
    rest_room: "",
    step_taken: "",
    facility_demanded: "",
    is_victim: {},
    victim_name: null,

    transgenders_certificate: "",
    complaint_to_transgender: "",
    evidence: "",
    accessibility: "",
    placing_audio_visual_fire_alarms: "",
    communication_technologies: "",
    special_leave: "",
    accessible_rest_rooms: "",
    other_details_working_environment: "",
  })

  const validationSchema = useMemo(() => ([
    {
      required: true,
      value: fields.disibility_type,
      field: 'Disability Type',
    },
    {
      required: true,
      value: fields.complaint_to,
      field: 'Complaint To',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.co_worker,
      field: 'Name Of Co Worker',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.complaint_type,
      field: 'Complaint Type',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.incident_date,
      field: 'Incident Date',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.location,
      field: 'Location',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.frequency,
      field: 'Frequency Of Incidents',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.remarks,
      field: 'Details Of Misconduct',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.witness_name,
      field: 'Witness Name',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.witness_remarks,
      field: 'Witness Remark',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.disability_certificate,
      field: 'Disability Certificate',
    },
    {
      required: fields.complaint_to == 1 ? true : false,
      value: fields.other_details,
      field: 'Other Details (Co-Worker)',
    },
    // {
    //   required: false,
    //   value: fields.working_environment,
    //   field: 'working_environment',
    // },
    {
      required: fields.complaint_to == 2 ? true : false,
      value: fields.infrastructure,
      field: 'Infrastructure Related Remark',
    },

    {
      required: fields.complaint_to == 2 ? true : false,
      value: fields.gender_identity,
      field: 'Gender Identity Related Remark',
    },
    {
      required: fields.complaint_to == 2 ? true : false,
      value: fields.special_training,
      field: 'Special Training Related Remark',
    },
    {
      required: fields.complaint_to == 2 ? true : false,
      value: fields.rest_room,
      field: 'Rest Room Related Remark',
    },
    {
      required: fields.complaint_to == 2 ? true : false,
      value: fields.other_details,
      field: 'Other Details (Working Environment)',
    },
    {
      required: true,
      value: fields.step_taken,
      field: 'Necessary Step Taken Remark',
    },
    {
      required: true,
      value: fields.facility_demanded,
      field: 'Necessary Facility Require Remark',
    },
    {
      required: true,
      value: fields.is_victim,
      field: 'Whether Complaint Lodged By Victim Or Anyother Person',
    },
    {
      required: fields.is_victim == 1 ? true : false,
      value: fields.victim_name,
      field: 'Victim Name',
    },
  ]), [fields])


  const submitData = (e) => {
    console.log("obhjbgjhb jhbgji ject");
    e.preventDefault()
    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await disabilityComplaintCreateApi({ ...fields }),
        (response) => {
          // callBack()
          setLoading(false)
          dispatch(closeModal())
          dispatch(
            callSnackBar("Disability Complaint Raised Successfully", SNACK_BAR_VARIETNS.suceess)
          );
        },
        (err) => {
          setLoading(false)
          setFields({ ...fields, err })
        }
      ))
    } else {
      setFields({ ...fields, 'err': validationResponse })
      // console.log("err");

    }
  }

  const validationTransgenderSchema = useMemo(() => ([
    {
      required: true,
      value: fields.transgenders_certificate,
      field: 'Transgender Certificate',
    },
    {
      required: true,
      value: fields.complaint_to_transgender,
      field: 'Complaint To',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.co_worker,
      field: 'C0-Worker Name',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.complaint_type,
      field: 'Complaint Type',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.incident_date,
      field: 'Incident Date',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.location,
      field: 'Location',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.frequency,
      field: 'Frequency',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.remarks,
      field: 'Remarks (Co-Worker)',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.witness_name,
      field: 'Witness Name',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.witness_remarks,
      field: 'Witness Remarks',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.evidence,
      field: 'Evidence',
    },
    {
      required: fields.complaint_to_transgender == 1 ? true : false,
      value: fields.other_details,
      field: 'Other Details (C0-Worker)',
    },


    {
      required: fields.complaint_to_transgender == 2 ? true : false,
      value: fields.infrastructure,
      field: 'Infrastructure Related Remarks',
    },
    {
      required: fields.complaint_to_transgender == 2 ? true : false,
      value: fields.accessibility,
      field: 'Accesibility Related Remarks',
    },
    {
      required: fields.complaint_to_transgender == 2 ? true : false,
      value: fields.placing_audio_visual_fire_alarms,
      field: 'Audio Visual Alarm Related Remark',
    },
    {
      required: fields.complaint_to_transgender == 2 ? true : false,
      value: fields.communication_technologies,
      field: 'Communication Technologies Related Remark',
    },
    {
      required: fields.complaint_to_transgender == 2 ? true : false,
      value: fields.special_leave,
      field: 'Special Leave Related Remark',
    },
    {
      required: fields.complaint_to_transgender == 2 ? true : false,
      value: fields.accessible_rest_rooms,
      field: 'Accessible Rest Rooms Related Remarks',
    },
    {
      required: fields.complaint_to_transgender == 2 ? true : false,
      value: fields.other_details_working_environment,
      field: 'Other Details (Working Environment)',
    },


    {
      required: true,
      value: fields.step_taken,
      field: 'Step Taken Related Remark',
    },
    {
      required: true,
      value: fields.facility_demanded,
      field: 'Facilities Related Remark',
    },
    {
      required: true,
      value: fields.is_victim,
      field: 'Complaint Lodged By Victim Or Not',
    },
    {
      required: fields.is_victim == 1 ? true : false,
      value: fields.victim_name,
      field: 'Victim Name',
    },

  ]), [fields])


  const submitTransgenderData = (e) => {
    console.log("obhjbgjhb jhbgji ject");
    e.preventDefault()
    const validationResponse = validate(validationTransgenderSchema)

    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await transgenderComplaintCreateApi({ ...fields }),
        (response) => {
          // callBack()
          setLoading(false)
          dispatch(closeModal())
          dispatch(
            callSnackBar("Disability Complaint Raised Successfully", SNACK_BAR_VARIETNS.suceess)
          );
        },
        (err) => {
          setLoading(false)
          setFields({ ...fields, err })
        }
      ))
    } else {
      setFields({ ...fields, 'err': validationResponse })
      // console.log("err");

    }
  }

  const fetchByIdDisability = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await disabilityComplaintFetchByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  const fetchByIdTransgender = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await TransgenderComplaintFetchByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id && value == 1) fetchByIdDisability(id);
    if (id && value == 2) fetchByIdTransgender(id);
  }, [id]);

  return (
    <DisabilityAndTransgenderComplaintFormUi
      id={id}
      fields={fields}
      onSubmit={submitData}
      setFields={setFields}
      complainButton={complainButton}
      setComplainButton={setComplainButton}
      onSubmitButton={submitTransgenderData}

    />
  )
}
export default DisabilityAndTransgenderComplaintController