import {
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Download } from "@mui/icons-material";
import fileDownload from "js-file-download";
import moment from "moment";
import SubmitButton from "../../components/button/SubmitButton";
import { callApiAction } from "../../store/actions/commonAction";
import { salarySlipPdfApi } from "../../apis/payroll.api";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { closeModal } from "../../store/actions/modalAction";

const DownloadSalarySlipPdf = ({ date }) => {
  const { user } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onClick = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await salarySlipPdfApi({
            id: user.employee_id,
            date: date.toISOString(),
          }),
        (response) => {
          setLoading(false);
          console.log(response, "response in pdf ");
          if (
            response.status === 0 ||
            response.code === 400 ||
            response.message === "no salary slip found"
          ) {
            dispatch(
              callSnackBar("No salary slip found", SNACK_BAR_VARIETNS.error)
            );
          } else {
            fileDownload(
              response,
              (user && user.name ? user.name : user.data.first_name) +
                "-salarySlip.pdf"
            );
            dispatch(
              callSnackBar(
                "Pdf Downloaded Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            dispatch(closeModal());
          }
        },
        (err) => {
          setLoading(false);
          dispatch(callSnackBar("Cant download Pdf", SNACK_BAR_VARIETNS.error));
        },
        true
      )
    );
  };
  return (
    <SubmitButton
      onClick={onClick}
      loading={loading}
      title={"Download Salary Slip"}
    />
  );
};
export default DownloadSalarySlipPdf;
