import React, { useEffect, useState } from 'react'
import DisabilityComplaintModalUi from './DisabilityComplaintModalUi';
import { disabilityComplaintFetchByIdApi } from '../../apis/disabilityComplaint.api';
import { callApiAction } from '../../store/actions/commonAction';
import { useDispatch } from 'react-redux';
import { TransgenderComplaintFetchByIdApi } from '../../apis/transgender.api';
import TransgenderComplaintModalUi from './TransgenderComplaintModalUi';
import { poshComplaintFetchByIdApi } from '../../apis/poshPolicy.api';
import POSHComplaintModalUi from './POSHComplaintModalUi';

const POSHComplaintModalController = ({ id }) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    console.log("object id modal", id);
    const fetchByIdPOSH = (id) => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await poshComplaintFetchByIdApi({ id }),
                async (response) => {
                    setData({...response});
                    console.log(" data and object", ...response);
                    setLoading(false);
                },
                (err) => {
                    setData({ ...data, err });
                    setLoading(false);
                }
            )
        );
    };

    useEffect(() => {
        if (id) fetchByIdPOSH(id);
    }, [id]);

    return (
        <POSHComplaintModalUi
            id={id}
            data={data}
            setData={setData}
            loading={loading}
        />
    )
}

export default POSHComplaintModalController