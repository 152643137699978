import axios from "axios";
import { getFileHeaders } from "../utils/helper";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const fetchSalaryStructurePdf = async (params) => {
  return await axios({
    url: endpoints.salaryStructurePdf,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));
};

export const createSalaryStructurePdf = async (data) => {
  const callResponse = await axios({
    url: endpoints.salaryStructurePdf,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const DeleteSalaryStructurePdfDetailsApi = async (data) => {
  return await axios({
    url: endpoints.salaryStructurePdf,
    method: "delete",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));
};

export const getSalaryStructurePdfByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.salaryStructurePdfbyId,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
