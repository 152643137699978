import React from 'react';
import { Box, Paper, IconButton, Typography } from '@mui/material';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import moment from 'moment';
import CustomDatePicker from './CustomDatePicker';
import colorTheme from '../../../assets/css/theme/colorTheme';

const getFinancialYearRange = (date) => {
  const month = date.month(); // Month in moment.js is 0-based (0 = January, 11 = December)
  const year = date.year();

  let fromYear, toYear;

  if (month < 3) { // January, February, March
    fromYear = year - 1;
    toYear = year;
  } else { // April to December
    fromYear = year;
    toYear = year + 1;
  }

  return {
    fromYear,
    toYear,
    fromMonth: 'April',
    toMonth: 'March'
  };
};

const CustomYearPicker = ({ date, setDate }) => {
  const { fromYear, toYear, fromMonth, toMonth } = getFinancialYearRange(date);

  const handlePrevYear = () => {
    setDate(moment(date).subtract(1, 'year'));
  };

  const handleNextYear = () => {
    setDate(moment(date).add(1, 'year'));
  };

  const currentYear = moment().year();
  
  // Enable "next" button as long as the starting year of the next financial year is not beyond the current year
  const isNextDisabled = fromYear + 1 > currentYear;

  return (
    <Paper
      sx={{
        display: 'flex',
        padding: 2,
        flex: 1,
        backgroundColor: colorTheme.palette.grey.calender,
        height: "100%",
        boxShadow: 'none',
        border: "1px solid " + colorTheme.palette.grey.calender
      }}
      component={Box}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          mr: 2,
          borderRight: '1px solid gray',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <IconButton onClick={handlePrevYear}>
          <KeyboardDoubleArrowLeft color="primary" />
        </IconButton>

        <Typography variant="h3">
          {`${fromMonth} ${fromYear} - ${toMonth} ${toYear}`}
        </Typography>

        <IconButton
          onClick={handleNextYear}
          disabled={isNextDisabled}
        >
          <KeyboardDoubleArrowRight
            color={isNextDisabled ? 'grey' : 'primary'}
          />
        </IconButton>
      </Box>

      <CustomDatePicker date={date} setDate={setDate} />
    </Paper>
  );
};

export default CustomYearPicker;
