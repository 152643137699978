import DataTable from "../../components/tables/DataTable";

import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import UserSearchBar, { StyledSearchBar } from "../../components/inputs/SearchBar";
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";



const EmployeeTaskReviewUi = ({
  forms,
  loading,
  filters,
  columns,
  setFilters,
  handleSearchChange,
  onUserChange,
  defaultVal,
}) => {
  return (
    <Box mt={3} mb={3}>


      
        <Box width={"100%"} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "40px", color: "#0D0D0D" }}>
            Employee Task Review
          </Typography>
          <StyledSearchBar
            placeholder="Search by User Name"
            value={filters.search}
            onChange={handleSearchChange}
            sx={{ width: "300px", marginRight: "16px" }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <CustomMonthPicker
            date={filters.date}
            setDate={(date) => setFilters({ ...filters, date })}
          />
        </Box>
        <Box sx={{ minHeight: "300px" }} mt={4}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <DataTable
              columns={columns}
              rows={forms}
              count={filters.total}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
              onChangePage={(page) => setFilters((prev) => ({ ...prev, pageNo: page }))}
              onChangeRowsPerPage={(pageSize) => setFilters((prev) => ({ ...prev, pageSize }))}
            />
          )}
        </Box>
    </Box>


  );
};

export default EmployeeTaskReviewUi;
