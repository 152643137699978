import { memo, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'
// import ForwardLeaveUi from './ForwardLeaveUi'
import { addManualLeaveApi, encashLeaveApi, forwadLeaveApi } from '../../../apis/leave.api'
import moment from 'moment';
import { HALF_TYPE_OBJ, SNACK_BAR_VARIETNS } from '../../../utils/constants'

import { callSnackBar } from '../../../store/actions/snackbarAction'
import { LEAVE_TYPE } from '../../../utils/leave.constants'
import AddLeaveEncashmentUi from './AddLeaveEncashmentUi'
import { getUserById } from '../../../apis/task.api'
import { getUserByIdApi } from '../../../apis/user.api'
import { closeModal } from "../../../store/actions/modalAction";

const AddLeaveEncashmentController = ({ weeklyOf, callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()

  const { holiday, user ,leaveBalance,leave} = useSelector((state) => state)
  
  const [loading, setLoading] = useState(false)
  const [state,setState] = useState(null)
  const defaultFields = {
    err: '',

    userId: null,
    type: LEAVE_TYPE.COFF,
    count: 0,
    workable_days:0,
    date: moment(),
    salary:0,
  }
  const [fields, setFields] = useState(defaultFields)

  const validationSchema = useMemo(
    () => [
      {
        required: true,
        value: fields.userId,
        field: 'Select User'
      },
      {
        required: true,
        value: fields.date,
        field: 'Date ',
        
      },
      {
        required: true,
        value: fields.leave_count,
        field: 'Leave Count'
      },
      {
        required: true,
        value: fields.workable_days,
        field: 'Workable Days'
      },
      {
        required: true,
        value: fields.type,
        field: 'Leave Type',
      },
      {
        // required: true,
        value: fields.salary,
        field: 'Salary',
      }
    ],
    [fields],
  )

  const onSubmit = async (e) => {
   
    e.preventDefault()

    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      const leaveData = { ...fields }

      leaveData.date = fields.date.toISOString()

      setLoading(true)
      dispatch(
        callApiAction(
          async () => await encashLeaveApi(leaveData),
          async (response) => {

            setLoading(false)
            setFields(defaultFields)
            dispatch(closeModal())
            dispatch(callSnackBar('Leave encashment Successfully',SNACK_BAR_VARIETNS.suceess))
          },
          (err) => {
            setLoading(false)
            setFields({ ...fields, err })
          },
        ),
      )
    } else {
      setFields({ ...fields, err: validationResponse })
    }
  }

//   useEffect(() => {
//     if (id) {
//         fetchList()
//     }
// }, [id])

  return (
    <AddLeaveEncashmentUi
      state={state}
      setState={setState}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  )
}
export default memo(AddLeaveEncashmentController)
