import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import { Box, Chip } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { callApiAction } from "../../store/actions/commonAction"
import { getReimberesementApi, getReimberesementCountApi } from "../../apis/reimbersement.api"
import { REIMBURSEMENT_STATUS } from "../../utils/constants"
import { findObjectKeyByValue, toTitleCase } from "../../utils/helper"
import ReimburesmentViewMoreButton from "./actionButtons/ReimburesementViewMoreButton"
import ReimburesementApprovalButton from "./actionButtons/ReimburesmentApprovalButtons"
import ReimberesementPageUI from "./ReimberesementPageUI"
import { AddCircle, CheckCircle } from "@mui/icons-material"
import { fetchPersonalReimbursementDataAction, fetchReimbursementCountAction } from "../../store/actions/reimbursementAction"

const ReimberesementController = ({ userId, selectedUser = {} }) => {
    console.log("userId in controller" , userId)
    const [list, setList] = useState({})
    const [loading, setLoading] = useState(false)
    const [counts, setCounts] = useState({})

    const [listLoading, setListLoading] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()
    const {reimbursement, user} = useSelector((state)=> state)
    const currentUserId  = user.data?._id

    const columns = useMemo(() => [
        { id: 1, fieldName: 'expenseDate', label: 'Expense Date', align: "left", sort: true, minWidth: '100px', renderValue: (params) => moment(params.expenseDate).format("DD/MM/YYYY") },
        { id: 2, fieldName: 'type', label: 'Expense Type', align: "left", renderValue: (params) => toTitleCase(params.type) },
        { id: 3, fieldName: 'amount', label: 'Amount', align: "left", sort: true, renderValue: (params) => "₹" + params.amount },
        { id: 7, fieldName: 'advance_payment_id', label: 'From Advance Payment', sort: true, align: "left", renderValue: (params) => params.advance_payment_id ? "yes" : "" },
        { id: 4, fieldName: 'purpose', label: 'Purpose', align: "left" },
        {
            id: 5, fieldName: 'status', label: 'Status', align: "left", renderValue: (params) => <Chip
                size="small"
                label={findObjectKeyByValue(params.status, REIMBURSEMENT_STATUS)}
                color={(params.status == REIMBURSEMENT_STATUS.approved || params.status == REIMBURSEMENT_STATUS.paid) ? "success" : (params.status == REIMBURSEMENT_STATUS.rejected ? "error" : "warning")}
            />,
            sort: true
        },
        {
            id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex", alignItems: "center" }}>
                <ReimburesmentViewMoreButton id={params._id} />
                <ReimburesementApprovalButton params={params} setParams={setParams} />
            </Box>
        },
    ], [dispatch]);

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 25,
        search: '',

        date: moment(),
        userId: userId ?? '',
        status: null

    })
    const fetchReimburesmentCounts = () => {
        setLoading(true)

        const passFilters = { ...filters }
        passFilters['date'] = passFilters['date'].toISOString()

        dispatch(callApiAction(
            async () => await getReimberesementCountApi({ ...passFilters }),
            (response) => {
                setCounts(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const fetchList = () => {
        const passFilters = { ...filters }
        passFilters['date'] = passFilters['date'].toISOString()
        setListLoading(true)
        dispatch(callApiAction(
            async () => await getReimberesementApi({ ...passFilters }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }



    // const onCreateBtnClick = () => {
    //     dispatch(openModal(<ReimberesementCreateController userId={userId} callBack={async () => { fetchList() }} />, "sm"))
    // }
    const getReimbursementCountData = () => {
        const reimbursementMonth = moment(reimbursement.count_filters.date).month();
        const currentMonth = moment(filters.date).month()
        // console.log("reimbursement month", reimbursementMonth)
        // console.log("current month", currentMonth)
        // console.log("reimbursementReducer controller", reimbursement)
        if (!reimbursement.count_data || reimbursement.count_data.length === 0 || reimbursementMonth != currentMonth || (!userId ? (reimbursement.count_filters.userId != currentUserId) : (reimbursement.count_filters.userId != userId))) {
            dispatch(fetchReimbursementCountAction(filters));
        }
    }
    useEffect(() => {
        getReimbursementCountData()
        // fetchReimburesmentCounts()
    }, [filters.date, filters.userId])

    const getPersonalReimbursementList = () => {
        const reimbursementMonth = moment(reimbursement.count_filters.date).month();
        const currentMonth = moment(filters.date).month();
    
        if (!reimbursement.personal_reimbursement_data || 
            reimbursement.personal_reimbursement_data.length === 0 || 
            reimbursementMonth != currentMonth || 
            !Object.keys(filters).filter(k => k != 'date').every(k => filters[k] == reimbursement.personal_reimbursement_filters[k])) {
            dispatch(fetchPersonalReimbursementDataAction(filters));
        } else {
            // If data is available, refresh the UI immediately
            setList(reimbursement.personal_reimbursement_data);
        }
    };
    
    useEffect(() => {
        getPersonalReimbursementList();
    }, [filters]);
    



    return (
        <>
            <ReimberesementPageUI
                callBack={getPersonalReimbursementList}
                columns={columns}

                // counts={counts}
                counts={reimbursement.count_data}
                selectedUser={selectedUser}
                // listLoading={listLoading}
                listLoading = {reimbursement.personal_reimbursement_loading}
                filters={filters}
                setFilters={setFilters}
                // loading={loading}
                loading={reimbursement.count_loading}
                // list={list}
                list={reimbursement.personal_reimbursement_data}
                isForEmployee={userId}

            />

        </>
    )
}
export default ReimberesementController