import React from 'react'
import { Box, Button, ButtonGroup, Chip, FormControlLabel, Radio, Typography } from '@mui/material'
import CustomInput from '../../components/inputs/CustomInput'
import UserSearchBar, { UserSearchBarNormal, UserSearchBarNormalUser } from '../../components/inputs/SearchBar'
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState } from 'react'
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PaddingBoxInDesktop from '../../components/layouts/common/PaddingBoxInDesktop'
import { DatePicker, DesktopDatePicker, MobileTimePicker, TimePicker } from '@mui/x-date-pickers'
import CustomDatePicker from '../../components/layouts/common/CustomDatePicker'
import { useSelector } from 'react-redux'
import moment from 'moment'
import FileInput from '../../components/inputs/FileInput'
import SubmitButton from '../../components/button/SubmitButton'
import { ADOPTION_RESPONSE, COMPLAINT_RELATION_TO, POLICY_COMPLAINT_TYPE, DISIBILITY_TYPE, TYPE_OF_COMPLAINT, COMPLAINT_RELATION_TO_TRANSGENDER, TYPE_OF_COMPLAINT_TRANSGENDER, VICTIM_RESPONSE } from '../../utils/constants'
import { titleCase } from '../../utils/helper'


const DisabilityAndTransgenderComplaintFormUi = ({ fields, setFields, loading, disableDates, onSubmit, setComplainButton, complainButton, onSubmitButton, id }) => {

    const { holiday } = useSelector((state) => state)

    const [radioselected, setRadioselected] = useState('')

    const [selected, setSelected] = useState('')
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    console.log("object fields fields fieldsv fields", fields);
    return (
        <>
            <Box display={"flex"} flexDirection={"column"} alignItems={"space-between"}>

                <PaddingBoxInDesktop
                    sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}

                >
                    <ButtonGroup
                        disableElevation
                        variant="outlined"
                        sx={{ height: "6vh" }}
                    >
                        <Button
                            onClick={() => { setFields({ ...fields, err: "" }); setComplainButton(POLICY_COMPLAINT_TYPE.Disability) }}
                            variant={complainButton == POLICY_COMPLAINT_TYPE.Disability ? "contained" : "outlined"}
                        >
                            <Typography variant="h5">Disability</Typography>
                        </Button>
                        <Button
                            onClick={() => { setFields({ ...fields, err: "" }); setComplainButton(POLICY_COMPLAINT_TYPE.Transgender) }}
                            variant={complainButton == POLICY_COMPLAINT_TYPE.Transgender ? "contained" : "outlined"}
                        >
                            <Typography variant="h5">Transgender</Typography>
                        </Button>
                    </ButtonGroup>
                </PaddingBoxInDesktop>

                {(complainButton == POLICY_COMPLAINT_TYPE.Disability) &&
                    <Box mt={2} display={"flex"} flexDirection={"column"} alignItems={"space-between"} justifyContent={"center"}>
                        <Box mt={3} display={"flex"} justifyContent={"center"}>
                            <Typography variant='h3'>Grievance Form For Person With Disability</Typography>
                        </Box>

                        {
                            fields.err && <Typography color="error" variant="h6">
                                {fields.err}
                            </Typography>
                        }

                        <Box mt={4}>
                            <Autocomplete
                                multiple
                                options={DISIBILITY_TYPE}
                                // defaultValue={
                                //     fields.disibility_type
                                //         ? fields.disibility_type?.map((item) =>
                                //             DISIBILITY_TYPE.find((e) => item == e.value)
                                //         )
                                //         : []
                                // }
                                onChange={(event, newValue) => {
                                    setFields({
                                        ...fields, err: '', disibility_type: newValue.map((val) => val.value),
                                    });
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    );
                                }}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Type Of Disability" placeholder="" />
                                )}
                            />

                        </Box>
                        <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>

                            <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>

                                <FormLabel id="demo-radio-buttons-group-label">Complaint in relation to :</FormLabel>
                                <RadioGroup
                                    row
                                    sx={{ marginLeft: "10px" }}
                                    value={fields.complaint_to}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            err: "",
                                            complaint_to: e.target.value

                                        })
                                    }
                                >
                                    {Object.keys(COMPLAINT_RELATION_TO).map((item) => (
                                        <FormControlLabel
                                            disabled={loading}
                                            key={item}
                                            value={COMPLAINT_RELATION_TO[item]}
                                            control={<Radio />}
                                            label={item}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>

                        </Box>

                        {fields.complaint_to == COMPLAINT_RELATION_TO['Co-Worker'] &&
                            <Box mt={2}>
                                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                    <Box>
                                        <Typography>Name Of The Co-Worker : </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%' }}>
                                        <UserSearchBarNormalUser
                                            placeholder="search"
                                            inputProps={{ placeholder: "Search & Select Employee" }}
                                            value={fields.co_worker}
                                            onChange={(newVal) => {
                                                setFields({ ...fields, err: '', co_worker: newVal._id })
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box mt={3}>
                                    <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                        <FormLabel id="demo-radio-buttons-group-label">Type Of Comlplaint : </FormLabel>
                                        <RadioGroup
                                            row
                                            sx={{ marginLeft: "10px" }}
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={fields.complaint_type}
                                            onChange={(e) =>
                                                setFields({
                                                    ...fields,
                                                    err: '',
                                                    complaint_type: e.target.value

                                                })
                                            }
                                            defaultValue=""
                                            name="radio-buttons-group"
                                        >
                                            {Object.keys(TYPE_OF_COMPLAINT).map((item) => (
                                                <FormControlLabel
                                                    disabled={loading}
                                                    key={item}
                                                    value={TYPE_OF_COMPLAINT[item]}
                                                    control={<Radio />}
                                                    label={item}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>

                                <Box mt={3} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box sx={{ display: 'flex', width: '24%' }}>
                                        <DesktopDatePicker
                                            autoFocus={true}
                                            label={"Date Of The Incident*"}
                                            renderInput={(props) => {
                                                return <CustomInput {...props} sx={{ height: '100%' }} />
                                            }}
                                            shouldDisableDate={disableDates}
                                            inputFormat="DD-MM-yyyy"
                                            disabled={
                                                loading ||
                                                !(holiday.data && Array.isArray(holiday.data)) ||
                                                holiday.loading
                                            }
                                            value={moment(fields.incident_date)}
                                            onChange={(e) =>
                                                setFields({
                                                    ...fields,
                                                    err: '', incident_date: e.toISOString(),
                                                })
                                            }
                                            type="date"
                                        />

                                    </Box>

                                    <Box width={"24%"}>
                                        <TimePicker
                                            label={"Time Of The Incident*"}
                                            renderInput={(props) => {
                                                return <CustomInput {...props} sx={{ height: '100%' }} />
                                            }}

                                            disabled={
                                                loading ||
                                                !(holiday.data && Array.isArray(holiday.data)) ||
                                                holiday.loading
                                            }
                                            value={moment(fields?.start_time)}
                                            onChange={(e) =>
                                                setFields({ ...fields, err: '', start_time: e.toISOString() })
                                            }
                                        // type="Start Time"
                                        />
                                    </Box>

                                    <Box width={"24%"}>
                                        <CustomInput
                                            disabled={loading}
                                            value={fields.location}
                                            onChange={(e) => setFields({ ...fields, err: '', location: e.target.value })}
                                            type="text"
                                            label={"Location Where The Incident Has Taken Place*"}
                                        />
                                    </Box>
                                    <Box width={"24%"}>
                                        <CustomInput
                                            // disabled={loading}
                                            value={fields.frequency}
                                            onChange={(e) => setFields({ ...fields, err: '', frequency: e.target.value })}
                                            type="number"
                                            label={"Frequency Of The Incident*"}
                                        />
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <CustomInput
                                        rows={2}
                                        multiline
                                        // disabled={loading}
                                        value={fields.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                                        type="text"
                                        label={"Details Of Misconduct Done Including The Sequence Of Events Leading To The Same*"}
                                    />
                                </Box>

                                <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box width={"20%"}>
                                        <CustomInput
                                            // disabled={loading}
                                            value={fields.witness_name}
                                            onChange={(e) => setFields({ ...fields, err: '', witness_name: e.target.value })}
                                            type="text"
                                            label={"Name Of Witness (If Any)"}
                                        />
                                    </Box>
                                    <Box width={"79%"}>
                                        <CustomInput

                                            // disabled={loading}
                                            value={fields.witness_remarks}
                                            onChange={(e) => setFields({ ...fields, err: '', witness_remarks: e.target.value })}
                                            type="text"
                                            label={"Remarks Of The Witness (If Any)"}
                                        />
                                    </Box>
                                </Box>

                                <Box mt={2} display={"flex"} flexDirection={"column"} >
                                    <Box>
                                        <Typography fontWeight={400} variant="h5"> Documents supporting the evidence : </Typography>
                                    </Box>
                                    <Box ml={1} width={"100%"} >
                                        <FileInput
                                            multi
                                            value={fields.disability_certificate}
                                            onChange={(file, fileArr) => {
                                                setFields({
                                                    ...fields,
                                                    disability_certificate: fileArr
                                                })
                                            }}
                                            defaults={fields.disability_certificate ?? []}
                                            accept=""
                                            title="Upload Document"
                                            subTitle="Only documents are allowed! less than 1 MB"
                                        />
                                    </Box>
                                </Box>

                                <Box mt={2}>
                                    <CustomInput
                                        rows={2}
                                        multiline
                                        // disabled={loading}
                                        value={fields.other_details}
                                        onChange={(e) => setFields({ ...fields, err: '', other_details: e.target.value })}
                                        type="text"
                                        label={"Other Details (If Any)"}
                                    />
                                </Box>
                            </Box>
                        }

                        {fields.complaint_to == COMPLAINT_RELATION_TO['Working Environment'] &&
                            <Box >
                                <Box mt={2} width={"100%"}>
                                    <CustomInput
                                        rows={2}
                                        multiline
                                        autoFocus={true}
                                        // disabled={loading}
                                        value={fields.infrastructure}
                                        onChange={(e) => setFields({ ...fields, err: '', infrastructure: e.target.value })}
                                        type="text"
                                        label={"Infrastructure For Example - Providing Accessible Toilet Facilities, Hygiene Products Etc."}
                                    />
                                </Box>
                                <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.gender_identity}
                                            onChange={(e) => setFields({ ...fields, err: '', gender_identity: e.target.value })}
                                            type="text"
                                            label={"Maintaining Confidentiality Of The Gender Identity Of The Employees."}
                                        />
                                    </Box>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.special_training}
                                            onChange={(e) => setFields({ ...fields, err: '', special_training: e.target.value })}
                                            type="text"
                                            label={"Any Special Training Or Counseling Needed."}
                                        />
                                    </Box>
                                </Box>
                                <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.rest_room}
                                            onChange={(e) => setFields({ ...fields, err: '', rest_room: e.target.value })}
                                            type="text"
                                            label={"Accessible Rest Rooms."}
                                        />
                                    </Box>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.other_details}
                                            onChange={(e) => setFields({ ...fields, err: '', other_details: e.target.value })}
                                            type="text"
                                            label={"Others (Specify Details If Any)"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        }
                        <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>

                            <Box width={"49%"} mt={2}>
                                <CustomInput
                                    rows={2}
                                    multiline
                                    // disabled={loading}
                                    value={fields.step_taken}
                                    onChange={(e) => setFields({ ...fields, err: '', step_taken: e.target.value })}
                                    type="text"
                                    label={"Steps Taken By The Complainant To Resolve The Issue."}
                                />
                            </Box>

                            <Box width={"49%"} mt={2}>
                                <CustomInput
                                    rows={2}
                                    multiline
                                    // disabled={loading}
                                    value={fields.facility_demanded}
                                    onChange={(e) => setFields({ ...fields, err: '', facility_demanded: e.target.value })}
                                    type="text"
                                    label={"Remedy Or Facility Demanded By The Complainant."}
                                />
                            </Box>
                        </Box>

                        <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>

                            <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                <FormLabel id="demo-radio-buttons-group-label">Whether Complaint Lodged By The Victim Or Any Other Person On His/Her Behalf :</FormLabel>
                                <RadioGroup
                                    row
                                    sx={{ marginLeft: "10px" }}
                                    value={fields.is_victim}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            err: "",
                                            is_victim: e.target.value

                                        })
                                    }
                                >
                                    {Object.keys(ADOPTION_RESPONSE).map((item) => (
                                        <FormControlLabel
                                            disabled={loading}
                                            key={item}
                                            value={ADOPTION_RESPONSE[item]}
                                            control={<Radio />}
                                            label={item}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        {fields.is_victim == "true" &&
                            <Box mt={2} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography>Name Of The Person : </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <UserSearchBarNormalUser
                                        placeholder="search"
                                        inputProps={{ placeholder: "Search & Select Employee" }}
                                        value={fields.victim_name}
                                        onChange={(newVal) => {
                                            setFields({ ...fields, err: '', victim_name: newVal?._id })
                                        }}
                                    />
                                </Box>
                            </Box>}

                        {!id && <Box mt={2}>
                            <SubmitButton
                                title={"Submit"}
                                onClick={onSubmit}
                            />
                        </Box>}
                    </Box>}



                {(complainButton == POLICY_COMPLAINT_TYPE.Transgender) &&
                    <Box mt={2} display={"flex"} flexDirection={"column"} alignItems={"space-between"} justifyContent={"center"}>

                        <Box mt={3} display={"flex"} justifyContent={"center"}>
                            <Typography variant='h3'>Grievance Form For Transgender</Typography>
                        </Box>

                        {
                            fields.err && <Typography color="error" variant="h6">
                                {fields.err}
                            </Typography>
                        }

                        <Box mt={3} display={"flex"} flexDirection={"column"} >
                            <Box>
                                <Typography fontWeight={400} variant="h5">The Transgender Certificate And ID Card : </Typography>
                            </Box>
                            <Box ml={1} width={"100%"} >
                                <FileInput

                                    value={fields.transgenders_certificate}
                                    onChange={(file, fileArr) => {
                                        setFields({
                                            ...fields,
                                            transgenders_certificate: fileArr[0]
                                        })
                                    }}
                                    defaults={fields.transgenders_certificate}
                                    accept=""
                                    title="Upload Document"
                                    subTitle="Only documents are allowed! less than 1 MB"
                                />

                            </Box>
                        </Box>

                        <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>

                            <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                <FormLabel id="demo-radio-buttons-group-label">Complaint in relation to :</FormLabel>
                                <RadioGroup
                                    row
                                    sx={{ marginLeft: "10px" }}
                                    value={fields.complaint_to_transgender}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            err: "",
                                            complaint_to_transgender: e.target.value

                                        })
                                    }
                                >
                                    {Object.keys(COMPLAINT_RELATION_TO_TRANSGENDER).map((item) => (
                                        <FormControlLabel
                                            disabled={loading}
                                            key={item}
                                            value={COMPLAINT_RELATION_TO_TRANSGENDER[item]}
                                            control={<Radio />}
                                            label={item}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>

                        </Box>

                        {fields.complaint_to_transgender == COMPLAINT_RELATION_TO_TRANSGENDER['Co-Worker'] &&
                            <Box mt={2}>
                                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                    <Box>
                                        <Typography>Name Of The Co-Worker : </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%' }}>
                                        <UserSearchBarNormalUser
                                            placeholder="search"
                                            inputProps={{ placeholder: "Search & Select Employee" }}
                                            value={fields.co_worker}
                                            onChange={(newVal) => {
                                                setFields({ ...fields, err: '', co_worker: newVal?._id })
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box mt={3}>
                                    <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                        <FormLabel id="demo-radio-buttons-group-label">Type Of Comlplaint : </FormLabel>
                                        <RadioGroup
                                            row
                                            sx={{ marginLeft: "10px" }}
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            name="radio-buttons-group"
                                            value={fields.complaint_type}
                                            onChange={(e) =>
                                                setFields({
                                                    ...fields,
                                                    err: '',
                                                    complaint_type: e.target.value
                                                })
                                            }
                                        >
                                            {Object.keys(TYPE_OF_COMPLAINT_TRANSGENDER).map((item) => (
                                                <FormControlLabel
                                                    disabled={loading}
                                                    key={item}
                                                    value={TYPE_OF_COMPLAINT_TRANSGENDER[item]}
                                                    control={<Radio />}
                                                    label={item}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {/* <Box>
                                <CustomDatePicker/>
                                </Box> */}
                                <Box mt={3} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box sx={{ display: 'flex', width: '24%' }}>
                                        <DesktopDatePicker
                                            autoFocus={true}
                                            label={"Date Of The Incident*"}
                                            renderInput={(props) => {
                                                return <CustomInput {...props} sx={{ height: '100%' }} />
                                            }}
                                            // shouldDisableDate={disableDates}
                                            inputFormat="DD-MM-yyyy"
                                            disabled={
                                                loading ||
                                                !(holiday.data && Array.isArray(holiday.data)) ||
                                                holiday.loading
                                            }
                                            value={moment(fields.incident_date)}
                                            onChange={(e) =>
                                                setFields({
                                                    ...fields,
                                                    err: '', incident_date: e.toISOString(),
                                                })
                                            }
                                            type="date"
                                        />

                                    </Box>

                                    <Box width={"24%"}>
                                        <TimePicker
                                            label={"Time Of The Incident*"}
                                            renderInput={(props) => {
                                                return <CustomInput {...props} sx={{ height: '100%' }} />
                                            }}
                                            // shouldDisableDate={disableDates}

                                            disabled={
                                                loading ||
                                                !(holiday.data && Array.isArray(holiday.data)) ||
                                                holiday.loading
                                            }
                                            value={moment(fields.start_time)}
                                            onChange={(e) =>
                                                setFields({ ...fields, err: '', start_time: e.toISOString() })
                                            }
                                        // type="Start Time"
                                        />
                                    </Box>

                                    <Box width={"24%"}>
                                        <CustomInput
                                            // disabled={loading}
                                            value={fields.location}
                                            onChange={(e) => setFields({ ...fields, err: '', location: e.target.value })}
                                            type="text"
                                            label={"Location Where The Incident Has Taken Place*"}
                                        />
                                    </Box>
                                    <Box width={"24%"}>
                                        <CustomInput
                                            // disabled={loading}
                                            value={fields.frequency}
                                            onChange={(e) => setFields({ ...fields, err: '', frequency: e.target.value })}
                                            type="number"
                                            label={"Frequency Of The Incident*"}
                                        />
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <CustomInput
                                        rows={2}
                                        multiline
                                        // disabled={loading}
                                        value={fields.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                                        type="text"
                                        label={"Details Of Misconduct Done Including The Sequence Of Events Leading To The Same*"}
                                    />
                                </Box>

                                <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box width={"20%"}>
                                        <CustomInput
                                            // disabled={loading}
                                            value={fields.witness_name}
                                            onChange={(e) => setFields({ ...fields, err: '', witness_name: e.target.value })}
                                            type="text"
                                            label={"Name Of Witness (If Any)"}
                                        />
                                    </Box>
                                    <Box width={"79%"}>
                                        <CustomInput

                                            // disabled={loading}
                                            value={fields.witness_remarks}
                                            onChange={(e) => setFields({ ...fields, err: '', witness_remarks: e.target.value })}
                                            type="text"
                                            label={"Remarks Of The Witness (If Any)"}
                                        />
                                    </Box>
                                </Box>

                                <Box mt={2} display={"flex"} flexDirection={"column"} >
                                    <Box>
                                        <Typography fontWeight={400} variant="h4"> Documents supporting the evidence : </Typography>
                                    </Box>
                                    <Box ml={1} width={"100%"} >
                                        <FileInput
                                            multi
                                            value={fields.evidence}
                                            onChange={(file, fileArr) => {
                                                setFields({
                                                    ...fields,
                                                    err: '',
                                                    evidence: fileArr[0]
                                                })
                                            }}
                                            defaults={fields.evidence ?? []}
                                            accept=""
                                            title="Upload Document"
                                            subTitle="Only documents are allowed! less than 1 MB"
                                        />
                                    </Box>
                                </Box>

                                <Box mt={2}>
                                    <CustomInput
                                        rows={2}
                                        multiline
                                        // disabled={loading}
                                        value={fields.other_details}
                                        onChange={(e) => setFields({ ...fields, err: '', other_details: e.target.value })}
                                        type="text"
                                        label={"Other Details (If Any)"}
                                    />
                                </Box>
                            </Box>
                        }

                        {fields.complaint_to_transgender == COMPLAINT_RELATION_TO_TRANSGENDER['Working Environment'] &&
                            <Box >
                                <Box mt={2} width={"100%"}>
                                    <CustomInput
                                        rows={2}
                                        multiline
                                        autoFocus={true}
                                        // disabled={loading}
                                        value={fields.infrastructure}
                                        onChange={(e) => setFields({ ...fields, err: '', infrastructure: e.target.value })}
                                        type="text"
                                        label={"Infrastructure For Example - Providing Accessible Toilet Facilities For Transgenders."}
                                    />
                                </Box>
                                <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.accessibility}
                                            onChange={(e) => setFields({ ...fields, err: '', accessibility: e.target.value })}
                                            type="text"
                                            label={"Accessibility For Example - Replacing Steps With Ramps etc."}
                                        />
                                    </Box>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.placing_audio_visual_fire_alarms}
                                            onChange={(e) => setFields({ ...fields, err: '', placing_audio_visual_fire_alarms: e.target.value })}
                                            type="text"
                                            label={"Placing Audio-Visual Fire Alarms."}
                                        />
                                    </Box>
                                </Box>
                                <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.communication_technologies}
                                            onChange={(e) => setFields({ ...fields, err: '', communication_technologies: e.target.value })}
                                            type="text"
                                            label={"Communication Technologies."}
                                        />
                                    </Box>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.special_leave}
                                            onChange={(e) => setFields({ ...fields, err: '', special_leave: e.target.value })}
                                            type="text"
                                            label={"Special Leave"}
                                        />
                                    </Box>
                                </Box>
                                <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.accessible_rest_rooms}
                                            onChange={(e) => setFields({ ...fields, err: '', accessible_rest_rooms: e.target.value })}
                                            type="text"
                                            label={"Accessible Rest Rooms."}
                                        />
                                    </Box>
                                    <Box mt={2} width={"49%"}>
                                        <CustomInput
                                            rows={2}
                                            multiline
                                            // disabled={loading}
                                            value={fields.other_details_working_environment}
                                            onChange={(e) => setFields({ ...fields, err: '', other_details_working_environment: e.target.value })}
                                            type="text"
                                            label={"Others (Specify Details If Any)"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        }
                        <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>

                            <Box width={"49%"} mt={2}>
                                <CustomInput
                                    rows={2}
                                    multiline
                                    // disabled={loading}
                                    value={fields.step_taken}
                                    onChange={(e) => setFields({ ...fields, err: '', step_taken: e.target.value })}
                                    type="text"
                                    label={"Steps Taken By The Complainant To Resolve The Issue."}
                                />
                            </Box>

                            <Box width={"49%"} mt={2}>
                                <CustomInput
                                    rows={2}
                                    multiline
                                    // disabled={loading}
                                    value={fields.facility_demanded}
                                    onChange={(e) => setFields({ ...fields, err: '', facility_demanded: e.target.value })}
                                    type="text"
                                    label={"Remedy Or Facility Demanded By The Complainant."}
                                />
                            </Box>
                        </Box>

                        <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>

                            <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                <FormLabel id="demo-radio-buttons-group-label">Whether Complaint Lodged By The Victim Or Any Other Person On His/Her Behalf :</FormLabel>
                                <RadioGroup
                                    row
                                    sx={{ marginLeft: "10px" }}
                                    value={fields.is_victim}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            err: "",
                                            is_victim: e.target.value

                                        })
                                    }
                                >
                                    {Object.keys(VICTIM_RESPONSE).map((item) => (
                                        <FormControlLabel
                                            disabled={loading}
                                            key={item}
                                            value={VICTIM_RESPONSE[item]}
                                            control={<Radio />}
                                            label={item}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        {fields.is_victim == "true" &&
                            <Box mt={2} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography>Name Of The Person : </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <UserSearchBarNormalUser
                                        placeholder="search"
                                        inputProps={{ placeholder: "Search & Select Employee" }}
                                        value={fields.victim_name}
                                        onChange={(newVal) => {
                                            setFields({ ...fields, err: '', victim_name: newVal?._id })
                                        }}
                                    />
                                </Box>
                            </Box>}

                        {!id && <Box mt={2}>
                            <SubmitButton
                                title={"Submit"}
                                onClick={onSubmitButton}

                            />
                        </Box>}
                    </Box>}
            </Box >
        </>
    )
}

export default DisabilityAndTransgenderComplaintFormUi