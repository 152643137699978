import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { closeModal, openModal } from "../../store/actions/modalAction";
import moment from "moment";
import UploadPdfUI from "./UploadPdfUI";
import useValidate from "../../store/hooks/useValidator";
import {
  createSalaryStructurePdf,
  fetchSalaryStructurePdf,
} from "../../apis/SalaryStructurePdf.api";

const UploadPdfController = (callBack) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState((moment().inputFormat = "YYYY"));
  const [file, setFile] = useState(null);
  const [err, setErr] = useState("");
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState([]);
  const validate = useValidate();
  const { settings } = useSelector((state) => state);

  const [fields, setFields] = useState({
    userId: "",
    url: "",
    year: "",
    err: "",
  });

  const validationSchema = useMemo(
    () => [
      {
        required: true,
        value: fields.userId,
        field: " userId ",
      },
      {
        required: true,
        value: fields.url,
        field: "url",
      },
      {
        required: true,
        value: fields.year,
        field: "year",
      },
    ],
    [fields]
  );

  const onSubmit = (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchema);
    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createSalaryStructurePdf({ ...fields }),
          (response) => {
            setLoading(false);
            fetchSalaryStructurePdf(response);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  return (
    <UploadPdfUI
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
      setFields={setFields}
      err={err}
    />
  );
};

export default UploadPdfController;
