import {  Box, ButtonBase, Tooltip, Typography, styled, useTheme } from "@mui/material"
import { CALENDAR_ITEM_TYPES } from "../../../../utils/constants"
import { center } from "../../../../assets/css/theme/common"
import { TASK_STATUS } from "../../../../utils/task.constants"
import { setStringView } from "../../../../utils/helper"
import moment from "moment"
import { Check, CheckCircle, CheckCircleOutline, Warning } from "@mui/icons-material"
import { Link, useNavigate } from "react-router-dom"
import { CenteredBox } from "../../../../components/layouts/common/boxes"
import { EmployeeTaskUserContext } from "../../EmployeeMain"
import { useContext } from 'react'


const TaskItem = styled(ButtonBase)(({ theme, color, background }) => ({
    position: 'relative',
    zIndex: 1,
    width: '100%',
    padding: '4px',
    backgroundColor: background ?? color,
    '::after': {
        content: "' '",
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        zIndex: -1,
    },
    '*': {
        color: background ? color : '#d68332 !important',
    },
}));

const SmallCalendarButton = styled(ButtonBase)(({ theme }) => ({
    height: '35px',
    width: '35px',
    borderRadius: '100%',
    lineHeight: '100%',
    backgroundColor: '#f2f2f2',
}));

const findColor = (status, type) => {
    if (type === CALENDAR_ITEM_TYPES.pms_task) {
        return '#fff2e7';
    }
    if (status === TASK_STATUS.COMPLETED) {
        return 'rgba(55, 221, 151, 1)';
    }
    if (status === TASK_STATUS.REJECTED) {
        return 'rgba(255, 24, 24, 1)';
    }
    if (status === TASK_STATUS.PENDING) {
        return 'rgba(230, 111, 25, 1)';
    }
    if (status === TASK_STATUS.MARKED_AS_COMPLETED) {
        return 'rgba(230, 111, 25, 1)';
    }
    return 'grey';
};
const MobileViewDesktop = ({ data, date, weekDay, weeklyOf, isMobile, onClick, isHoliday, dailyUpdates, selectedUser, ...props }) => {
    const theme = useTheme();

    return (
        <CenteredBox sx={{ width: '100%', flexDirection: 'column' }} p={1}>
            <SmallCalendarButton
                sx={{
                    background: isHoliday ? '#D58C37' : '#f2f2f2',
                    color: isHoliday ? '#fff' : 'unset',
                    flexDirection: 'column',
                }}
                onClick={onClick}
            >
                {/* Conditionally render daily update symbol */}
                {selectedUser && (
                    <Box
                        sx={{
                            height: '6px',
                            width: '6px',
                            background: dailyUpdates?.length > 0 ? theme.palette.success.main : theme.palette.grey[400],
                            borderRadius: '100%',
                            position: 'absolute',
                            top: '2px',
                        }}
                    />
                )}
                {date}
            </SmallCalendarButton>

            <CenteredBox>
                {data
                    .filter(item => item.type === CALENDAR_ITEM_TYPES.task || item.type === CALENDAR_ITEM_TYPES.pms_task)
                    .map(item => (
                        <Box
                            onClick={item.onClick}
                            key={item.title}
                            sx={{
                                height: '6px',
                                width: '6px',
                                background: findColor(item.status, item.type),
                                borderRadius: '100%',
                            }}
                        />
                    ))}
            </CenteredBox>
        </CenteredBox>
    );
};

const CalendarInnerBox = ({ data, weeklyOf, weekDay, date, isMobile, calendarTimeObj, ...props }) => {
    const isCompanyHoliday = data.find(item => item.type === CALENDAR_ITEM_TYPES.holiday);
    const selectedUser = useContext(EmployeeTaskUserContext);
    const dailyUpdates = data?.filter(item => item.type === CALENDAR_ITEM_TYPES.daily_updates);
    const isWeeklyOf = weeklyOf.includes(weekDay);
    console.log("selectedUser",selectedUser)
    if (isMobile) {
        return (
            <MobileViewDesktop
                data={data}
                date={date}
                weekDay={weekDay}
                weeklyOf={weeklyOf}
                dailyUpdates={dailyUpdates}
                selectedUser={selectedUser}  // Pass selectedUser to MobileViewDesktop
                {...props}
                isHoliday={isWeeklyOf || isCompanyHoliday}
            />
        );
    }

    return (
        <Box sx={{ height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'flex-end', display: 'flex', flex: 1 }} pt={5}>

            { selectedUser && dailyUpdates?.length > 0 ? (
                <Box
                    // LinkComponent={Link}
                    // to={'daily-update/' + moment(dailyUpdates[0].start).valueOf()}
                     sx={{ position: 'absolute', top: '5px', left: '5px' }}
                    // onClick={() => {}}
                >
                    <Tooltip title="This icon denotes they have added there daily update">
                        <CheckCircle color="success" fontSize="inherit" />
                    </Tooltip>
                </Box>
            ) : (
                selectedUser && (
                    <Box sx={{ position: 'absolute', top: '5px', left: '5px' }}>
                        <Tooltip title="This icon denotes they have not added there daily update">
                            <CheckCircleOutline color="grey" fontSize="inherit" />
                        </Tooltip>
                    </Box>
                )
            )}
            {data &&
                data.map((item, id) => {
                    if (item.type === CALENDAR_ITEM_TYPES.task || item.type === CALENDAR_ITEM_TYPES.pms_task) {
                        return (
                            <TaskItem color={findColor(item.status, item.type)} onClick={item.onClick} key={id} sx={{ zIndex: 1 }}>
                                <Typography variant="caption">{setStringView(item.title)}</Typography>
                            </TaskItem>
                        );
                    }
                })}
            {(isWeeklyOf || isCompanyHoliday) && (
                <Box sx={{ zIndex: 1, background: '#D58C37', width: '100%', ...center }}>
                    <Typography color="light.main" align="center" variant="caption">
                        {isCompanyHoliday ? isCompanyHoliday.title : 'WO'}
                    </Typography>
                </Box>
            )}
            {(isWeeklyOf || (isCompanyHoliday && isCompanyHoliday !== -1)) && (
                <Box
                    sx={{
                        zIndex: 0,
                        top: '0px',
                        width: '100%',
                        position: 'absolute',
                        height: '100%',
                        background: '#f1f1f1',
                        ...center,
                    }}
                />
            )}
        </Box>
    );
};


export default CalendarInnerBox;
