import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const fetchDepartments = async (params) => {
  const callResponse = await axios({
    url: endpoints.department,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

export const createDepartment = async (data) => {
  const callResponse = await axios({
    url: endpoints.department,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

export const deleteDepartment = async (data) => {
  const callResponse = await axios({
    url: endpoints.department,
    method: "delete",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

export const updateDepartment = async (data) => {
  const callResponse = await axios({
    url: endpoints.department,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};
export const UserMultipleDepartmentUpdate = async data => {
  const callResponse = await axios({
    url: endpoints.multipledepartment,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

