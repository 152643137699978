import { Box, ButtonBase, Tooltip, Typography, styled, useTheme } from "@mui/material"
import { CALENDAR_ITEM_TYPES } from "../../../../utils/constants"
import { center } from "../../../../assets/css/theme/common"
import { TASK_STATUS } from "../../../../utils/task.constants"
import { setStringView } from "../../../../utils/helper"
import moment from "moment"
import { Check, CheckCircle, CheckCircleOutline } from "@mui/icons-material"
import { Link, useNavigate } from "react-router-dom"
import { CenteredBox } from "../../../../components/layouts/common/boxes"
import { useContext } from "react"
import { EmployeeTaskUserContext } from '../../EmployeeMain'
import { useDispatch } from "react-redux"
import DailyupdateController from "../../DailyUpdate/DailyupdateController"
import { openModal } from "../../../../store/actions/modalAction"
import DailyUpdateReportController from "../../DailyUpdate/DailyUpdateReportController"



// const TaskItem = styled(ButtonBase)(({ theme, color, background }) => ({
//     position: 'relative',
//     zIndex: 1,
//     width: '100%',
//     padding: '4px',
//     backgroundColor: background ?? color,
//     '::after': {
//         content: "' '",
//         position: 'absolute',
//         top: '0px',
//         left: '0px',
//         height: '100%',
//         width: '100%',
//         zIndex: -1,
//     },
//     '*': {
//         color: background ? color : '#d68332 !important',
//     },
// }));

// const SmallCalendarButton = styled(ButtonBase)(({ theme }) => ({
//     height: '35px',
//     width: '35px',
//     borderRadius: '100%',
//     lineHeight: '100%',
//     backgroundColor: '#f2f2f2',
// }));

// const findColor = (status, type) => {
//     if (type === CALENDAR_ITEM_TYPES.pms_task) {
//         return '#fff2e7';
//     }
//     if (status === TASK_STATUS.COMPLETED) {
//         return 'rgba(55, 221, 151, 1)';
//     }
//     if (status === TASK_STATUS.REJECTED) {
//         return 'rgba(255, 24, 24, 1)';
//     }
//     if (status === TASK_STATUS.PENDING) {
//         return 'rgba(230, 111, 25, 1)';
//     }
//     if (status === TASK_STATUS.MARKED_AS_COMPLETED) {
//         return 'rgba(230, 111, 25, 1)';
//     }
//     return 'grey';
// };

// const MobileViewDesktop = ({ data, date, weekDay, weeklyOf, forEmployee, isMobile, onClick, isHoliday, dailyUpdates, ...props }) => {
//     const theme = useTheme();

//     return (
//         <CenteredBox sx={{ width: '100%', flexDirection: 'column' }} p={1}>
//             <SmallCalendarButton
//                 sx={{
//                     background: isHoliday ? '#D58C37' : '#f2f2f2',
//                     color: isHoliday ? '#fff' : 'unset',
//                     flexDirection: 'column',
//                 }}
//                 onClick={onClick}
//             >
//                 <Box
//                     sx={{
//                         height: '6px',
//                         width: '6px',
//                         background: dailyUpdates?.length > 0 ? theme.palette.success.main : 'transparent',
//                         borderRadius: '100%',
//                         position: 'absolute',
//                         top: '2px',
//                     }}
//                 />
//                 {date}
//             </SmallCalendarButton>

//             <CenteredBox>
//                 {data
//                     .filter(item => item.type === CALENDAR_ITEM_TYPES.task || item.type === CALENDAR_ITEM_TYPES.pms_task)
//                     .map(item => (
//                         <Box
//                             onClick={item.onClick}
//                             key={item.title}
//                             sx={{
//                                 height: '6px',
//                                 width: '6px',
//                                 background: findColor(item.status, item.type),
//                                 borderRadius: '100%',
//                             }}
//                         />
//                     ))}
//             </CenteredBox>
//         </CenteredBox>
//     );
// };

// const CalendarInnerBox = ({ data, weeklyOf, weekDay, date, isMobile, calendarTimeObj, ...props }) => {
//     const isCompanyHoliday = data.find(item => item.type === CALENDAR_ITEM_TYPES.holiday);
//     const selectedUser = useContext(EmployeeTaskUserContext);
//     const userId = selectedUser ? selectedUser._id : null;

//     const dailyUpdates = data?.filter(item => item.type === CALENDAR_ITEM_TYPES.daily_updates);
//     const isWeeklyOf = weeklyOf.includes(weekDay);

//     if (isMobile) {
//         return (
//             <MobileViewDesktop
//                 data={data}
//                 date={date}
//                 weekDay={weekDay}
//                 weeklyOf={weeklyOf}
//                 dailyUpdates={dailyUpdates}
//                 {...props}
//                 isHoliday={isWeeklyOf || isCompanyHoliday}
//             />
//         );
//     }

//     return (
//         <Box sx={{ height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'flex-end', display: 'flex', flex: 1 }} pt={5}>
//             {dailyUpdates?.length > 0 ? (
//                 <ButtonBase
//                     LinkComponent={Link}
//                     to={'daily-update/' + moment(dailyUpdates[0].start).valueOf()}
//                     sx={{ position: 'absolute', top: '5px', left: '5px' }}
//                      onClick={() => {}}
//                 >
//                     <Tooltip title="This icon denotes that you have added your daily updates">
//                         <CheckCircle color="success" fontSize="inherit" />
//                     </Tooltip>
//                 </ButtonBase>
//             ) : (
//                 <Box sx={{ position: 'absolute', top: '5px', left: '5px' }}>
//                     <Tooltip color="red" title="This icon denotes that you have not added your daily updates">
//                         <CheckCircleOutline color="grey" fontSize="inherit" />
//                     </Tooltip>
//                 </Box>
//             )}
//             {data &&
//                 data.map((item, id) => {
//                     if (item.type === CALENDAR_ITEM_TYPES.task || item.type === CALENDAR_ITEM_TYPES.pms_task) {
//                         return (
//                             <TaskItem color={findColor(item.status, item.type)} onClick={item.onClick} key={id} sx={{ zIndex: 1 }}>
//                                 <Typography variant="caption">{setStringView(item.title)}</Typography>
//                             </TaskItem>
//                         );
//                     }
//                 })}
//             {(isWeeklyOf || isCompanyHoliday) && (
//                 <Box sx={{ zIndex: 1, background: '#D58C37', width: '100%', ...center }}>
//                     <Typography color="light.main" align="center" variant="caption">
//                         {isCompanyHoliday ? isCompanyHoliday.title : 'WO'}
//                     </Typography>
//                 </Box>
//             )}
//             {(isWeeklyOf || (isCompanyHoliday && isCompanyHoliday !== -1)) && (
//                 <Box
//                     sx={{
//                         zIndex: 0,
//                         top: '0px',
//                         width: '100%',
//                         position: 'absolute',
//                         height: '100%',
//                         background: '#f1f1f1',
//                         ...center,
//                     }}
//                 />
//             )}
//         </Box>
//     );
// };

// export default CalendarInnerBox;

const TaskItem = styled(ButtonBase)(({ color, background }) => ({
    position: 'relative',
    zIndex: 1,
    width: '100%',
    padding: '4px',
    backgroundColor: background ?? color,
    '::after': {
        content: "' '",
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        zIndex: -1,
    },
    '*': {
        color: background ? color : '#d68332 !important',
    },
}));
const SmallCalendarButton = styled(ButtonBase)(({ theme }) => ({
    height: '35px',
    width: '35px',
    borderRadius: '100%',
    lineHeight: '100%',
    backgroundColor: '#f2f2f2',
}));

const findColor = (status, type) => {
    if (type === CALENDAR_ITEM_TYPES.pms_task) {
        return '#fff2e7';
    }
    if (status === TASK_STATUS.COMPLETED) {
        return 'rgba(55, 221, 151, 1)';
    }
    if (status === TASK_STATUS.REJECTED) {
        return 'rgba(255, 24, 24, 1)';
    }
    if (status === TASK_STATUS.PENDING) {
        return 'rgba(230, 111, 25, 1)';
    }
    if (status === TASK_STATUS.MARKED_AS_COMPLETED) {
        return 'rgba(230, 111, 25, 1)';
    }
    return 'grey';
};

const MobileViewDesktop = ({ data, date, weekDay, weeklyOf, forEmployee, isMobile, onClick, isHoliday, dailyUpdates, ...props }) => {
    const theme = useTheme();

    return (
        <CenteredBox sx={{ width: '100%', flexDirection: 'column' }} p={1}>
            <SmallCalendarButton
                sx={{
                    background: isHoliday ? '#D58C37' : '#f2f2f2',
                    color: isHoliday ? '#fff' : 'unset',
                    flexDirection: 'column',
                }}
                onClick={onClick}
            >
                <Box
                    sx={{
                        height: '6px',
                        width: '6px',
                        background: dailyUpdates?.length > 0 ? theme.palette.success.main : 'transparent',
                        borderRadius: '100%',
                        position: 'absolute',
                        top: '2px',
                    }}
                />
                {date}
            </SmallCalendarButton>

            <CenteredBox>
                {data
                    .filter(item => item.type === CALENDAR_ITEM_TYPES.task || item.type === CALENDAR_ITEM_TYPES.pms_task)
                    .map(item => (
                        <Box
                            onClick={item.onClick}
                            key={item.title}
                            sx={{
                                height: '6px',
                                width: '6px',
                                background: findColor(item.status, item.type),
                                borderRadius: '100%',
                            }}
                        />
                    ))}
            </CenteredBox>
        </CenteredBox>
    );
};
const CalendarInnerBox = ({ data, weeklyOf, weekDay, date, isMobile, calendarTimeObj, ...props }) => {
    const isCompanyHoliday = data.find(item => item.type === CALENDAR_ITEM_TYPES.holiday);
    const selectedUser = useContext(EmployeeTaskUserContext);
    const userId = selectedUser ? selectedUser._id : null;
    const navigate = useNavigate(); // Use navigate hook for redirection

    const dailyUpdates = data?.filter(item => item.type === CALENDAR_ITEM_TYPES.daily_updates);
    const isWeeklyOf = weeklyOf.includes(weekDay);
    const dispatch = useDispatch();

    // console.log('calendarTimeObj:', calendarTimeObj);

    const handleDailyUpdateClick = (day) => {
        const year = calendarTimeObj.year();
        const month = calendarTimeObj.month() + 1; 
        const fullDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');

        console.log('Original Date:', day);
        console.log('Full Date:', fullDate);

        dispatch(openModal({
          component: <DailyUpdateReportController date={fullDate} userId={userId} />, // Pass fullDate and userId
          title: 'Daily Update',
          size: 'sm',
        }));
    };
    
    if (isMobile) {
        return (
            <MobileViewDesktop
                data={data}
                date={date}
                weekDay={weekDay}
                weeklyOf={weeklyOf}
                dailyUpdates={dailyUpdates}
                {...props}
                isHoliday={isWeeklyOf || isCompanyHoliday}
            />
        );
    }

    return (
        <Box sx={{ height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'flex-end', display: 'flex', flex: 1 }} pt={5}>
          {dailyUpdates?.length > 0 ? (
            <ButtonBase
              onClick={() => handleDailyUpdateClick(date)} // Pass the date to the handler
              sx={{ position: 'absolute', top: '5px', left: '5px' }}
            >
              <Tooltip title="This icon denotes that you have added your daily updates">
                <CheckCircle color="success" fontSize="inherit" />
              </Tooltip>
            </ButtonBase>
          ) : (
            <Box sx={{ position: 'absolute', top: '5px', left: '5px' }}>
              <Tooltip color="red" title="This icon denotes that you have not added your daily updates">
                <CheckCircleOutline color="grey" fontSize="inherit" />
              </Tooltip>
            </Box>
          )}
    
          {data && data.map((item, id) => {
            if (item.type === CALENDAR_ITEM_TYPES.task || item.type === CALENDAR_ITEM_TYPES.pms_task) {
              return (
                <TaskItem color={findColor(item.status, item.type)} onClick={item.onClick} key={id} sx={{ zIndex: 1 }}>
                  <Typography variant="caption">{item.title}</Typography>
                </TaskItem>
              );
            }
          })}
          {(isWeeklyOf || isCompanyHoliday) && (
            <Box sx={{ zIndex: 1, background: '#D58C37', width: '100%', ...center }}>
              <Typography color="light.main" align="center" variant="caption">
                {isCompanyHoliday ? isCompanyHoliday.title : 'WO'}
              </Typography>
            </Box>
          )}
          {(isWeeklyOf || (isCompanyHoliday && isCompanyHoliday !== -1)) && (
            <Box
              sx={{
                zIndex: 0,
                top: '0px',
                width: '100%',
                position: 'absolute',
                height: '100%',
                background: '#f1f1f1',
                ...center,
              }}
            />
          )}
        </Box>
      );
    };
    
    export default CalendarInnerBox;