import React, { useContext, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { AccountCircle, Business, Cancel } from "@mui/icons-material";
import DailyUpdateCustomDayPicker from "../../components/layouts/common/DailyUpdateCustomDayPicker";
import { EmployeeTaskUserContext } from "./EmployeeMain";
import { DAILYUPDATE_STATUS } from "../../utils/leave.constants";
import { useSelector } from "react-redux";
import { CenteredBox } from "../../components/layouts/common/boxes";

// const EmployeeDailyUpdateUi = ({
//   date,
//   setDate,
//   loading,
//   onReject,
//   onApprove,
//   handleUserCardClick,
//   fetchChildUsersOnClick,
//   allUsers,
// }) => {
//   const scrollRef = useRef();
//   const selectedUser = useContext(EmployeeTaskUserContext);

//   const userData = useSelector((state) => state.user);

//   const handleUserClick = (userId, userName) => {
//     fetchChildUsersOnClick(userId, userName);

//     if (scrollRef.current) {
//       setTimeout(() => {
//         scrollRef.current.scrollIntoView({
//           behavior: "smooth",
//           block: "start",
//         });
//       }, 300);
//     }
//   };

//   const renderUserCard = (user, key, userHierarchy, idx) => (
//     <Grid item xs={12} sm={6} md={4} key={key}>
//       {console.log("this is all aboute select user", selectedUser)}
//       <Card
//         sx={{
//           height: "200px",
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "space-between",
//           borderRadius: "12px",
//           boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
//           border: "1px solid #ddd",
//           transition: "box-shadow 0.3s ease-in-out",
//           "&:hover": {
//             boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
//           },
//         }}
//       >
//         <CardContent sx={{ overflowY: "auto", padding: "8px 12px" }}>
//           {/* Header Section */}
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "flex-start",
//               alignItems: "flex-start",
//               padding: "4px 8px",
//               borderRadius: "4px",
//               boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
//               backgroundColor: "#f9f9f9",
//               width: "100%",
//               cursor: "pointer",
//             }}
//             onClick={() => handleUserClick(user._id, user.name)}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 width: "100%",
//                 mb: user.tasks?.length > 0 ? 0.5 : 1,
//               }}
//             >
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontWeight: "bold",
//                   // color:
//                   //   user.tasks?.[0]?.status === DAILYUPDATE_STATUS.APPROVED
//                   //     ? "#388e3c"
//                   //     : user.tasks?.[0]?.status === DAILYUPDATE_STATUS.REJECTED
//                   //     ? "#d32f2f"
//                   //     : "#333",
//                   display: "flex",
//                   alignItems: "center",
//                 }}
//               >
//                 <AccountCircle sx={{ fontSize: 20, mr: 1 }} />
//                 {selectedUser ? selectedUser?.name : user?.name}
//               </Typography>
//             </Box>

//             <Typography
//               variant="body2"
//               sx={{
//                 display: "flex",
//                 alignItems: "center",

//                 mt: 0.5,
//               }}
//             >
//               <Business sx={{ fontSize: 18, mr: 1 }} />
//               {user.departmentName || "No Department"}
//             </Typography>
//           </Box>

//           {/* Description Section */}
//           {user.tasks?.length > 0 ? (
//             user.tasks.map((task, i) => (
//               <React.Fragment key={i}>
//                 {/* Render the description */}
//                 {/* {console.log("this is react fragment", task)} */}
//                 <Box
//                   mt={2}
//                   p={1}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     borderRadius: "10px",
//                     alignItems: "center",
//                     backgroundColor:
//                       task?.status === DAILYUPDATE_STATUS.REJECTED
//                         ? "rgba(244, 67, 54, 0.1)"
//                         : "#f9f9f9",
//                   }}
//                 >
//                   <Typography variant="body2" sx={{ flexGrow: 1 }}>
//                     <span
//                       dangerouslySetInnerHTML={{
//                         __html: task.description || "No description available.",
//                       }}
//                     />
//                   </Typography>

//                   {/* Approve/Reject icons for each task - Uncomment to use */}
//                   {task.status === DAILYUPDATE_STATUS.APPROVED && (
//                     <Box sx={{ ml: 1 }}>
//                       {/* <IconButton color="success" onClick={() => onApprove(task)}>
//                       <CheckCircle />
//                     </IconButton> */}

//                       <IconButton
//                         color="error"
//                         onClick={() => {
//                           if (selectedUser) {
//                             onReject({ task_id: task?._id }, user._id, 0, 0);
//                           } else {
//                             onReject(
//                               { task_id: task?._id },
//                               userHierarchy.parentUserId,
//                               userHierarchy.parentUserName,
//                               idx
//                             );
//                           }
//                         }}
//                       >
//                         <Cancel />
//                       </IconButton>
//                     </Box>
//                   )}
//                 </Box>

//                 {/* Render a thin line between descriptions if there is another task after this */}
//                 {i < user?.tasks?.length - 1 && <Divider sx={{ my: 1 }} />}
//               </React.Fragment>
//             ))
//           ) : (
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 height: "100px",
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 sx={{
//                   color: "#999",
//                   mt: 2,
//                 }}
//               >
//                 No Update Found.
//               </Typography>
//             </Box>
//           )}
//         </CardContent>
//       </Card>
//     </Grid>
//   );
//   const renderAllUsers = () => {
//     return allUsers.map((userHierarchy, idx) => {
//       // Check if both parentUserName exists and there are child users
//       const shouldRenderParentBox =
//         userHierarchy.parentUserName && userHierarchy.childUsers.length > 0;

//       return (
//         <Box
//           key={idx}
//           ref={idx === allUsers.length - 1 ? scrollRef : null}
//           mt={4}
//         >
//           {/* Conditionally render the parent user section only if conditions are met */}
//           {shouldRenderParentBox && (
//             <Box
//               mb={2}
//               p={2}
//               height={"6vh"}
//               width={"100%"}
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 backgroundColor: "lightgrey",
//                 border: "0px",
//                 borderRadius: "5px",
//               }}
//             >
//               <AccountCircle color="primary" sx={{ fontSize: 22, mr: 1 }} />
//               <Typography
//                 variant="h4"
//                 color={"primary"}
//                 sx={{ fontWeight: 700 }}
//               >
//                 {userHierarchy.parentUserName}
//               </Typography>
//             </Box>
//           )}

//           <Grid container spacing={3}>
//             {userHierarchy.childUsers.length > 0 ? (
//               userHierarchy.childUsers.map((user, index) =>
//                 renderUserCard(user, index, userHierarchy, idx)
//               )
//             ) : (
//               <>
//                 <Grid item xs={12}></Grid>
//               </>
//             )}
//           </Grid>
//         </Box>
//       );
//     });
//   };

//   return (
//     <Box mt={3} mb={3} position="relative">
//       <Box mb={2}>
//         <Typography
//           sx={{
//             fontWeight: 700,
//             fontSize: "24px",
//             lineHeight: "40px",
//             color: "#0D0D0D",
//           }}
//         >
//           Employee Daily Updates
//         </Typography>
//       </Box>

//       <>
//         <Box id="custom-day-picker">
//           <DailyUpdateCustomDayPicker date={date} setDate={setDate} />
//         </Box>
//       </>
//       {loading ? (
//         <CenteredBox sx={{ minHeight: "300px" }}>
//           <CircularProgress />
//         </CenteredBox>
//       ) : (
//         <>
//           {selectedUser
//             ? allUsers?.map((userHierarchy) =>
//                 userHierarchy.childUsers
//                   .filter((user) => user._id === selectedUser._id)
//                   .map((user, index) => (
//                     <Box key={index} mt={4}>
//                       <Grid container spacing={3} direction={"row"}>
//                         {renderUserCard(user, index)}
//                       </Grid>
//                     </Box>
//                   ))
//               )
//             : renderAllUsers()}
//         </>
//       )}
//     </Box>
//   );
// };

// export default EmployeeDailyUpdateUi;

const EmployeeDailyUpdateUi = ({
  date,
  setDate,
  loading,
  onReject,
  onApprove,
  handleUserCardClick,
  fetchChildUsersOnClick,
  allUsers,
}) => {
  const scrollRef = useRef();
  const selectedUser = useContext(EmployeeTaskUserContext);

  const userData = useSelector((state) => state.user);

  const handleUserClick = (userId, userName) => {
    fetchChildUsersOnClick(userId, userName);

    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 300);
    }
  };

  const renderUserCard = (user, key, userHierarchy, idx) => (
    <Grid item xs={12} sm={6} md={4} key={key}>
      <Card
        sx={{
          height: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "12px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid #ddd",
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent sx={{ overflowY: "auto", padding: "8px 12px" }}>
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              padding: "4px 8px",
              borderRadius: "4px",
              boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#f9f9f9",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => handleUserClick(user._id, user.name)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: user.tasks?.length > 0 ? 0.5 : 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AccountCircle sx={{ fontSize: 20, mr: 1 }} />
                {selectedUser ? selectedUser?.name : user?.name}
              </Typography>
            </Box>

            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 0.5,
              }}
            >
              <Business sx={{ fontSize: 18, mr: 1 }} />
              {user.departmentName || "No Department"}
            </Typography>
          </Box>

          {/* Description Section */}
          {user.tasks?.length > 0 ? (
            user.tasks.map((task, i) => (
              <React.Fragment key={i}>
                <Box
                  mt={2}
                  p={1}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    alignItems: "center",
                    backgroundColor:
                      task?.status === DAILYUPDATE_STATUS.REJECTED
                        ? "rgba(244, 67, 54, 0.1)"
                        : "#f9f9f9",
                  }}
                >
                  <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: task.description || "No description available.",
                      }}
                    />
                  </Typography>

                  {task.status === DAILYUPDATE_STATUS.APPROVED && (
                    <Box sx={{ ml: 1 }}>
                      <IconButton
                        color="error"
                        onClick={(event) => {
                          event.stopPropagation();

                          if (selectedUser) {
                            onReject(
                              { task_id: task?._id },
                              userData.data._id,
                              userData.data.name,
                              0
                            );
                          } else {
                            onReject(
                              { task_id: task?._id },
                              userHierarchy.parentUserId,
                              userHierarchy.parentUserName,
                              idx
                            );
                          }
                        }}
                      >
                        <Cancel />
                      </IconButton>
                    </Box>
                  )}
                </Box>

                {/* Divider between tasks */}
                {i < user?.tasks?.length - 1 && <Divider sx={{ my: 1 }} />}
              </React.Fragment>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#999",
                  mt: 2,
                }}
              >
                No Update Found.
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );

  const renderAllUsers = () => {
    if (
      allUsers.length === 0 ||
      allUsers.every((userHierarchy) => userHierarchy.childUsers.length === 0)
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#999",
              textAlign: "center",
            }}
          >
            No users found.
          </Typography>
        </Box>
      );
    }

    return allUsers.map((userHierarchy, idx) => {
      const shouldRenderParentBox =
        userHierarchy.parentUserName && userHierarchy.childUsers.length > 0;

      return (
        <Box
          key={idx}
          ref={idx === allUsers.length - 1 ? scrollRef : null}
          mt={4}
        >
          {shouldRenderParentBox && (
            <Box
              mb={2}
              p={2}
              height={"6vh"}
              width={"100%"}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "lightgrey",
                borderRadius: "5px",
              }}
            >
              <AccountCircle color="primary" sx={{ fontSize: 22, mr: 1 }} />
              <Typography
                variant="h4"
                color={"primary"}
                sx={{ fontWeight: 700 }}
              >
                {userHierarchy.parentUserName}
              </Typography>
            </Box>
          )}

          <Grid container spacing={3}>
            {userHierarchy.childUsers.length > 0 ? (
              userHierarchy.childUsers.map((user, index) =>
                renderUserCard(user, index, userHierarchy, idx)
              )
            ) : (
              <Grid item xs={12}></Grid>
            )}
          </Grid>
        </Box>
      );
    });
  };

  return (
    <Box mt={3} mb={3} position="relative">
      <Box mb={2}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "40px",
            color: "#0D0D0D",
          }}
        >
          Employee Daily Updates
        </Typography>
      </Box>

      <>
        <Box id="custom-day-picker">
          <DailyUpdateCustomDayPicker date={date} setDate={setDate} />
        </Box>
      </>
      {loading ? (
        <CenteredBox sx={{ minHeight: "300px" }}>
          <CircularProgress />
        </CenteredBox>
      ) : (
        <>
          {selectedUser
            ? (() => {
                const selectedUserData = allUsers
                  .flatMap((userHierarchy) => userHierarchy.childUsers)
                  .filter((user) => user._id === selectedUser._id);

                return selectedUserData.length > 0 ? (
                  <Box mt={4}>
                    <Grid container spacing={3} direction="row">
                      {selectedUserData.map((user, index) =>
                        renderUserCard(user, index)
                      )}
                    </Grid>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100px",
                    }}
                  >
                    <Typography variant="h4" sx={{ color: "#999" }}>
                      No Updates found.
                    </Typography>
                  </Box>
                );
              })()
            : renderAllUsers()}
        </>
      )}
    </Box>
  );
};

export default EmployeeDailyUpdateUi;
