// import { Delete } from "@mui/icons-material"
// import { CircularProgress, IconButton, Typography } from "@mui/material"
// import { useState } from "react"
// import { deleteHoliday } from "../../apis/holiday.api"
// import { callApiAction } from "../../store/actions/commonAction"
// import { callSnackBar } from "../../store/actions/snackbarAction"
// import { SNACK_BAR_VARIETNS } from "../../utils/constants"
// import { useDispatch, useSelector } from "react-redux"
// import { closeModal, openModal } from "../../store/actions/modalAction"
// import { deleteVersion } from "../../apis/version.api"
// import { deletedocument } from "../../apis/document.api"
// import { setDocumentDataOnDeleteAction } from "../../store/actions/settingsAction"


// const DeleteDocumentButton = ({ params, setParams }) => {
//     const dispatch = useDispatch()
//     const [loading, setLoading] = useState()
//     const {settings} = useSelector((state) => state)

//     const onDelete = () => {
//         setLoading(true);
//         dispatch(
//             callApiAction(
//                 async () => await deletedocument({ id: params._id }),
//                 (response) => {
                    
//                     setLoading(false);
//                     setParams({})
//                     const updatedData = settings.document_data.filter(item => item._id != params._id)
//                     dispatch(setDocumentDataOnDeleteAction(updatedData, settings.document_filters))
//                     dispatch(callSnackBar("Document Deleted", SNACK_BAR_VARIETNS.suceess))
//                 },
//                 (err) => {
//                     setLoading(false);
//                     dispatch(callSnackBar("Can not delete Document", SNACK_BAR_VARIETNS.error))
//                 }
//             )
//         );
//         dispatch(closeModal())
//     }
//     const onClick = () => {
//         dispatch(openModal({
//             title:"Alert!",
//             component:<Typography>{"Are sure to want to delete "+params.name+" ?"}</Typography>,
//             onConfirm:onDelete,
//             size:"sm",
//             confirmText:"Delete",
//             onCancle:()=>dispatch(closeModal())
//         }))
//     }
//     if (loading)
//         return <CircularProgress />
//     return <IconButton onClick={onClick}>
//         <Delete color="error" />
//     </IconButton>
// }
// export default DeleteDocumentButton

import { Delete } from "@mui/icons-material"
import { CircularProgress, IconButton, Typography } from "@mui/material"
import { useState } from "react"
import { deleteHoliday } from "../../apis/holiday.api"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { deleteVersion } from "../../apis/version.api"
import { deletedocument } from "../../apis/document.api"
import { setDocumentDataOnDeleteAction } from "../../store/actions/settingsAction"


const DeleteDocumentButton = ({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const {settings} = useSelector((state) => state)

    const onDelete = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await deletedocument({ id: params._id }),
                (response) => {
                    
                    setLoading(false);
                    setParams({})
                    const updatedData = settings.document_data.filter(item => item._id != params._id)
                    dispatch(setDocumentDataOnDeleteAction(updatedData, settings.document_filters))
                    dispatch(callSnackBar("Document Deleted", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Can not delete Document", SNACK_BAR_VARIETNS.error))
                }
            )
        );
        dispatch(closeModal())
    }
    const onClick = () => {
        dispatch(openModal({
            title:"Alert!",
            component:<Typography>{"Are sure to want to delete "+params.name+" ?"}</Typography>,
            onConfirm:onDelete,
            size:"sm",
            confirmText:"Delete",
            onCancle:()=>dispatch(closeModal())
        }))
    }
    if (loading)
        return <CircularProgress />
    return <IconButton onClick={onClick}>
        <Delete color="error" />
    </IconButton>
}
export default DeleteDocumentButton