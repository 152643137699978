import React, { useEffect, useMemo, useState } from 'react'
import PoshPolicyComplaintFormUi from './PoshPolicyComplaintFormUi';
import { closeModal } from '../../store/actions/modalAction';
import { useDispatch, useSelector } from 'react-redux';
import { callApiAction } from '../../store/actions/commonAction';
import { fetchDocumentDataAction } from '../../store/actions/settingsAction';
import useValidate from '../../store/hooks/useValidator';
import { poshComplaintCreateApi, poshComplaintFetchByIdApi } from '../../apis/poshPolicy.api';
import { KebabDining } from '@mui/icons-material';
import { SNACK_BAR_VARIETNS, SUPPORTING_DOCUMENTS } from '../../utils/constants';
import { callSnackBar } from '../../store/actions/snackbarAction';


const PoshComplaintController = ({ callBack, id }) => {
  const [loading, setLoading] = useState(false)
  const validate = useValidate()
  const dispatch = useDispatch()
  const { settings } = useSelector((state) => state)

  const [fields, setFields] = useState({
    id,
    respondentId: "",

    natureOfComplaint: null,

    detailsOfComplaint: { dateOfIncident: "", timeOfIncident: "", location: "", frequency: "", stepsTaken: "", detailsOfMisconduct: "" },

    supportingDocuments: { supportingDocuments_type: "", others: "", attachments: "" },

    complaintLodgedBy: { isVictim: {} },

    witness: { name: "", remarks: "" },

    otherDetails: "",

  })
  console.log("object user field rudren ", fields);


  const validationSchema = useMemo(() => ([
    {
      required: true,
      value: fields.respondentId,
      field: ' Respondent Name '
    },
    {
      required: true,
      value: fields.natureOfComplaint,
      field: 'Nature Of The Complaint',
    },
    // {
    //   required: true,
    //   value: fields.natureOfComplaint.nonVerbal,
    //   field: 'natureOfComplaint.nonVerbal',
    // },
    // {
    //   required: true,
    //   value: fields.natureOfComplaint.physicalBehavior,
    //   field: 'natureOfComplaint.physicalBehavior',
    // }, 
    {
      required: true,
      value: fields.detailsOfComplaint.dateOfIncident,
      field: 'Date Of Incident',
    },
    {
      required: true,
      value: fields.detailsOfComplaint.timeOfIncident,
      field: 'Time Of Incident',
    },
    {
      required: true,
      value: fields.detailsOfComplaint.location,
      field: 'Location',
    },
    {
      required: true,
      value: fields.detailsOfComplaint.frequency,
      field: 'Frequency Of Incident',
    },
    {
      required: true,
      value: fields.detailsOfComplaint.detailsOfMisconduct,
      field: 'Details Of Misconduct',
    },
    {
      required: true,
      value: fields.detailsOfComplaint.stepsTaken,
      field: 'Steps Need To Be Taken',
    },
    {
      required: true,
      value: fields.supportingDocuments.supportingDocuments_type,
      field: 'Supporting Documents Selection',
    },

    {
      required: fields.supportingDocuments.supportingDocuments_type == 5 ? true : false,
      value: fields.supportingDocuments.others,
      field: 'Other Details (Supporting Documents)',
    },
    {
      required: true,
      value: fields.supportingDocuments.attachments,
      field: 'Supporting Documents Attachments',
    },
    {
      required: true,
      value: fields.complaintLodgedBy.isVictim,
      field: 'Complaint Lodged By',
    },
    {
      required: true,
      value: fields.witness.name,
      field: 'Witness Name',
    },
    {
      required: true,
      value: fields.witness.remarks,
      field: 'Witness Remarks',
    },
    {
      required: false,
      value: fields.otherDetails,
      field: 'Other Details',
    },
  ]), [fields])


  const submitData = (e) => {
    console.log("obhjbgjhb jhbgji ject");
    e.preventDefault()
    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await poshComplaintCreateApi({ ...fields }),
        (response) => {
          // callBack()
          setLoading(false)
          dispatch(closeModal())
          dispatch(
            callSnackBar("Complaint Raised Successfully", SNACK_BAR_VARIETNS.suceess)
          );
        },

        (err) => {
          setLoading(false)
          setFields({ ...fields, err })
        }
      ))
    } else {
      setFields({ ...fields, 'err': validationResponse })
      // console.log("err");

    }
  }
  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await poshComplaintFetchByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <PoshPolicyComplaintFormUi
      id={id}
      fields={fields}
      setFields={setFields}
      onSubmit={submitData}
    // disableDates={disableDates}

    />
  )
}
export default PoshComplaintController