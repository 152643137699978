import {
  Avatar,
  Box,
  ButtonBase,
  Grid,
  Paper,
  Typography,
  Popover,
  List,
  ListSubheader,
  ListItemButton,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import React, { memo, useState } from "react";
import { toTitleCase } from "../../../utils/helper";
import NotificationModel from "../../../pages/notification/NotificationModel";
import ResetPasswordController from "../../../pages/reset-password/ResetPasswordController";
import CompanyDocuments from "../../../pages/companyDocuments/CompanyDocuments";
import { Link, useNavigate } from "react-router-dom";
import { center } from "../../../assets/css/theme/common";
import { signOutAction } from "../../../store/actions/userReducerAction";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import responsive from "../../../assets/css/responsive";
import MenuIcon from "@mui/icons-material/Menu";
import { Logo } from "./Logo";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Lock, PictureAsPdfRounded } from "@mui/icons-material";
import { openModal } from "../../../store/actions/modalAction";
import ImageComponent from "../../inputs/ImageComponent";
import { AccountBalance } from "@mui/icons-material";
import SalarySlipUi from "../../../pages/payrollSummary/SalarySlipUi";
import { ReceiptLong } from "@mui/icons-material";
import FeedbackFormButton from "../../../pages/feedbackForm/FeedbackFormButton";
import { HistoryEdu } from "@mui/icons-material";
import { CenteredBox } from "./boxes";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ApprasialLetterUi from "../../../pages/appresialLetter/ApprasialLetterUI";
import Form16UI from "../../../pages/form16/Form16UI";
//import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EmployeeEvaluationButton from "../../../pages/employeeEvaluation/AddEmployeeEvaluationButton";
import SalaryStructurePdfUI from "../../../pages/SalaryStructurePdf/SalaryStructurePdfUI";

const headerStyle = (theme) => ({
  width: "100%",
  background: "white",
  position: "sticky",
  top: "0px",
  display: "flex",
  alignItems: "center",
  height: "88px",
  pt: 3,
  pb: 3,
  pr: 3,
  pl: 3,
  zIndex: "11",
  borderRadius: "0px",
  boxShadow:
    "40px 15px 26px rgba(0, 0, 0, 0.01), 18px 7px 19px rgba(0, 0, 0, 0.02), 4px 2px 10px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02)",
  border: "1px solid #E6E6E6",

  ...responsive.headerStyle,
});
const mobileHeaderStyle = (theme) => ({
  width: "100%",
  background: "white",
  position: "sticky",
  top: "0px",
  display: "flex",
  alignItems: "center",
  height: "50px",
  pr: "20px",
  pl: "20px",
  zIndex: "11",
  borderRadius: "0px",
  boxShadow:
    "40px 15px 26px rgba(0, 0, 0, 0.01), 18px 7px 19px rgba(0, 0, 0, 0.02), 4px 2px 10px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02)",
  border: "1px solid #E6E6E6",
  ...responsive.mobileHeaderStyle,
});

const ProfileBox = ({ style = {} }) => {
  const user = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onResetButtonClick = () => {
    dispatch(
      openModal({
        component: <ResetPasswordController />,
        size: "sm",
        title: "Reset Password",
      })
    );
  };

  const openSalaryModal = () => {
    dispatch(
      openModal({
        component: <SalarySlipUi />,
        size: "sm",
        title: "My Salary Slip",
      })
    );
  };

  const openApprasialModal = () => {
    dispatch(
      openModal({
        component: <ApprasialLetterUi />,
        size: "sm",
        title: "Apprasial Letter",
      })
    );
  };

  const openSalaryStructurePdfModal = () => {
    dispatch(
      openModal({
        component: <SalaryStructurePdfUI />,
        size: "sm",
        title: "Salary Structure Pdf",
      })
    );
  };

  const openForm16Modal = () => {
    dispatch(
      openModal({ component: <Form16UI />, size: "sm", title: "Form-16" })
    );
  };
  return (
    <>
      <ButtonBase
        p={0}
        LinkComponent={Link}
        aria-describedby={id}
        onClick={handleClick}
      >
        <ImageComponent
          avtarProps={{
            sx: {
              height: "50px",
              width: "50px",
              bgcolor: "grey.main",
              ...style,
              objectFit: "contain !important",
            },
          }}
          src={user.data?.profile_url}
          isAvatar
          avtarTitle={
            user.data?.first_name &&
            user.data?.first_name[0] &&
            user.data?.first_name[0].toUpperCase()
          }
        />
      </ButtonBase>
      <Popover
        sx={(theme) => ({ paddingTop: 3 })}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List
          sx={{
            width: "260px",
            maxWidth: "100%",
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component={Box}
              variant="subtitle1"
              pt={2}
              color="text.primary"
              id="nested-list-subheader"
            >
              <Typography lineHeight="150%" color="primary" variant="h5">
                {user.data.first_name &&
                  toTitleCase(user.data.first_name) +
                    " " +
                    toTitleCase(user.data.last_name)}
              </Typography>
              <Typography lineHeight="100%" variant="body1" color={"text.200"}>
                {user.data.designation}
              </Typography>
            </ListSubheader>
          }
        >
          <ListItemButton
            aria-describedby={id}
            onClick={() => {
              navigate("/profile/");
              handleClose();
            }}
          >
            <AccountCircleIcon variant={"primary.main"} />
            &nbsp;&nbsp;View Profile
          </ListItemButton>

          <ListItemButton onClick={openSalaryModal}>
            <ReceiptLong variant={"primary.main"} />
            &nbsp;&nbsp;My Salary Slip
          </ListItemButton>

          <ListItemButton onClick={openApprasialModal}>
            <HistoryEdu variant={"primary.main"} />
            &nbsp;&nbsp;Apprasial Letter
          </ListItemButton>

          <ListItemButton onClick={openForm16Modal}>
            <InsertDriveFileIcon variant={"primary.main"} />
            &nbsp;&nbsp;Form-16
          </ListItemButton>

          <ListItemButton onClick={openSalaryStructurePdfModal}>
            <PictureAsPdfRounded variant={"primary.main"} />
            &nbsp;&nbsp;Salary Structure
          </ListItemButton>

          <ListItemButton onClick={onResetButtonClick}>
            <Lock />
            &nbsp;&nbsp;Reset&nbsp;Password
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              dispatch(signOutAction());
            }}
          >
            &nbsp; <LogoutIcon />
            &nbsp;&nbsp;Log&nbsp;Out
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
};

const Header = () => {
  const user = useSelector((state) => state.user);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (toggle) => (event) => {
    setDrawerOpen(toggle);
  };

  const designation = {
    ...responsive.designation,
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Paper elevation={1} sx={headerStyle}>
        {!isMobile && (
          <>
            <Box sx={{ display: "flex", alignItems: "flex-end", flex: 1 }}>
              <Typography variant="h3">
                {user.data.first_name &&
                  toTitleCase(user.data.first_name) +
                    " " +
                    toTitleCase(user.data.last_name)}
              </Typography>
              <Typography
                variant="h6"
                color={"text.200"}
                ml={2}
                sx={designation}
              >
                ({user.data.designation})
              </Typography>
            </Box>

            <Box sx={{ display: "flex", gap: "30px" }}>
              <CenteredBox>
                <CompanyDocuments />
                {/* <EmployeeEvaluationButton /> */}
                <FeedbackFormButton />

                <NotificationModel />
              </CenteredBox>
              <ProfileBox />
            </Box>
          </>
        )}
      </Paper>
      {isMobile && (
        <Paper elevation={1} sx={mobileHeaderStyle}>
          <Grid container>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Box sx={{ display: "flex" }}>
                <ButtonBase onClick={toggleDrawer(true)}>
                  <MenuIcon variant={"primary.main"} />
                </ButtonBase>
                <SwipeableDrawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                >
                  <Paper
                    sx={{
                      width: "300px",
                      borderRadius: "none",
                      boxShadow: "none",
                      zIndex: "99",
                    }}
                  >
                    <Box sx={{ ...center }}>
                      {/* <ButtonBase
                      onClick={handleDrawerClose}
                      sx={{
                        position: "absolute",
                        top: "3px",
                        right: "3px",
                      }}
                    >
                      <ClearIcon variant={"primary.main"} />
                    </ButtonBase> */}
                      <Box sx={{ marginTop: "24px", width: "113px" }}>
                        <Avatar
                          sizes="large"
                          sx={{
                            width: "64px",
                            height: "64px",
                            bgcolor: "primary.main",
                            marginLeft: "25px",
                          }}
                        >
                          <Typography variant="h1">
                            {user.data.first_name &&
                              user.data.first_name[0].toUpperCase()}
                          </Typography>
                        </Avatar>
                        <Typography
                          sx={{ marginTop: "12px", ...center }}
                          variant="h3"
                        >
                          {user.data.first_name &&
                            toTitleCase(user.data.first_name) +
                              " " +
                              toTitleCase(user.data.last_name)}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ ...center, ml: "0" }}
                          color={"text.200"}
                          ml={2}
                        >
                          {user.data.designation &&
                            toTitleCase(user.data.designation)}
                        </Typography>
                      </Box>
                    </Box>
                    <List>
                      <ListItem
                        sx={{
                          borderTop: "1px solid #CCCCCC",
                          borderBottom: "1px solid #CCCCCC",
                        }}
                      >
                        <ButtonBase p={0} LinkComponent={Link} to="/profile">
                          <ListItemText primary="Profile" />
                        </ButtonBase>
                      </ListItem>
                      <ListItem sx={{ borderBottom: "1px solid #CCCCCC" }}>
                        <CompanyDocuments />
                      </ListItem>
                    </List>
                    <Box>
                      <Logo
                        mt={4}
                        sx={{
                          position: "absolute",
                          display: "flex",
                          bottom: "94px",
                          alignItems: "center",
                          left: "87px",
                        }}
                      />
                    </Box>
                  </Paper>
                </SwipeableDrawer>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box>
                <Logo mt={4} mb={4} />
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ display: "flex" }}>
                <NotificationModel />
                <ProfileBox style={{ height: "40px", width: "40px" }} />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};
export default memo(Header);
