import React, { useEffect, useState } from 'react'
import { callApiAction } from '../../store/actions/commonAction'
import { useDispatch, useSelector } from 'react-redux'
import { fetchdocuments } from '../../apis/document.api'
import CompanypolicyUi from './CompanyPolicyUi'
import { UserPolicyLogUpdate } from '../../apis/user.api'
import { useNavigate } from 'react-router-dom'


const CompanypolicyController = () => {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState([])
    const [policyButton, setPolicyButton] = useState();
    const [policyIdGet, setPolicyIdGet] = useState();
    const [topicindex, setTopicindex] = useState(user?.data?.policy_logs?.length);
    console.log("user?.data?.policy_logs?.length hello", topicindex)
    const dispatch = useDispatch();
    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchdocuments(),
            (response) => {

                setState(response)
                if (topicindex == 0) {
                    setPolicyButton(response?.[0]._id)
                }
                if (topicindex == 1) {
                    setPolicyButton(response?.[1]._id)
                }
                if (topicindex == 2) {
                    setPolicyButton(response?.[2]._id)
                }
                if (topicindex == 3) {
                    setPolicyButton(response?.[3]._id)
                }
                if (topicindex == 4) {
                    setPolicyButton(response?.[4]._id)
                }
                if (topicindex == 5) {
                    setPolicyButton(response?.[5]._id)
                }
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const UpdatepolicyIdGet = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await UserPolicyLogUpdate({ policy_id: policyIdGet }),
            (response) => {
                setLoading(false)
                setTopicindex(topicindex + 1);
                if (topicindex + 1 == 6) {
                    navigate("/dashboard/");
                    window.location.reload();
                }
            },
            (err) => {
                setLoading(false)
            }
        ))
    }




    useEffect(() => {
        if (policyIdGet) {
            UpdatepolicyIdGet()
        } else {
            fetchList();
        }

    }, [policyIdGet])

    return (
        <CompanypolicyUi
            state={state}
            policyButton={policyButton}
            setPolicyButton={setPolicyButton}
            policyIdGet={policyIdGet}
            setPolicyIdGet={setPolicyIdGet}
            loading={loading}
            setLoading={setLoading}
            topicindex={topicindex}
            setTopicindex={setTopicindex}
        />
    )
}

export default CompanypolicyController


