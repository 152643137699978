import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const fetchdocuments = async (params = {}) => {
  const callResponse = await axios({
    url: endpoints.documentFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const createdocument = async (data) => {
  const callResponse = await axios({
    url: endpoints.documentBase,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const deletedocument = async (data) => {
  const callResponse = await axios({
    url: endpoints.documentDelete,
    method: "delete",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
